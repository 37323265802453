/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import _ from "lodash";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Collapse,
  colors,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Link,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import {
  Block as BlockIcon,
  CloudDownload as CloudDownloadIcon,
  TableChart as TableChartIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  Info as InfoIcon,
  Error as ErrorIcon,
  OpenInNew as OpenInNewIcon,
  Replay,
  PictureAsPdf,
} from "@material-ui/icons";
import { getBaseURL } from "src/config/api";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { apiCancelProject } from "src/api";
import { userHasPermission } from "src/components/utils";
import safeLogger from "src/services/safeLogger";
import { format } from "date-fns";
import { connect } from "react-redux";
import Status from "./status";
import {
  ArrowDown,
  ArrowUp,
  Download,
  DownloadCloud,
  File,
  TrendingUp,
} from "react-feather";
import FailLogs from "./failLogs";
import { DiagnosisMaterialsTable } from "./diagnosisTable";
import { apiReRunProject } from "src/api/endpoints";
import { prepareFiles } from "../reports/utils/helpers";

const useRowStyles = makeStyles((theme) => ({
  row: {
    cursor: "pointer",
    transition: "all 0.2s  ease-in-out",
    "&:hover": {
      backgroundColor: theme.palette.action.disabledBackground + " ! important",
    },
  },
  completedStatus: {
    backgroundColor: theme.palette.success.main,
  },
  analyzingStatus: {
    backgroundColor: theme.palette.warning.main,
  },
  errorStatus: {
    backgroundColor: theme.palette.error.main,
  },
  chip: {
    "& span": {
      color: "white !important",
    },
  },
  buttonProgress: {
    color: theme.palette.success.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  analysisProgress: {
    color: colors.orange[500],
  },
  igvIcon: {
    padding: "12px",
    display: "flex",
    alignItems: "center",
  },
  info: {
    color: theme.palette.primary.main,
  },
}));

export const CancelAndReAnalysisButton = (props) => {
  const classes = useRowStyles();
  const { type, sampleData, buttonType, projectId, projectData } = props;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const handleClickOpen = (event) => {
    event.stopPropagation();
    setDialogOpen(true);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setDialogOpen(false);
  };

  const handleCanel = (event) => {
    if (event) {
      event.stopPropagation();
    }
    setLoading(true);
    apiCancelProject(
      projectId,
      sampleData ? sampleData.id : null,
      (data, status) => {
        safeLogger(data);
        safeLogger(status);
        if (status === 200) {
          setLoading(false);
          // TODO remove project from list if user has no priv to see
          // TODO change status to cancelled to update
          sampleData.status = "cancelled";
          enqueueSnackbar(
            t(
              "analysis_cancelled_message",
              "{{type}} analysis was cancelled!",
              {
                type: type,
              }
            ),
            {
              variant: "warning",
            }
          );
        } else {
          safeLogger(data);
        }
      }
    );
    handleClose(event);
  };

  function refineSamples(projectData) {
    // check sample files and reports and group them based on project type
    //   if (tableType === "reports") {
    //     setReportsData(projectData.reports);
    //   } else
    if (projectData.sample_kind === "paired_end") {
      let tmpSamplesData = [];
      let pairedSamples = [];
      for (let i = 0; i < projectData.samples.length; i++) {
        if (!pairedSamples.includes(projectData.samples[i].name)) {
          pairedSamples.push(projectData.samples[i].name);
          tmpSamplesData.push(projectData.samples[i]);
        }
      }
      return tmpSamplesData.map((sample) => sample.id);
    } else if (projectData.sample_kind === "single_end") {
      return projectData.samples.map((sample) => sample.id);
    }
  }

  const handleRe = (event) => {
    if (event) {
      event.stopPropagation();
    }
    setLoading(true);
    apiReRunProject(
      projectId,
      type === "project" ? null : [sampleData.id],
      (data, status) => {
        safeLogger(data);
        safeLogger(status);
        if (status === 200) {
          setLoading(false);
          // sampleData.status = "cancelled";
          enqueueSnackbar(
            t("analysis_rerun_message", "{{type}} analysis was re analysing!", {
              type: type,
            }),
            {
              variant: "warning",
            }
          );
        } else {
          safeLogger(data);
        }
      }
    );
    handleClose(event);
  };

  return (
    <React.Fragment>
      <Tooltip
        title={t(
          `${buttonType}_analysis_title`,
          buttonType === "cancel"
            ? "Cancel {{type}} analysis"
            : "Anew {{type}} analysis",
          {
            type: t(type),
          }
        )}
      >
        {projectData?.status !== "completed" ||
        sampleData?.status !== "completed" ? (
          <IconButton
            aria-label="cancel"
            color="secondary"
            onClick={handleClickOpen}
          >
            {buttonType === "cancel" ? (
              <BlockIcon fontSize="inherit" />
            ) : (
              <Replay fontSize="inherit" />
            )}
          </IconButton>
        ) : (
          <></>
        )}
      </Tooltip>
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t(
            `${buttonType}_analysis_title`,
            buttonType === "cancel"
              ? "Cancel {{type}} analysis"
              : "Anew {{type}} analysis",
            {
              type: t(type),
            }
          )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t(
              `${buttonType}_analysis_text`,
              buttonType === "cancel"
                ? "Are you sure you want to cancel {{type}} analysis? This action cannot be undone!"
                : "Are you sure you want to anew {{type}} analysis? This action cannot be undone!",
              { type: t(type) }
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={handleClose} color="primary">
            {t("No")}
          </Button>
          <Button
            disabled={loading}
            onClick={buttonType === "cancel" ? handleCanel : handleRe}
            color="secondary"
            autoFocus
          >
            {t("Yes")}
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:hover": {
      boxShadow: "0 1px 3px rgba(0,0,0,0.24)",
    },
  },
}))(TableRow);

export const CircularProgressWithLabel = (props) => {
  const classes = useRowStyles();
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        className={classes.analysisProgress}
        size={"35px"}
        thickness={5}
        variant="static"
        {...props}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
          style={{ fontSize: "11px" }}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    activeSamples: state.activeSamples,
  };
};

const ConnectedSampleRow = (props) => {
  const classes = useRowStyles();
  const [openLog, setOpenLog] = useState(false);
  const [openFileTable, setOpenFileTable] = useState(false);
  const { userObj, rowData, projectId, colNames, activeSamples } = props;
  const { t } = useTranslation();

  const status =
    activeSamples[rowData.id] && activeSamples[rowData.id].status
      ? activeSamples[rowData.id].status
      : rowData.status;

  useEffect(() => {
    if (
      rowData.status === "analysing" &&
      rowData.id in activeSamples &&
      activeSamples[rowData.id].status === "completed"
    ) {
      window.location.reload();
    }
  }, [rowData.status, activeSamples]);

  const handleOpenLog = () => {
    setOpenLog((prev) => !prev);
  };

  const hanldeFileTable = () => {
    setOpenFileTable((prev) => !prev);
  };
  return (
    <>
      <StyledTableRow href={"reports/" + rowData.id} hover data-id={rowData.id}>
        <TableCell>
          {userHasPermission(
            userObj.activePermissions,
            "diagnose_analysis"
          ) && (
            <>
              <IconButton onClick={handleOpenLog}>
                {openLog ? <ArrowDown /> : <ArrowUp />}
              </IconButton>
              <Tooltip title={t("Diagnosis Materials")}>
                <IconButton
                  variant=""
                  color="primary"
                  onClick={hanldeFileTable}
                >
                  <File />
                </IconButton>
              </Tooltip>
            </>
          )}
        </TableCell>
        <TableCell component="th" scope="row">
          {rowData.filename || "N/A"}
        </TableCell>
        <TableCell component="th" scope="row">
          {rowData.name || "N/A"}
        </TableCell>
        <TableCell align="left">
          {rowData.created_at
            ? format(new Date(rowData.created_at), "dd/MM/yyyy")
            : "N/A"}
        </TableCell>
        <TableCell align="left">
          {rowData.started_at
            ? format(new Date(rowData.started_at), "dd/MM/yyyy")
            : "N/A"}
        </TableCell>
        <TableCell align="left">
          {status === "analysing" &&
          ("progress" in rowData || rowData.id in activeSamples) ? (
            <CircularProgressWithLabel
              value={
                activeSamples[rowData.id] && activeSamples[rowData.id].progress
                  ? activeSamples[rowData.id].progress
                  : rowData.progress
              }
            />
          ) : (
            <Chip
              className={clsx({
                [classes.chip]: true,
                [classes.completedStatus]: status === "completed",
                [classes.analyzingStatus]: status === "analysing",
                [classes.errorStatus]:
                  status === "failed" || status === "cancelled",
              })}
              color={"primary"}
              label={t(status)}
              size="small"
            />
          )}
          {rowData.diagnosis && (
            <Tooltip
              title={
                Object.values(rowData.diagnosis)[0] &&
                Object.values(rowData.diagnosis)[0] !== "" &&
                Object.values(rowData.diagnosis)[0]
              }
            >
              {Object.keys(rowData.diagnosis)[0] === "error" ? (
                <ErrorIcon style={{ color: colors.red[400] }} />
              ) : (
                <InfoIcon style={{ color: colors.blue[500] }} />
              )}
            </Tooltip>
          )}
        </TableCell>
        <TableCell>
          <Box>
            {status === "pending" &&
              userHasPermission(userObj.activePermissions, "cancel_sample") && (
                <CancelAndReAnalysisButton
                  type="sample"
                  buttonType="cancel"
                  sampleData={rowData}
                  projectId={projectId}
                />
              )}
            {rowData.download_address && (
              <Tooltip title={t("Download the sample file")}>
                <Link href={`${getBaseURL()}${rowData.download_address}`}>
                  <IconButton>
                    <CloudDownloadIcon color="primary" />
                  </IconButton>
                </Link>
              </Tooltip>
            )}
            {status === "completed" &&
              userHasPermission(userObj.activePermissions, "cancel_sample") && (
                <CancelAndReAnalysisButton
                  type="sample"
                  buttonType="re"
                  sampleData={rowData}
                  projectId={projectId}
                />
              )}
          </Box>
        </TableCell>
      </StyledTableRow>
      <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={openFileTable}
        onClose={hanldeFileTable}
      >
        <DiagnosisMaterialsTable t={t} rowData={rowData} />
      </Dialog>
      {openLog && (
        <TableRow>
          <TableCell colSpan={colNames.length}>
            <Collapse in={openLog}>
              <FailLogs
                id={rowData.id}
                isFailed={rowData.status === "failed"}
              />
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

const SampleRow = connect(mapStateToProps)(ConnectedSampleRow);

const useFilesTableStyle = makeStyles((theme) => ({
  tableRow: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  boldCell: {
    fontWeight: "bold",
  },
}));

const boldFiles = ["bam", "bai", "vcf", "vcf_idx", "variant_table_report"];

const FilesTable = (props) => {
  const { files } = props;
  const classes = useFilesTableStyle();
  const { t } = useTranslation();

  return (
    <Table size="small" aria-label="samples">
      <TableHead>
        <TableRow>
          <TableCell>{t("File Name")}</TableCell>
          <TableCell>{t("Download Link")}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.keys(files).map((fileKey, index) => (
          <TableRow key={index} className={classes.tableRow}>
            <TableCell
              className={clsx({
                [classes.boldCell]: files[fileKey].tags.some((item) =>
                  boldFiles.includes(item)
                ),
              })}
            >
              {files[fileKey].name}
            </TableCell>
            {/* <TableCell>{files[fileKey].name.split("/").pop().replace(/(\.)\w+/, "")}</TableCell> */}
            <TableCell>
              <Link href={`${getBaseURL()}${files[fileKey].download_address}`}>
                {t("DOWNLOAD")}
              </Link>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const ReportRow = (props) => {
  const { rowData, setSelectedRowId, projectData } = props;
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [IGVFiles, setIGVFiles] = useState(undefined);
  const classes = useRowStyles();
  const { t } = useTranslation();

  useEffect(() => {
    setIGVFiles(prepareFiles(rowData.files, rowData.unified_output));
  }, []);

  const handleDetailsClick = (event) => {
    event.stopPropagation();
    setDetailsOpen(!detailsOpen);
  };

  const handleClick = () => {
    setSelectedRowId(rowData.id);
  };

  const openIGV = (event) => {
    event.stopPropagation();
    let igvTracks = "";
    const sendRequest = (file) => {
      fetch(`http://localhost:60151/load?genome=${projectData.genome}&${file}`)
        .then((response) => {
          safeLogger(response);
        })
        .catch((error) => {
          setOpenDialog(true);
        });
    };

    if (IGVFiles["mutect2_bam"] && IGVFiles["mutect2_bai"]) {
      igvTracks += `&file=${getBaseURL()}${
        IGVFiles["mutect2_bam"].download_address
      }&index=${getBaseURL()}${IGVFiles["mutect2_bai"].download_address}`;
      sendRequest(igvTracks);
    }

    if (IGVFiles["bam"] && IGVFiles["bai"]) {
      igvTracks = `&file=${getBaseURL()}${
        IGVFiles["bam"].download_address
      }&index=${getBaseURL()}${IGVFiles["bai"].download_address}`;
      sendRequest(igvTracks);
    }

    if (IGVFiles["haplotypecaller_bam"] && IGVFiles["haplotypecaller_bai"]) {
      igvTracks = `&file=${getBaseURL()}${
        IGVFiles["haplotypecaller_bam"].download_address
      }&index=${getBaseURL()}${
        IGVFiles["haplotypecaller_bai"].download_address
      }`;
      sendRequest(igvTracks);
    }

    if (IGVFiles["vcf"] && IGVFiles["vcf_idx"]) {
      igvTracks = `&file=${getBaseURL()}${
        IGVFiles["vcf"].download_address
      }&index=${getBaseURL()}${IGVFiles["vcf_idx"].download_address}`;
      sendRequest(igvTracks);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <React.Fragment>
      <StyledTableRow
        hover
        className={classes.row}
        data-id={rowData.id}
        onClick={handleClick}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={handleDetailsClick}
          >
            {detailsOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {rowData.name || "N/A"}
        </TableCell>
        <TableCell
        // style={{
        //   justifyContent: "start",
        //   alignContent: "center",
        // }}
        >
          <Box display="flex" justifyContent="start" alignContent="center">
            <Tooltip title={t("Show variant analysis")}>
              <RouterLink to={`/reports/${rowData.id}`}>
                <IconButton>
                  <TableChartIcon color="primary" />
                </IconButton>
              </RouterLink>
            </Tooltip>
            {IGVFiles && IGVFiles["auto_report"] && (
              <Tooltip title={t("Download PDF")}>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  target="_blank"
                  href={getBaseURL() + IGVFiles["auto_report"].download_address}
                >
                  <PictureAsPdf color="primary" />
                </IconButton>
              </Tooltip>
            )}
            {IGVFiles && (
              <Tooltip title={t("Open in IGV")}>
                <div onClick={openIGV} className={classes.igvIcon}>
                  <img
                    alt="igv logo"
                    height="16"
                    src="/assets/images/icons/IGV.svg"
                  />
                  <OpenInNewIcon />
                </div>
              </Tooltip>
            )}
          </Box>
        </TableCell>
        <TableCell align="left">
          {rowData.generated_at
            ? format(new Date(rowData.generated_at), "dd/MM/yyyy")
            : "N/A"}
        </TableCell>
        <TableCell onClick={(e) => e.stopPropagation()}>
          <Status
            data={rowData.status_choices}
            value={rowData.evaluation}
            id={rowData.id}
          />
        </TableCell>
        {/* <TableCell style={{ maxWidth: 200 }}>
          <Tooltip title="Add" aria-label="add">
            <Box display="flex" justifyContent="center" alignItems="center">
              <InfoIcon className={classes.info} />
            </Box>
          </Tooltip>
        </TableCell> */}
      </StyledTableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={detailsOpen} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography style={{ margin: "10px auto" }}>
                {t("Analysis Outputs")}
              </Typography>
              <FilesTable files={rowData.files} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("Unable to open sample in IGV")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t(
              "Sample could not be opened in IGV. Please make sure IGV is installed and running. If the problem persists, please contact support."
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary" autoFocus>
            {t("Close")}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export const DetailsTable = (props) => {
  const {
    userObj,
    classes,
    tableType,
    colNames,
    reports,
    projectData,
    sort,
    setSort,
    setSelectedRowId,
  } = props;

  const handleSort = (name, isSorting) => {
    if (isSorting) {
      setSort((prev) => {
        const newSort = {
          [name]:
            sort[name] === "ASC" ? "DESC" : sort[name] === "DESC" ? "" : "ASC",
        };
        if (newSort[name] === "") {
          delete newSort[name];
        }
        return newSort;
      });
    }
  };

  return (
    <TableContainer component={Paper} className={classes.tableFixHeader}>
      <Table
        size="small"
        stickyHeader
        aria-label="variant table"
        id="variant-table"
      >
        <TableHead className="table-header">
          <TableRow>
            {colNames.map((item, index) => (
              <TableCell
                key={index}
                onClick={() => handleSort(item.name, item.sort)}
                className={clsx(classes.tableHead, {
                  [classes.sampleTableHead]: tableType === "samples",
                  [classes.pointer]: item.sort,
                })}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  color={"white !important"}
                  style={{ minHeight: 40 }}
                >
                  {item.label}
                  {item.sort ? (
                    sort[item.name] === "ASC" ? (
                      <ArrowUp />
                    ) : sort[item.name] === "DESC" ? (
                      <ArrowDown />
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </Box>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableType === "samples" &&
            projectData.samples &&
            projectData.samples.map((rowData, index) => (
              <SampleRow
                userObj={userObj}
                key={index}
                colNames={colNames}
                rowData={rowData}
                projectId={projectData.id}
              />
            ))}
          {tableType === "reports" &&
            reports &&
            reports.map((rowData, index) => (
              <ReportRow
                projectData={{ reports, ...projectData }}
                key={index}
                rowData={rowData}
                setSelectedRowId={setSelectedRowId}
              />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
