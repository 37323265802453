import { Button, Menu, MenuItem } from "@material-ui/core";
import React from "react";
import Submission from ".";
import { useTranslation } from "react-i18next";
import { KeyboardArrowDown } from "@material-ui/icons";

export default function SubmissionMenu({
  tableColDetails,
  setIsOrable,
  setTableColDetails,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { t } = useTranslation();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        style={{
          marginRight: "1rem",
          marginLeft: "auto",
        }}
        aria-controls="simple-menu"
        aria-haspopup="true"
        color="primary"
        variant="contained"
        endIcon={<KeyboardArrowDown />}
        onClick={handleClick}
      >
        {t("Clinvar")}
      </Button>
      <Menu
        id="simple-menu"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Submission
          tableColDetails={tableColDetails}
          setIsOrable={setIsOrable}
          selectedValues={["P", "LP", "VUS"]}
          setTableColDetails={setTableColDetails}
          label="Exclude All Benign"
          color="primary"
        />
        <Submission
          tableColDetails={tableColDetails}
          setIsOrable={setIsOrable}
          selectedValues={["P", "LP"]}
          setTableColDetails={setTableColDetails}
          label="Include Any Pathogenic"
          color="secondary"
        />
      </Menu>
    </>
  );
}
