import {
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { SampleRow } from "./sampleRow";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function InnerRow(props) {
  const {
    classes,
    rowData,
    samples,
    innerTableHeaders,
    expandOverride,
    userObj,
  } = props;
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setOpen(expandOverride);
  }, [expandOverride]);

  useEffect(() => {
    setOpen(rowData.expanded);
  }, [rowData.expanded]);

  return (
    <div
      className={classes.innerTable}
      onClick={(event) => event.stopPropagation()}
    >
      <Table size="small">
        <TableHead>
          <TableRow
            onClick={(event) => event.stopPropagation()}
            className="inner-table-header"
          >
            {Object.values(innerTableHeaders).map((headerItem, index) => (
              <TableCell key={index}>{t(headerItem)}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {samples.map((sampleData, index) => (
            <SampleRow
              key={index}
              userObj={userObj}
              sampleData={sampleData}
              rowData={rowData}
            />
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
