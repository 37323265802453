import { Settings } from "./settings";
import React, { useState, useEffect } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { loadUser, signOut } from "src/redux/actions";
import { apiSignOut } from "src/api";
import clsx from "clsx";
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  InputBase,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { alpha } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import InputIcon from "@material-ui/icons/Input";
import { useSnackbar } from "notistack";
import SearchIcon from "@material-ui/icons/Search";

import { useTranslation } from "react-i18next";
import { urlAPIConf } from "src/config/api";
import SystemNotification from "src/components/systemNotification";

import { UserAvatar } from "./NavBar";
import ThemeChange from "./themeChange";
import { NotificationBox } from "./notifications";
import { LanguageSelect } from "./languageSelect";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
  },
  settingsButton: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  popover: {
    "& svg": {
      color: "white !important",
    },
    backgroundColor: theme.palette.primary.main,
  },
  logoWrapper: {
    color: "white !important",
    marginLeft: "8px",
    "& > img": {
      maxHeight: "25px",
      marginBottom: "-3px",
    },
    "& .logotype": {
      marginLeft: "8px",
      display: "inline-flex",
      textDecoration: "none",
      color: "#FFF !important",
      fontSize: "25px",
      fontWeight: "400",
    },
  },
  avatar: {
    width: 60,
    height: 60,
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

function mapDispatchToPropsSignOut(dispatch) {
  return {
    signOut: () => dispatch(signOut()),
    loadUser: () => dispatch(loadUser()),
  };
}

const ConnectedSignOutButton = (props) => {
  const { enqueueSnackbar } = useSnackbar();

  const signOutHandler = () => {
    apiSignOut((data, status) => {
      if (status === 200) {
        enqueueSnackbar("Successfully signed out", { variant: "success" });
        props.signOut();
        props.loadUser(null);
      } else {
        // error
        enqueueSnackbar(data, { variant: "alert" });
      }
    });
  };

  return (
    <IconButton color="inherit" onClick={signOutHandler}>
      <InputIcon />
    </IconButton>
  );
};

export const SignOutButton = connect(
  null,
  mapDispatchToPropsSignOut
)(ConnectedSignOutButton);

const mapStateToProps = (state) => {
  return {
    appConf: state.appConf,
    userObject: state.userObject,
  };
};

const ConnectedTopBar = (props) => {
  const { className, onMobileNavOpen, appConf, userObject } = props;
  const classes = useStyles();
  const navigate = useNavigate();
  const [showSystemNotification, setShowSystemNotification] = useState(
    appConf.system_notification &&
      appConf.system_notification.id !==
        userObject.settings.general.hideSystemNotification
  );
  const { t } = useTranslation();

  useEffect(() => {
    if (appConf && userObject) {
      setShowSystemNotification(
        appConf.system_notification &&
          appConf.system_notification.id !==
            userObject.settings.general.hideSystemNotification
      );
    }
  }, [appConf, userObject]);

  const searchSubmitHandler = (event) => {
    event.preventDefault();
    const query = event.target[0].value.replace(" ", "+");
    event.target[0].value = "";
    navigate(
      `/${urlAPIConf.PROJECTS_SLUG}/?filterValue={}&value=${query}&page=0&pageSize=10&value=&&sort={"created_at":"DESC"}`
    );
  };

  return (
    <AppBar className={clsx(classes.root, className)} elevation={0}>
      <Toolbar>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
        <RouterLink to="/" className={classes.logoWrapper}>
          <img alt="Logo" src="/exomeLogoWhite.png" />
          <Typography variant="body2" className="logotype">
            {t("app_name", "Massive Analyser")}
          </Typography>
        </RouterLink>
        <Box flexGrow={1} />
        <Hidden mdDown>
          <UserAvatar />
          <form className={classes.search} onSubmit={searchSubmitHandler}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder={t("Search…")}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{
                "aria-label": "search",
              }}
            />
          </form>
          <ThemeChange />
          <NotificationBox userObject={userObject} />
          <LanguageSelect />
          {/* <Settings
            classes={classes}
            searchSubmitHandler={searchSubmitHandler}
            userObject={userObject}
          /> */}
          <SignOutButton />
        </Hidden>
      </Toolbar>
      {showSystemNotification && (
        <SystemNotification
          id={appConf.system_notification.id}
          message={appConf.system_notification.message}
          kind={appConf?.system_notification?.kind}
          isPersistent={appConf.system_notification.is_persistent}
        />
      )}
    </AppBar>
  );
};

const TopBar = connect(mapStateToProps)(ConnectedTopBar);

export default TopBar;
