import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { Save as SaveIcon } from "@material-ui/icons";
import { apiUpdateProfile } from "src/api";
import { useTranslation } from "react-i18next";
import safeLogger from "src/services/safeLogger";
import { reportSectionsConf } from "src/config";

const useStyles = makeStyles((theme) => ({
  section: {
    paddingTop: "20px",
  },
}));

export const DefaultDetailsTabSection = (props) => {
  const { userObject, loadUser, projectTypeConf } = props;
  const [userConf, setUserConf] = useState(
    userObject.settings.report[projectTypeConf.name].defaultDetailsTab
      ? userObject.settings.report[projectTypeConf.name].defaultDetailsTab
      : "main"
  );
  // const [detailsTabs, setDetailsTabs] = useState(undefined);
  const classes = useStyles();

  // useEffect(() => {
  //   if (appConf) {
  //     const colDetails = appConf.schemas.default.columns;
  //     const tmpDetailsTabs = {};
  //     for (let i = 0; i < colDetails.length; i++) {
  //       if (colDetails[i].representation.kind.split("_")[0] === "tabbed") {
  //         if (
  //           !tmpDetailsTabs[colDetails[i].representation.options.group_name]
  //         ) {
  //           tmpDetailsTabs[colDetails[i].representation.options.group_name] = {
  //             value: (
  //               colDetails[i].representation.options.order + 1
  //             ).toString(),
  //             title: colDetails[i].representation.options.title,
  //           };
  //         }
  //       }
  //     }

  //     tmpDetailsTabs["comments"] = {
  //       value: "5",
  //       title: "Comments",
  //     };

  //     tmpDetailsTabs["genome_visualization"] = {
  //       value: "6",
  //       title: "Genome visualization",
  //     };

  //     setDetailsTabs(tmpDetailsTabs);
  //   }
  // }, [appConf]);

  const { t } = useTranslation();

  const updateProfileCallback = (data, status) => {
    if (status === 200) {
      loadUser(data);
    } else {
      safeLogger(status, data);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    userObject.settings.report[projectTypeConf.name]["defaultDetailsTab"] =
      userConf;
    apiUpdateProfile({ settings: userObject.settings }, updateProfileCallback);
  };

  const handleChange = (event) => {
    setUserConf(event.target.value);
  };

  if (!projectTypeConf) {
    return <CircularProgress />;
  } else {
    return (
      <div className={classes.section} data-testid="details-tab">
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <FormControl component="fieldset" style={{ display: "flex" }}>
            <RadioGroup
              aria-label="default report tab"
              name="defaultDetailsTab"
              data-testid="defaultDetailsTab"
              value={userConf}
              onChange={handleChange}
            >
              {Object.keys(
                reportSectionsConf[projectTypeConf.name].detailsTabs
              ).map((item, index) => (
                <FormControlLabel
                  key={index}
                  value={item}
                  data-testid={"default-details-tab"}
                  control={<Radio />}
                  label={t(
                    reportSectionsConf[projectTypeConf.name].detailsTabs[item]
                      .label
                  )}
                />
              ))}
            </RadioGroup>
          </FormControl>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            startIcon={<SaveIcon />}
            style={{ marginTop: "10px" }}
          >
            {t("Save")}
          </Button>
        </form>
      </div>
    );
  }
};
