import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  ListItem,
  ListItemText,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { useState } from "react";
import { MdOutlineCalculate } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { fileDatas } from "./bed";
import CustomDropdown from "src/components/customDropdown";

const useStyle = makeStyles((theme) => ({
  result: {
    marginTop: 0,
  },
  button: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  grid: {
    "& .MuiTextField-root": {
      width: "100%",
    },
    "& .MuiGrid-item": {
      padding: "10px 5px",
    },
  },
}));

const mapStateToProps = (state) => {
  return {};
};

// function bytesToSize(data, type = "byte") {
//   var sizes = {
//     byte: 1,
//     mb: 2,
//     gb: 3,
//   };

//   if (data === 0) return 0;
//   var result = Math.floor(data * Math.pow(1024, sizes[type]));
//   return parseInt(result);
// }

function mbToGb(data, type) {
  var sizes = {
    gb: 1,
    mb: 1000,
  };
  if (data === 0) return 0;
  const result = data / sizes[type];
  return result;
}

const ConnectedAvarage = (props) => {
  const { appConf } = props;
  const classes = useStyle();
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    fastq1: 0,
    fastq2: 0,
    kit: "",
    dataType: "mb",
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [alert, setAlert] = useState({
    fastq1: false,
    fastq2: false,
    kit: false,
    dataType: false,
  });
  const [result, setResult] = useState(0);
  const handleAlert = () => {
    let hasErr = true;
    formData.fastq1 <= 0 && (hasErr = false);
    formData.fastq2 <= 0 && (hasErr = false);
    formData.kit === "" && (hasErr = false);
    setAlert({
      fastq1: formData.fastq1 <= 0,
      fastq2: formData.fastq2 <= 0,
      kit: formData.kit === "",
      dataType: false,
    });
    return hasErr;
  };
  const handleDialog = () => {
    setOpenDialog(!openDialog);
  };
  const calculate = () => {
    if (handleAlert()) {
      let fileData = fileDatas[formData.kit];
      let sum =
        mbToGb(formData.fastq1, formData.dataType) +
        mbToGb(formData.fastq2, formData.dataType);
      sum = sum * 1000000000;
      sum /= fileData;
      sum *= 0.16;
      setResult(sum + "" + t("X"));
    }
  };

  const handleChange = (event) => {
    const newFormData = JSON.parse(JSON.stringify(formData));
    newFormData[event.target.name] = event.target.value
      ? event.target.value
      : null;
    setFormData(newFormData);
  };

  const dataTypes = {
    mb: "MB",
    gb: "GB",
  };

  return (
    <Container>
      <FormControl>
        <Grid container className={classes.grid}>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              component="h4"
              style={{ marginBottom: "15px" }}
            >
              {t("Expected Coverage Calculator")}
            </Typography>
          </Grid>
          <Grid container style={{ marginBottom: 10 }}>
            <Grid item xs={4}>
              <CustomDropdown
                name="kit"
                errorHandler={alert}
                formData={formData}
                labelTitle={t("Kit")}
                values={appConf.entities.kits}
                changeHandler={handleChange}
                defaultIndex={0}
              />
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <TextField
              name="fastq1"
              type="number"
              error={alert.fastq1}
              onKeyUp={handleChange}
              label="Fastq (1)"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              type="number"
              name="fastq2"
              error={alert.fastq2}
              onKeyUp={handleChange}
              label="Fastq (2)"
            />
          </Grid>
          <Grid item className={classes.button} xs={2}>
            <CustomDropdown
              name="dataType"
              formData={formData}
              errorHandler={alert}
              labelTitle={t("Type")}
              values={dataTypes}
              changeHandler={handleChange}
              defaultIndex={0}
            />
          </Grid>
          <Grid item className={classes.button} xs={2}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={() => {
                calculate();
              }}
              startIcon={<MdOutlineCalculate />}
            >
              {t("Calculate")}
            </Button>
          </Grid>
          <Grid item className={classes.result} xs={3}>
            <TextField
              label={t("Result")}
              value={result}
              disabled
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} style={{ marginTop: 10 }}>
            <Button variant="outlined" color="primary" onClick={handleDialog}>
              {t("Method")}
            </Button>
            <Dialog
              open={openDialog}
              onClose={handleDialog}
              aria-labelledby="formul-dialog-title"
              aria-describedby="formul-dialog-description"
            >
              <DialogTitle id="formul-dialog-title">{t("Formul")}</DialogTitle>
              <DialogContent>
                <DialogContentText id="formul-dialog-description">
                  <ListItem>
                    <ListItemText primary={t("Formule Byte")} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary={t("Formule Kit Value")} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary={t("Formule Coverage")} />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Typography
                          variant="subtitle1"
                          component="p"
                          style={{ fontSize: 20, fontWeight: 700 }}
                        >
                          {t("Method")} = ((I - (I * 0.1)) / 2) / kv
                        </Typography>
                      }
                    />
                  </ListItem>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleDialog} color="primary" autoFocus>
                  {t("Close")}
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </Grid>
      </FormControl>
    </Container>
  );
};

export const Avarage = connect(mapStateToProps, null)(ConnectedAvarage);
