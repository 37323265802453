/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import clsx from "clsx";
import {
  Button,
  CircularProgress,
  Collapse,
  colors,
  IconButton,
  Paper,
  TableCell,
  withStyles,
  Table as MuiTable,
  TableBody,
  TableHead,
  TableRow,
  Chip,
  makeStyles,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Box,
} from "@material-ui/core";
import { TooltipTableCell } from "src/components/pages/reports/virtualizedTable/tooltipTableCell";
import { Skeleton } from "@material-ui/lab";
import { FilterTableHeader } from "src/components/pages/reports/virtualizedTable/headers";
import { AutoSizer, Column, Table } from "react-virtualized";
import { cellDataGetter } from "src/components/pages/reports/utils/virtualizedCustom";
import { projectsTableHeaders } from "src/config";
import safeLogger from "src/services/safeLogger";
import { useNavigate } from "react-router-dom";
import {
  Block as BlockIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  TableChart as TableChartIcon,
} from "@material-ui/icons";
import {
  innerTableHeaders,
  projectsTableHeadersObject,
  virtualTableConf,
} from "src/config/projects";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import { useTranslation, withTranslation } from "react-i18next";
import { apiCancelProject } from "src/api";
import { userHasPermission } from "src/components/utils";
import { Link } from "react-router-dom";
import { filterRows } from "../reports/utils/helpers";
import { connect } from "react-redux";
import { CircularProgressWithLabel } from "../projectDetails/detailsTable";

const styles = (theme) => ({
  flexContainer: {
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
    flexWrap: "wrap",
  },
  applyFiltersBtn: {
    color: theme.palette.common.white,
    background: colors.green[400],
    "&:hover": {
      background: colors.green[600],
    },
  },
  filterableHeader: {
    position: "relative",
    paddingLeft: 0,
    borderBottom: "1px solid #E0E0E",
    display: "flex",
    // backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.paper,
    "& > *": {
      fill: "#FFF",
      color: theme.palette.header.primary,
    },
    "& > div": {
      borderBottom: "none",
      padding: 0,
    },
    "&:first-child > div": {
      // paddingLeft: "3px",
    },
    "& .header-tooltip-wrapper": {
      // whiteSpace: "nowrap",
      display: "inline-flex",
      cursor: "pointer",
    },
    "& .filter-button-wrapper": {
      paddingTop: "6px",
      "& *": {
        color: "#FFF",
      },
    },
    "& .filtered path": {
      color: colors.orange[300],
    },
  },
  sliderMenu: {
    "& ul": {
      width: "200px",
      padding: "40px 20px 20px",
    },
  },
  autocompleteMenuItem: {
    "& .menuItemCheckBox": { marginRight: "1px" },
    "& .menuItemLabel": {
      display: "inline-block",
      verticalAlign: "middle",
      width: "200px",
    },
  },
  sortIndicator: {
    position: "absolute",
    left: "-28px",
    // marginLeft: "-28px",
    width: "20px",
  },
  ascSortIndicator: {
    transform: "rotate(90deg)",
  },
  descSortIndicator: {
    transform: "rotate(-90deg)",
  },
  table: {
    "& .ReactVirtualized__Table__headerRow": {
      // paddingRight: theme.direction === "rtl" ? "0 !important" : undefined,
      paddingRight: "0 !important",
      //   paddingLeft: "62px",
      background: theme.palette.primary.main,
    },
    "& .ReactVirtualized__Table__headerRow > div:first-child": {
      marginRight: "51px",
    },
    "& .ReactVirtualized__Table__headerColumn:first-child $sortIndicator": {
      left: "40px",
    },
  },
  loadingTableRow: {
    border: "5px solid #FFF",
    cursor: "auto !important",
    "& .MuiSkeleton-root": {
      backgroundColor: "rgba(55, 71, 79, 0.2)",
    },
  },
  tableRow: {
    cursor: "pointer",
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    background: theme.palette.background.paper,
    "& .completedChip": { backgroundColor: theme.palette.success.main },
    "& .activeChip": { backgroundColor: theme.palette.warning.main },
    "& .errorChip": { backgroundColor: theme.palette.error.main },
  },
  tableCell: {
    width: "100%",
    fontSize: "0.875rem",
    borderBottom: "none",
  },
  tableRowHover: {
    backgroundColor: `${theme.palette.background.default} !important`,
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
    "& < $innerTable:hover": {
      background: theme.palette.background.paper,
    },
  },
  tableRowSelected: {
    border: "1px solid " + colors.amber[200] + " !important",
    backgroundColor: colors.amber[100] + " !important",
    // backgroundColor: theme.palette.warning.light + " !important",
  },
  projectRow: {
    display: "flex",
    width: "100%",
    "& .collaps-button": {
      padding: "11px",
      borderBottom: "none",
    },
  },
  innerTable: {
    width: "100%",
    "& thead tr": {
      secondBackground: `${theme.palette.background.secondBackground} !important`,
    },
    "& .inner-table-header": {
      borderTop: `1px solid ${theme.palette.tab.primary}`,
      background: `${theme.palette.background.secondBackground} !important`,
      color: theme.palette.text.primary,
      cursor: "initial",
    },
  },
  linkButtonCell: {
    "& .MuiButton-root": {
      lineHeight: "1",
    },
  },
  noClick: {
    cursor: "initial",
  },
  hidden: {
    display: "none",
  },
  projectCompletedChip: {
    backgroundColor: theme.palette.success.main,
  },
  projectActiveChip: {
    backgroundColor: theme.palette.warning.main,
  },
  errorStatus: {
    backgroundColor: theme.palette.error.main,
  },
  toggleActive: {
    color: "#FFF",
    transition: "color 0.3s",
  },
});

const useStyles = makeStyles((theme) => ({
  projectTable: {
    // "&::before": {
    //   content: "",
    //   position: "absolute",
    //   top: "-48px",
    //   height: "48px",
    //   backgroundColor: "#f57c00",
    // },
    "& .MuiTableCell-root": {
      padding: "6px 24px 6px 16px",
    },
    "& .MuiTableRow-root:hover": {
      backgroundColor: "#f1f1f1",
    },
    "& *": {
      boxSizing: "inherit",
    },
  },
  clickableRow: {
    cursor: "pointer",
  },
  projectDetailTable: {
    marginBottom: "10px",
  },
  projectCompletedChip: {
    backgroundColor: theme.palette.success.main,
  },
  projectActiveChip: {
    backgroundColor: theme.palette.warning.main,
  },
  errorStatus: {
    backgroundColor: theme.palette.error.main,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  buttonProgress: {
    color: theme.palette.success.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  sampleRow: {
    cursor: "initial",
    backgroundColor: `${theme.palette.background.mainBackground} !important`,
    background: colors.grey[50],
    "&:hover": {
      background: colors.grey[100],
    },
  },
  rowActionBtn: {
    padding: 0,
  },
}));

const CancelAnalysisButton = (props) => {
  const classes = useStyles();
  const { type, projectData, sampleData } = props;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const handleClickOpen = (event) => {
    event.stopPropagation();
    setDialogOpen(true);
  };

  const handleClose = (event) => {
    if (event) {
      event.stopPropagation();
    }
    setDialogOpen(false);
  };

  const handleCanel = (event) => {
    if (event) {
      event.stopPropagation();
    }
    setLoading(true);
    apiCancelProject(
      projectData.id,
      sampleData ? sampleData.id : null,
      (data, status) => {
        if (status === 200) {
          setLoading(false);
          // TODO remove project from list if user has no priv to
          const type = sampleData ? "sample" : "project";
          if (type === "sample") {
            sampleData.status = "cancelled";
          } else if (type === "project") {
            projectData.status = "cancelled";
          }
          enqueueSnackbar(
            t(
              "analysis_cancelled_message",
              "{{type}} analysis was cancelled!",
              {
                type: type,
              }
            ),
            {
              variant: "warning",
            }
          );
        } else {
          safeLogger(data);
        }
      }
    );
    handleClose();
  };

  return (
    <React.Fragment>
      <Tooltip
        title={t("cancel_analysis_title", "Cancel {{type}} analysis", {
          type: type,
        })}
      >
        <IconButton
          className={classes.rowActionBtn}
          aria-label="delete"
          color="secondary"
          onClick={handleClickOpen}
        >
          <BlockIcon fontSize="inherit" />
        </IconButton>
      </Tooltip>
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("cancel_analysis_title", "Cancel {{type}} analysis", {
            type: type,
          })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t(
              "cancel_analysis_text",
              "Are you sure you want to cancel {{type}} analysis? This action cannot be undone!",
              { type: type }
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={handleClose} color="primary">
            {t("No")}
          </Button>
          <Button
            disabled={loading}
            onClick={handleCanel}
            color="secondary"
            autoFocus
          >
            {t("Yes")}
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

const mapProgressStateToProps = (state) => {
  return {
    activeSamples: state.activeSamples,
    activeProjects: state.activeProjects,
  };
};

const ConnectedSampleRow = (props) => {
  const { sampleData, rowData, userObj, activeSamples } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    safeLogger("activeSamples: ", activeSamples);
  }, [activeSamples]);

  const status =
    sampleData.id in activeSamples && activeSamples[sampleData.id].status
      ? activeSamples[sampleData.id].status
      : sampleData.status;

  const report_id =
    sampleData.id in activeSamples &&
    activeSamples[sampleData.id].reports &&
    activeSamples[sampleData.id].reports.length
      ? activeSamples[sampleData.id].reports[0].id
      : findReport(sampleData.name)
      ? findReport(sampleData.name).id
      : "";

  function findReport(sampleName) {
    for (let i = 0; i < rowData.reports.length; i++) {
      if (rowData.project_type === "trio") {
        if (rowData.reports[i].name === "trio_variant_table") {
          return rowData.reports[i];
        }
        continue;
      }
      if (rowData.project_type === "acnv") {
        if (rowData.reports[i].name === sampleName + "_cnv_table_v1") {
          return rowData.reports[i];
        }
        continue;
      }
      if (rowData.reports[i].name === sampleName) {
        return rowData.reports[i];
      }
    }
  }

  return (
    <TableRow
      onClick={(event) => event.stopPropagation()}
      className={classes.sampleRow}
    >
      <TableCell component="th" scope="row">
        {sampleData.name}
      </TableCell>
      <TableCell
        style={{
          padding:
            status === "analysing" ? "1px 24px 1px 16px" : "6px 24px 6px 16px",
        }}
      >
        {status === "analysing" &&
        ("progress" in sampleData || sampleData.id in activeSamples) ? (
          <CircularProgressWithLabel
            size={"30px"}
            value={
              activeSamples[sampleData.id] &&
              activeSamples[sampleData.id].progress
                ? activeSamples[sampleData.id].progress
                : sampleData.progress
            }
          />
        ) : (
          <Chip
            color="primary"
            size="small"
            className={clsx({
              activeChip: status === "analysing",
              completedChip: status === "completed",
              errorChip: status === "failed" || status === "cancelled",
            })}
            label={t(status)}
          />
        )}
      </TableCell>
      <TableCell>
        {sampleData.started_at &&
          format(new Date(sampleData.started_at), "dd/MM/yyyy")}
      </TableCell>
      <TableCell>
        {sampleData.completed_at &&
          format(new Date(sampleData.completed_at), "dd/MM/yyyy")}
      </TableCell>
      <TableCell>
        {status === "pending" &&
          userHasPermission(userObj.activePermissions, "cancel_sample") && (
            <CancelAnalysisButton
              projectData={rowData}
              sampleData={sampleData}
              type="sample"
            />
          )}

        {status === "completed" && (
          <Tooltip title={t("Show variant analysis")}>
            <Link to={`/reports/${report_id}`}>
              <IconButton className={classes.rowActionBtn}>
                <TableChartIcon color="primary" />
              </IconButton>
            </Link>
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  );
};

const SampleRow = connect(mapProgressStateToProps)(ConnectedSampleRow);

const InnerRow = (props) => {
  const {
    classes,
    rowData,
    samples,
    innerTableHeaders,
    expandOverride,
    userObj,
  } = props;
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setOpen(expandOverride);
  }, [expandOverride]);

  useEffect(() => {
    setOpen(rowData.expanded);
  }, [rowData.expanded]);

  return (
    <div
      className={classes.innerTable}
      onClick={(event) => event.stopPropagation()}
    >
      <Collapse in={open} timeout="auto" unmountOnExit>
        <MuiTable size="small">
          <TableHead>
            <TableRow
              onClick={(event) => event.stopPropagation()}
              className="inner-table-header"
            >
              {Object.values(innerTableHeaders).map((headerItem, index) => (
                <TableCell key={index}>{t(headerItem)}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {samples.map((sampleData, index) => (
              <SampleRow
                key={index}
                userObj={userObj}
                sampleData={sampleData}
                rowData={rowData}
              />
            ))}
          </TableBody>
        </MuiTable>
      </Collapse>
    </div>
  );
};

class MuiVirtualizedTable extends React.PureComponent {
  static defaultProps = {
    headerHeight: 48,
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.sortState !== prevProps.sortState ||
      this.props.rows !== prevProps.rows
    ) {
      this.tableRef.recomputeRowHeights();
      this.tableRef.forceUpdateGrid();
    }
  }

  getRowClassName = ({ index }) => {
    const { classes, onRowClick } = this.props;
    return clsx(classes.tableRow, classes.flexContainer, {
      [classes.tableRowHover]: index !== -1 && onRowClick != null,
    });
  };

  TooltipCellRenderer = ({ cellData, columnIndex, dataKey, rowData }) => {
    const {
      activeProjects,
      appConf,
      userObj,
      columns,
      classes,
      rowHeight,
      onRowClick,
      t,
    } = this.props;

    const status =
      rowData.id in activeProjects && activeProjects[rowData.id].status
        ? activeProjects[rowData.id].status
        : rowData.status;

    return dataKey === "project_type" ? (
      <TableCell className={classes.tableCell} component="div">
        {appConf.entities.project_types[cellData]}
      </TableCell>
    ) : dataKey === "pipeline_class" ? (
      <TooltipTableCell
        column={columns[columnIndex]}
        className={clsx(classes.tableCell, classes.flexContainer, {
          [classes.noClick]: onRowClick == null,
        })}
        height={rowHeight}
        alignment={
          (columnIndex != null && columns[columnIndex].numeric) || false
            ? "right"
            : "left"
        }
        data={
          appConf.entities.pipeline_classes[rowData["project_type"]][cellData]
        }
        exceptions={[]}
      />
    ) : dataKey === "kit" ? (
      <TooltipTableCell
        column={columns[columnIndex]}
        className={clsx(classes.tableCell, classes.flexContainer, {
          [classes.noClick]: onRowClick == null,
        })}
        height={rowHeight}
        alignment={
          (columnIndex != null && columns[columnIndex].numeric) || false
            ? "right"
            : "left"
        }
        data={appConf.entities.kits[cellData]}
        exceptions={[]}
      />
    ) : dataKey === "status" ? (
      <TableCell
        component="div"
        variant="body"
        className={classes.tableCell}
        style={{ padding: status === "analysing" ? "6px 14px" : "14px" }}
      >
        {status === "analysing" &&
        ("progress" in rowData || rowData.id in activeProjects) ? (
          <CircularProgressWithLabel
            value={
              activeProjects[rowData.id] && activeProjects[rowData.id].progress
                ? activeProjects[rowData.id].progress
                : rowData.progress
            }
          />
        ) : (
          <Chip
            color="primary"
            size="small"
            className={clsx({
              [classes.projectActiveChip]: status === "analysing",
              [classes.projectCompletedChip]: status === "completed",
              [classes.errorStatus]:
                status === "failed" || status === "cancelled",
            })}
            label={t(status)}
          />
        )}
      </TableCell>
    ) : dataKey === "actions" ? (
      <TableCell className={classes.tableCell} component="div">
        {status === "pending" &&
        userHasPermission(userObj.activePermissions, "cancel_project") ? (
          <CancelAnalysisButton projectData={rowData} type="project" />
        ) : (
          "\u00A0"
        )}
      </TableCell>
    ) : (
      <TooltipTableCell
        column={columns[columnIndex]}
        className={clsx(classes.tableCell, classes.flexContainer, {
          [classes.noClick]: onRowClick == null,
        })}
        height={rowHeight}
        alignment={
          (columnIndex != null && columns[columnIndex].numeric) || false
            ? "right"
            : "left"
        }
        data={
          columns[columnIndex].type === "date"
            ? format(new Date(cellData), "dd/MM/yyyy HH:mm")
            : cellData
        }
        exceptions={[]}
      />
    );
  };

  rowRenderer = ({
    className,
    columns,
    index,
    key,
    onRowClick,
    onRowDoubleClick,
    onRowMouseOut,
    onRowMouseOver,
    onRowRightClick,
    rowData,
    style,
  }) => {
    const a11yProps = { "aria-rowindex": index + 1 };

    const { userObj } = this.props;

    const rowDataLength = rowData ? Object.keys(rowData).length : 0;

    if (
      Boolean(rowDataLength) &&
      (onRowClick ||
        onRowDoubleClick ||
        onRowMouseOut ||
        onRowMouseOver ||
        onRowRightClick)
    ) {
      a11yProps["aria-label"] = "row";
      a11yProps.tabIndex = 0;

      if (onRowClick) {
        a11yProps.onClick = (event) => onRowClick({ event, index, rowData });
      }
      if (onRowDoubleClick) {
        a11yProps.onDoubleClick = (event) =>
          onRowDoubleClick({ event, index, rowData });
      }
      if (onRowMouseOut) {
        a11yProps.onMouseOut = (event) =>
          onRowMouseOut({ event, index, rowData });
      }
      if (onRowMouseOver) {
        a11yProps.onMouseOver = (event) =>
          onRowMouseOver({ event, index, rowData });
      }
      if (onRowRightClick) {
        a11yProps.onContextMenu = (event) =>
          onRowRightClick({ event, index, rowData });
      }
    }

    const handleExpandClick = (event) => {
      event.stopPropagation();
      safeLogger(rowData);
      rowData.expanded = !rowData.expanded;
      this.tableRef.recomputeRowHeights();
      this.tableRef.forceUpdateGrid();
    };

    function refineSamples(projectData) {
      // check sample files and reports and group them based on project type
      //   if (tableType === "reports") {
      //     setReportsData(projectData.reports);
      //   } else
      if (projectData.sample_kind === "paired_end") {
        let tmpSamplesData = [];
        let pairedSamples = [];
        for (let i = 0; i < projectData.samples.length; i++) {
          if (!pairedSamples.includes(projectData.samples[i].name)) {
            pairedSamples.push(projectData.samples[i].name);
            tmpSamplesData.push(projectData.samples[i]);
          }
        }
        return tmpSamplesData;
      } else if (projectData.sample_kind === "single_end") {
        return projectData.samples;
      }
    }

    if (rowData) {
      return Boolean(rowDataLength) ? (
        <div
          {...a11yProps}
          className={className}
          key={key}
          role="row"
          style={style}
        >
          <div className={this.props.classes.projectRow}>
            <TableCell component="div" className="collaps-button">
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={handleExpandClick}
              >
                {rowData.expanded ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
            </TableCell>
            {columns}
          </div>
          <InnerRow
            rowData={rowData}
            samples={refineSamples(rowData)}
            classes={this.props.classes}
            expandOverride={this.props.expandOverride}
            innerTableHeaders={innerTableHeaders}
            userObj={userObj}
          />
        </div>
      ) : (
        <div style={style} className={className} key={index}>
          <Skeleton variant="rect" width={"100%"} height={22} />
        </div>
      );
    }
  };

  headerRenderer = ({ label, columnIndex }) => {
    const {
      sortState,
      setSortState,
      headerHeight,
      columns,
      setColumns,
      classes,
      filterState,
      t,
    } = this.props;

    return (
      <FilterTableHeader
        type="local"
        appConf={this.props.appConf}
        sortState={sortState}
        setSortState={setSortState}
        headerHeight={headerHeight}
        columns={columns}
        setColumns={setColumns}
        classes={classes}
        label={t(label)}
        columnIndex={columnIndex}
        filterState={filterState}
        headerIndex={columnIndex}
        tableKind="project"
        expandOverride={this.props.expandOverride}
        setExpandOverride={this.props.setExpandOverride}
        filterType={
          Object.keys(columns[columnIndex].filterValues).length < 5
            ? "select"
            : "search"
        }
      />
    );
  };

  rowHeight = (params) => {
    const { rows } = this.props;
    if (!rows[params.index].expanded) {
      return virtualTableConf.rowHeight;
    }

    return rows[params.index].sample_kind === "paired_end"
      ? virtualTableConf.rowHeight +
          37 +
          (rows[params.index].samples.length / 2) * 37
      : virtualTableConf.rowHeight +
          37 +
          rows[params.index].samples.length * 37;
  };

  render() {
    const { classes, columns, headerHeight, ...tableProps } = this.props;
    return (
      <AutoSizer>
        {({ height, width }) => (
          <Table
            ref={(ref) => (this.tableRef = ref)}
            scrollToAlignment="start"
            height={height}
            width={width}
            rowHeight={this.rowHeight}
            gridStyle={{
              direction: "inherit",
            }}
            headerHeight={headerHeight}
            className={classes.table}
            {...tableProps}
            rowClassName={this.getRowClassName}
            rowRenderer={this.rowRenderer}
          >
            {columns.map(({ name, ...other }, index) => {
              return (
                <Column
                  key={name}
                  cellDataGetter={cellDataGetter}
                  headerRenderer={(headerProps) =>
                    this.headerRenderer({
                      ...headerProps,
                      columnIndex: index,
                    })
                  }
                  className={clsx(classes.flexContainer)}
                  cellRenderer={this.TooltipCellRenderer}
                  dataKey={name}
                  {...other}
                  width={other.width || (width - 68) / columns.length}
                />
              );
            })}
          </Table>
        )}
      </AutoSizer>
    );
  }
}

const ProjectsVirtualizedTable = withStyles(styles)(
  withTranslation()(MuiVirtualizedTable)
);

const ConnectedVirtualProjectsTable = (props) => {
  const { userObj, appConf, projectsData, activeProjects } = props;
  const [columns, setColumns] = useState([...projectsTableHeaders]);
  const [defaultRows, setDefaultRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [filterState, setFilterState] = useState({});
  const [loading, setLoading] = useState(true);
  const [expandOverride, setExpandOverride] = useState(false);
  const navigate = useNavigate();
  const [sortState, setSortState] = useState({
    created_at: "DESC",
  });
  const [isInitiallySorted, setIsInitiallySorted] = useState(false);

  // useEffect(() => {
  //   // safeLogger("active projects: ", activeProjects);
  // }, [activeProjects]);

  function sortRows(rows, sortState) {
    // const collator = new Intl.Collator("en", { sensitivity: "base" }); // support for other locales?
    // for (let item in sortState) {
    //   rows.sort((a, b) => {
    //     if (projectsTableHeadersObject[item].type === "string") {
    //       return sortState[item] === "ASC"
    //         ? collator.compare(a[item], b[item])
    //         : collator.compare(b[item], a[item]);
    //     } else if (projectsTableHeadersObject[item].type === "date") {
    //       const aDate = new Date(a[item]);
    //       const bDate = new Date(b[item]);
    //       if (aDate > bDate) return sortState[item] === "ASC" ? 1 : -1;
    //       if (aDate < bDate) return sortState[item] === "ASC" ? -1 : 1;
    //       return 0;
    //     } else {
    //       if (a[item] > b[item]) return sortState[item] === "ASC" ? 1 : -1;
    //       if (a[item] < b[item]) return sortState[item] === "ASC" ? -1 : 1;
    //       return 0;
    //     }
    //   });
    // }

    return rows;
  }

  useEffect(() => {
    let expandedModified = false;
    for (let i = 0; i < filteredRows.length; i++) {
      if (filteredRows[i].expanded !== expandOverride) {
        filteredRows[i].expanded = expandOverride;
        expandedModified = true;
      }
    }
    if (expandedModified) {
      setFilteredRows(filteredRows.slice(0));
    }
  }, [expandOverride]);

  useEffect(() => {
    let expandedModified = false;
    for (let i = 0; i < filteredRows.length; i++) {
      if (filteredRows[i].expanded) {
        expandedModified = true;
        filteredRows[i].expanded = false;
      }
    }
    if (expandedModified) {
      setFilteredRows([...filteredRows]);
    }
    let mounted = true;

    if (sortState) {
      const sortedRows = sortRows(filteredRows, sortState);
      if (mounted) {
        setFilteredRows(sortedRows.slice(0));
      }
    }

    return function cleanup() {
      mounted = false;
      return mounted;
    };
  }, [sortState]);

  useEffect(() => {
    for (let i = 0; i < projectsData.length; i++) {
      projectsData[i].expanded = false;
    }

    setDefaultRows([...projectsData]);

    for (let i = 0; i < columns.length; i++) {
      columns[i].filterValues = {};
      columns[i].activeFilterCount = 0;
      for (let j = 0; j < projectsData.length; j++) {
        if (
          columns[i].filterValues[projectsData[j][columns[i].name]] ===
          undefined
        ) {
          columns[i].filterValues[projectsData[j][columns[i].name]] = false;
        }
      }
    }

    setColumns([...columns]);
    setLoading(false);
  }, [projectsData]);

  useEffect(() => {
    setExpandOverride(false);
    let mounted = true;
    if (mounted && columns.length && defaultRows) {
      const filteredData = filterRows(defaultRows, columns);
      setFilteredRows([...sortRows(filteredData, sortState)]);
    }

    return function cleanup() {
      mounted = false;
    };
  }, [defaultRows, columns]);

  useEffect(() => {
    if (filteredRows.length && !isInitiallySorted) {
      if (sortState && projectsData.length) {
        setFilteredRows([...sortRows(projectsData, sortState)]);
      }
      setIsInitiallySorted(true);
    }
  }, [filteredRows]);

  useEffect(() => {
    // let filtersets = {};
    let tmpFilters = {};
    // let newFilterCount = 0;
    for (let i = 0; i < columns.length; i++) {
      if (columns[i].activeFilterCount) {
        if (columns[i].type === "numeric") {
          tmpFilters[columns[i].name] = [...columns[i]?.filterRange];
          //   newFilterCount++;
        } else {
          let tmpColFilters = [];
          const filterObjects = Object.entries(columns[i].filterValues);
          for (let j = 0; j < filterObjects.length; j++) {
            if (filterObjects[j][1]) {
              tmpColFilters.push(filterObjects[j][0]);
              //   newFilterCount++;
            }
          }
          tmpFilters[columns[i].name] = tmpColFilters;
        }
      }
    }
    setFilterState(tmpFilters);
  }, [columns]);

  const rowClickHandler = (event) => {
    navigate(`/projects/${event.rowData.id}`);
  };

  if (loading) {
    return <CircularProgress />;
  } else {
    return (
      <Box height={530}>
        <ProjectsVirtualizedTable
          height={500}
          columns={columns}
          setColumns={setColumns}
          rows={filteredRows}
          rowGetter={({ index }) => filteredRows[index]}
          rowCount={filteredRows.length}
          onRowClick={rowClickHandler}
          //
          sort={sortState.sort}
          sortBy={undefined}
          sortDirection={undefined}
          //
          sortState={sortState}
          setSortState={setSortState}
          appConf={appConf}
          userObj={userObj}
          filterState={filterState}
          expandOverride={expandOverride}
          setExpandOverride={setExpandOverride}
          activeProjects={activeProjects}
        />
      </Box>
    );
  }
};

export const VirtualProjectsTable = connect(mapProgressStateToProps)(
  ConnectedVirtualProjectsTable
);
