import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
  colors,
  makeStyles,
} from "@material-ui/core";
import { Block } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { apiCancelProject } from "src/api";
import safeLogger from "src/services/safeLogger";

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    color: theme.palette.success.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  rowActionBtn: {
    padding: 0,
  },
}));

const CancelAnalysisButton = (props) => {
  const classes = useStyles();
  const { type, projectData, sampleData } = props;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const handleClickOpen = (event) => {
    event.stopPropagation();
    setDialogOpen(true);
  };

  const handleClose = (event) => {
    if (event) {
      event.stopPropagation();
    }
    setDialogOpen(false);
  };

  const handleCanel = (event) => {
    if (event) {
      event.stopPropagation();
    }
    setLoading(true);
    apiCancelProject(
      projectData.id,
      sampleData ? sampleData.id : null,
      (data, status) => {
        if (status === 200) {
          setLoading(false);
          // TODO remove project from list if user has no priv to
          const type = sampleData ? "sample" : "project";
          if (type === "sample") {
            sampleData.status = "cancelled";
          } else if (type === "project") {
            projectData.status = "cancelled";
          }
          enqueueSnackbar(
            t(
              "analysis_cancelled_message",
              "{{type}} analysis was cancelled!",
              {
                type: type,
              }
            ),
            {
              variant: "warning",
            }
          );
        } else {
          safeLogger(data);
        }
      }
    );
    handleClose();
  };

  return (
    <Fragment>
      <Tooltip
        title={t("cancel_analysis_title", "Cancel {{type}} analysis", {
          type: type,
        })}
      >
        <IconButton
          className={classes.rowActionBtn}
          aria-label="delete"
          color="secondary"
          onClick={handleClickOpen}
        >
          <Block fontSize="inherit" />
        </IconButton>
      </Tooltip>
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("cancel_analysis_title", "Cancel {{type}} analysis", {
            type: type,
          })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t(
              "cancel_analysis_text",
              "Are you sure you want to cancel {{type}} analysis? This action cannot be undone!",
              { type: type }
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={handleClose} color="primary">
            {t("No")}
          </Button>
          <Button
            disabled={loading}
            onClick={handleCanel}
            color="secondary"
            autoFocus
          >
            {t("Yes")}
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default CancelAnalysisButton;
