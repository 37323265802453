import { Button, MenuItem } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

export default function Submission({
  tableColDetails,
  setTableColDetails,
  selectedValues,
  setIsOrable,
  label,
  color,
}) {
  const { t } = useTranslation();

  const handleClick = async () => {
    const tmpDetail = [];
    await tableColDetails.forEach((value) => {
      if (value.name === "details4.clinvar_submission") {
        let count = value.activeFilterCount ?? 0;
        Object.keys(value.filterValues).forEach((key) => {
          if (selectedValues.includes(key)) {
            value.filterValues[key] = true;
            count++;
          }
        });
        value.activeFilterCount = count;
        value.representation.kind = "main_table_cell";
      }
      tmpDetail.push(value);
    });
    // setTableFilterState({ ...tableFilterState });
    setIsOrable(true);
    setTableColDetails([...tmpDetail]);
  };

  return <MenuItem onClick={handleClick}>{t(label)}</MenuItem>;
}
