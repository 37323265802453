import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Paper,
  Tooltip,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { ArrowDropUp } from "@material-ui/icons";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";

const useStylesRoot = (props) =>
  makeStyles((theme) => ({
    root: {
      backgroundImage: `linear-gradient(90deg, rgba(74,150,104,1) 0%, rgba(133,142,78,1) 20%, 
      rgba(200,134,48,1) 40%, rgba(211,118,50,1) 60%, rgba(221,103,51,1) 80%, 
      rgba(244,67,54,1) 100%);`,
      //   background: `linear-gradient(90deg, rgba(94,255,222,1) 0%, rgba(1,253,255,1) 5%, rgba(0,224,255,1) 12%, rgba(56,159,195,1) 20%, rgba(93,138,191,1) 27%, rgba(102,124,190,1) 31%, rgba(110,134,190,1) 42%, rgba(255,255,135,1) 51%, rgba(255,252,11,1) 61%, rgba(255,245,0,1) 65%, rgba(255,148,0,1) 73%, rgba(255,138,74,1) 77%, rgba(253,45,45,1) 100%);`,
      position: "relative",
      width: "100%",
      padding: theme.spacing(0.3),
      borderRadius: theme.spacing(4),
    },
    dialog: {
      minHeight: "40vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  }));

const useStylesMark = (props) =>
  makeStyles((theme) => ({
    mark: {
      position: "absolute",
      left: `${calculatePercentage(
        props.mark.value,
        props.range[0],
        props.range[1]
      )}%`,
      top: `40%`,
      transform: "translate(-50%,-40%)",
    },
    dot: {
      position: "relative",
      width: theme.spacing(1),
      height: theme.spacing(1),
      border: "1px solid white",
      // transform: "translate(-50%,-5%)",
      backgroundColor: props.isValue ? "red" : "black",
      borderRadius: theme.spacing(4),
    },
    markValues: {
      position: "absolute",
      display: "none",
      bottom: props.index % 2 === 0 ? `600%` : "-600%",
      justifyContent: "center",
      alignItems: "center",
      "& p": {
        fontSize: 12,
      },
      flexDirection: "column",
      left: `50%`,
      width: "max-content",
      transform: "translate(-50%,50%)",
    },
    value: {
      position: "absolute",
      display: "flex",
      bottom: "500%",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      borderRadius: theme.spacing(1),
      padding: theme.spacing(1),
      fontWeight: 600,
      left: `50%`,
      width: 10,
      height: 10,
      transform: "translate(-50%,150%)",
    },
    valueHeader: {
      fontWeight: 600,
    },
    arrow: {
      position: "absolute",
      bottom: "0%",
      fontSize: theme.spacing(2),
      left: "50%",
      transform: "translate(-50%,50%) rotate(180deg)",
    },
  }));

function calculatePercentage(value, min, max) {
  if (max === min && value === 0) {
    // Aralıkta fark yoksa, hesaplama yapılamaz
    return 0; // veya uygun bir hata mesajı döndürülebilir.
  }

  if (value === "") {
    value = min;
  } else {
    value = Number(value);
  }
  return ((value - min) / (max - min)) * 100 >= 100
    ? 100
    : ((value - min) / (max - min)) * 100 <= 0
    ? 0
    : ((value - min) / (max - min)) * 100;
}

export const ClassificationSlider = ({ marks, value, range }) => {
  const classes = useStylesRoot({
    value,
  })();

  if (_.isEmpty(value)) {
    return "";
  } else {
    return (
      <Box width={"100%"}>
        <Box className={classes.root}>
          {marks.map((mark, index) => (
            <Mark mark={mark} index={index} marks={marks} range={range} />
          ))}
          <Mark mark={{ value }} marks={marks} isValue range={range} />
        </Box>
      </Box>
    );
  }
};

const Mark = ({ marks, mark, range, isValue, index }) => {
  const classes = useStylesMark({
    mark,
    range,
    index,
    isValue,
  })();
  return mark.value && Number(mark.value) >= 0 ? (
    <Tooltip
      title={isValue ? Number(mark.value) : mark.value + " " + mark.label}
    >
      <Box className={classes.mark}>
        {isValue && mark.value && (
          <Box
            elevation={12}
            variant="outlined"
            square
            color="inherit"
            className={classes.value}
          >
            <Box className={classes.arrow}>
              <ArrowDropUp color="secondary" fontSize="large" />
            </Box>
          </Box>
        )}
        <Box className={classes.dot}>
          {!isValue && (
            <Box className={classes.markValues}>
              <Typography
                variant="p"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                {mark.label.split(" ").map((val) => (
                  <Typography>{val}</Typography>
                ))}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Tooltip>
  ) : (
    ""
  );
};
