import { MenuItem, TextField } from "@material-ui/core";
import React from "react";

const CustomDropdown = (props) => {
  const {
    labelTitle,
    values,
    changeHandler,
    style,
    errorHandler,
    formData,
    name,
    disabled,
  } = props;
  return (
    <React.Fragment>
      <TextField
        id={`select-${labelTitle.toLowerCase()}`}
        select
        name={name}
        value={formData ? formData[name] : ""}
        onChange={changeHandler}
        label={labelTitle}
        variant={"outlined"}
        style={style ?? {}}
        InputProps={{
          "data-testid": `aaaaaaa`,
        }}
        required
        error={errorHandler ? errorHandler[name] : false}
        disabled={disabled}
      >
        {Object.entries(values).map((item, index) => {
          return (
            <MenuItem
              disabled={item[0] === "single_end"}
              value={item[0]}
              data-testid={`select-${labelTitle.toLowerCase()}-${item[0]}`}
              key={index}
            >
              {item[1]}
            </MenuItem>
          );
        })}
      </TextField>
    </React.Fragment>
  );
};

export default CustomDropdown;
