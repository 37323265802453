/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Tabs,
  Tab,
  Typography,
  Box,
  Grid,
  colors,
  CircularProgress,
  Button,
} from "@material-ui/core";
import { UsersManagement } from "./userManagement";
import { TeamManagement } from "./teamManagement";
import { DepartmentManagement } from "./departmentManagement";
import {
  AccountCircle as AccountCircleIcon,
  Business as BusinessIcon,
  Group as GroupIcon,
  Equalizer as EqualizerIcon,
} from "@material-ui/icons";
import { apiGetOrganizationDetails } from "src/api";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { loadUser } from "src/redux/actions";
import { connect } from "react-redux";
import RunnedSamples from "./runnedSamples";
import AnnotationStatus from "./AnnotationStatus";
import { apiAddToSystemStatusChoices } from "src/api/endpoints";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  spinner: {
    position: "absolute",
    top: "48%",
    left: "48%",
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: 224,
  },
  pageTitle: {
    marginBottom: "20px",
    color: colors.grey[800],
  },
  runnedItem: {
    padding: theme.spacing(3),
  },
  paper: {
    backgroundColor: theme.palette.background.secondBackground,
    color: theme.palette.text.primary,
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    borderRadius: 15,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

function mapDispatchToProps(dispatch) {
  return {
    loadUser: (data) => dispatch(loadUser(data)),
  };
}

const ConnectedManagementSection = (props) => {
  const classes = useStyles();
  const { userObj, loadUser } = props;
  const [value, setValue] = useState(0);
  const [organizationData, setOrganizationData] = useState(undefined);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSignoutOrganization = () => {
    localStorage.removeItem("activeOrganizationId");
    const newUserObject = Object.assign({}, userObj);
    delete newUserObject["activeOrganization"];
    loadUser(newUserObject);
    navigate("/select-organization");
  };

  useEffect(() => {
    let mounted = true;
    apiGetOrganizationDetails(userObj.activeOrganization.id, (data, status) => {
      if (status === 200) {
        if (mounted) {
          if (!data.status_choices.length)
            apiAddToSystemStatusChoices(userObj.activeOrganization.id);
          setOrganizationData(data);
        }
      }
    });

    return () => {
      mounted = false;
    };
  }, []);

  if (!organizationData) {
    return <CircularProgress className={classes.spinner} />;
  } else {
    return (
      <React.Fragment>
        <Grid item xs={12} style={{ marginTop: "20px" }}>
          <Typography className={classes.pageTitle} component="h3" variant="h3">
            {t("Organization Management")}
          </Typography>
          {userObj.organizations.length > 1 && (
            <Box display="flex">
              <Typography
                className={classes.pageTitle}
                component="p"
                variant="body1"
              >
                {`${t("Active organization")}: `}
                {organizationData.name}
              </Typography>
              <Button
                style={{ marginLeft: "16px", height: "30px" }}
                onClick={handleSignoutOrganization}
                size="small"
                variant="outlined"
              >
                {t("Sign out of organization")}
              </Button>
            </Box>
          )}
        </Grid>
        <Grid item xs={12} sm={3} lg={2}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label={t("Organizational Management")}
            className={classes.tabs}
          >
            {/* <Tab label={t("General")} icon={<BusinessCenterIcon />} /> */}
            <Tab
              label={t("Users")}
              textColor="inherit"
              icon={<AccountCircleIcon />}
            />
            <Tab label={t("Teams")} textColor="inherit" icon={<GroupIcon />} />
            <Tab
              label={t("Departments")}
              textColor="inherit"
              icon={<BusinessIcon />}
            />
            <Tab
              label={t("Runned Sample Statistics")}
              icon={<EqualizerIcon />}
            />
            <Tab label={t("Report status")} icon={<EqualizerIcon />} />
          </Tabs>
        </Grid>
        <Grid item xs={12} sm={9} lg={10}>
          {/* <TabPanel value={value} index={0}>
            <GeneralManagement />
          </TabPanel> */}
          <TabPanel value={value} index={0}>
            <UsersManagement organization_id={userObj.activeOrganization.id} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <TeamManagement
              organization_id={userObj.activeOrganization.id}
              teams={organizationData.teams}
              setOrganizationData={setOrganizationData}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <DepartmentManagement
              organization_id={userObj.activeOrganization.id}
              departments={organizationData.departments}
              setOrganizationData={setOrganizationData}
            />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Grid container spacing={4}>
              <RunnedSamples
                organization_id={userObj.activeOrganization.id}
                pie={organizationData}
                classes={classes}
              />
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={4}>
            <AnnotationStatus
              organizationId={userObj.activeOrganization.id}
              userId={userObj.id}
              statusChoices={organizationData.status_choices}
            />
          </TabPanel>
        </Grid>
      </React.Fragment>
    );
  }
};

export const ManagementSection = connect(
  null,
  mapDispatchToProps
)(ConnectedManagementSection);
