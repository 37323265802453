/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import clsx from "clsx";
import {
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  IconButton,
  makeStyles,
  Chip,
  Collapse,
  Box,
  Typography,
  TablePagination,
  TableSortLabel,
  CircularProgress,
  Checkbox,
  TextField,
  colors,
} from "@material-ui/core";
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from "@material-ui/icons";
import { apiListProjects } from "src/api";
import { useTranslation } from "react-i18next";
import safeLogger from "src/services/safeLogger";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "250px",
    backgroundColor: theme.palette.background.paper,
    position: "relative",
  },
  spinner: {
    position: "absolute",
    top: "50%",
    left: "50%",
  },
  displayOverlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: colors.grey[500],
    opacity: 0.5,
    zIndex: 100,
  },
  searchBox: {
    padding: "5px 20px 10px 5px",
  },
  projectTable: {
    // "&::before": {
    //   content: "",
    //   position: "absolute",
    //   top: "-48px",
    //   height: "48px",
    //   backgroundColor: "#f57c00",
    // },
    "& .MuiTableCell-sizeSmall": {
      padding: "0 6px",
    },
    "& .MuiTableRow-root:hover": {
      backgroundColor: theme.palette.background.secondBackground,
    },
    "& *": {
      boxSizing: "inherit",
    },
  },
  enhancedTableHead: {
    "& .MuiTableCell-sizeSmall": {
      padding: "6px 24px 6px 16px",
    },
  },
  clickableRow: {
    cursor: "pointer",
  },
  selectedRow: {
    backgroundColor: colors.blueGrey[50],
  },
  rowButton: {
    width: "42px",
    "& input": {
      minWidth: "auto !important",
    },
  },
  projectDetailTable: {
    marginBottom: "10px",
  },
  errorStatus: {
    backgroundColor: theme.palette.error.main,
  },
  projectCompletedChip: {
    backgroundColor: theme.palette.success.main,
  },
  projectActiveChip: {
    backgroundColor: theme.palette.warning.main,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const ProjectDetailTable = (props) => {
  const classes = useStyles();
  const { projectData, projectsData, setProjectsData } = props;
  const { t } = useTranslation();

  const checkProjectStatus = (projectData) => {
    let anySamplesSelected = false;
    let anySampleNotSelected = false;
    for (let i = 0; i < projectData.samples.length; i++) {
      if (projectData.samples[i].selected && !anySamplesSelected) {
        anySamplesSelected = true;
      }
      if (projectData.samples[i].selected && !projectData.selected) {
        projectData.selected = true;
      }
      if (!projectData.samples[i].selected && !anySampleNotSelected) {
        anySampleNotSelected = true;
      }
    }
    projectData.selected = anySamplesSelected;
    projectData.indeterminate = projectData.selected && anySampleNotSelected;
  };

  const handleToggleSelect = (index) => (event, value) => {
    projectData.samples[index].selected = value;
    checkProjectStatus(projectData);
    setProjectsData([projectData]);
  };

  const sampleHeaders = {
    name: t("Sample"),
    started_at: t("Started at"),
    completed_at: t("Completed at"),
    status: t("Status"),
  };

  if (!projectData) {
    return <CircularProgress />;
  } else {
    return (
      <Table
        className={classes.projectDetailTable}
        size="small"
        aria-label="samples"
      >
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            {Object.values(sampleHeaders).map((headerItem, index) => (
              <TableCell key={index}>{headerItem}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Boolean(projectData.samples.length) &&
            projectData.samples.map((sampleData, index) => (
              <TableRow key={index}>
                <TableCell className={classes.rowButton}>
                  <Checkbox
                    onChange={handleToggleSelect(index)}
                    checked={sampleData.selected}
                  />
                </TableCell>
                <TableCell component="th" scope="row">
                  {sampleData.filename}
                </TableCell>
                <TableCell>
                  {sampleData.started_at && sampleData.started_at.split("T")[0]}
                </TableCell>
                <TableCell>
                  {sampleData.completed_at &&
                    sampleData.completed_at.split("T")[0]}
                </TableCell>
                <TableCell>
                  <Chip
                    color="primary"
                    size="small"
                    className={clsx({
                      [classes.projectActiveChip]:
                        sampleData.status === "analysing",
                      [classes.projectCompletedChip]:
                        sampleData.status === "completed",
                      [classes.errorStatus]:
                        sampleData.status === "failed" ||
                        sampleData.status === "cancelled",
                    })}
                    label={t(sampleData.status)}
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    );
  }
};

const ProjectRow = (props) => {
  const classes = useStyles();
  const [detailsOpen, setDetailsOpen] = useState(false);
  const { projectData, projectsData, setProjectsData, detailsOpenOverride } =
    props;
  const { t } = useTranslation();

  useEffect(() => {
    setDetailsOpen(detailsOpenOverride);
  }, [detailsOpenOverride]);

  const handleDetailsClick = (event) => {
    event.stopPropagation();
    setDetailsOpen(!detailsOpen);
  };

  const handleToggleSelect = (event, value) => {
    event.stopPropagation();
    projectData.selected = value;
    if (!value && projectData.indeterminate) {
      projectData.indeterminate = false;
    }
    for (let i = 0; i < projectData.samples.length; i++) {
      projectData.samples[i].selected = projectData.selected;
    }
    setProjectsData([...projectsData]);
  };

  return (
    <React.Fragment>
      <TableRow
        onClick={handleDetailsClick}
        className={clsx(classes.clickableRow, {
          [classes.selectedRow]: projectData.selected,
        })}
      >
        <TableCell className={classes.rowButton}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={handleDetailsClick}
          >
            {detailsOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell className={classes.rowButton}>
          <Checkbox
            onClick={(event) => {
              event.stopPropagation();
            }}
            indeterminate={projectData.indeterminate}
            checked={projectData.selected}
            onChange={handleToggleSelect}
          />
        </TableCell>
        <TableCell>{projectData.name}</TableCell>
        <TableCell>{projectData.created_at.split("T")[0]}</TableCell>
        <TableCell>
          <Chip
            color="primary"
            size="small"
            className={clsx({
              [classes.projectActiveChip]: projectData.status === "analysing",
              [classes.projectCompletedChip]:
                projectData.status === "completed",
              [classes.errorStatus]:
                projectData.status === "failed" ||
                projectData.status === "cancelled",
            })}
            label={t(projectData.status)}
          />
        </TableCell>
        <TableCell>{projectData.created_by}</TableCell>
      </TableRow>
      <TableRow style={{ backgroundColor: "#FAFAFA" }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={detailsOpen} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <ProjectDetailTable
                projectData={projectData}
                projectsData={projectsData}
                setProjectsData={setProjectsData}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const EnhancedTableHead = (props) => {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const { t } = useTranslation();

  const headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: t("Title"),
    },
    {
      id: "created_at",
      numeric: false,
      disablePadding: false,
      label: t("Date"),
    },
    { id: "status", numeric: false, disablePadding: false, label: t("Status") },
    {
      id: "created_by",
      numeric: false,
      disablePadding: false,
      label: t("Created by"),
    },
  ];

  return (
    <TableHead className={classes.enhancedTableHead}>
      <TableRow>
        <TableCell></TableCell>
        <TableCell></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const SearchBox = (props) => {
  const { classes, defaultProjectsData, setSearchValue, setProjectsData } =
    props;
  const { t } = useTranslation();

  const handleSearchProject = (event) => {
    setSearchValue(event.target.value);
  };

  return (
    <Box className={classes.searchBox}>
      <TextField
        fullWidth
        placeholder={t(
          "existing_projects_search_placeholder",
          "Search the name of the project or sample file name..."
        )}
        label={t("Search")}
        name="search"
        onChange={handleSearchProject}
      />
    </Box>
  );
};

export const ExistingProjectsTable = (props) => {
  const {
    userObj,
    setFiles,
    setRemoteFiles,
    projectId,
    setPhase,
    projectType,
    fileActionType,
    setFileActionType,
    disabled,
  } = props;
  const [loading, setLoading] = useState(true);
  const [defaultProjectsData, setDefaultProjectsData] = useState([]);
  const [projectsData, setProjectsData] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("created_at");
  const [page, setPage] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const classes = useStyles();
  const [count, setCount] = useState(0);
  const { t } = useTranslation();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    const tmpStabilizedThis = stabilizedThis.filter((val) => {
      if (
        val[0].project_type === projectType ||
        val[0].sequencer === projectType ||
        val[0].samples.filter(
          (e) => e && e.filename.includes(".bam").length > 0
        )
      )
        return (
          val[0].project_type === projectType ||
          val[0].sequencer === projectType ||
          val[0].samples.filter(
            (e) => e && e.filename.includes(".bam").length > 0
          )
        );
    });
    return tmpStabilizedThis.map((el) => el[0]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (projectsData.length) {
      if (loading) {
        setLoading(false);
      }
      getSelectedSamples();
    }
  }, [selectedProjects]);

  // organization_id,
  // page,
  // callback,
  // pageSize = 10,
  // sortState,
  // filterState,
  // search,
  // projectType

  useEffect(() => {
    let mounted = true;
    setLoading(false);
    apiListProjects(
      userObj.activeOrganization.id,
      page,
      (data, status) => {
        if (status === 200) {
          if (mounted) {
            setLoading(false);
          }
          let { results } = data;
          if (results.length) {
            for (let i = 0; i < results.length; i++) {
              results[i].selected = false;
              if (results[i].samples.length) {
                for (let j = 0; j < results[i].samples.length; j++) {
                  results[i].samples[j].selected = false;
                }
              }
            }
          }
          if (mounted) {
            setCount(data.count);
            setProjectsData([...results]);
            setDefaultProjectsData([...results]);
          }
        } else {
          safeLogger(status);
          safeLogger(data);
        }
      },
      rowsPerPage,
      { [orderBy]: order.toUpperCase() },
      {},
      searchValue,
      projectType === "nanopore" ? "exome_fastq" : projectType
    );

    return () => {
      mounted = false;
    };
  }, [page, rowsPerPage, order, orderBy, searchValue]);

  useEffect(() => {
    setPage(0);
  }, [rowsPerPage, order, orderBy, searchValue]);

  const getSelectedSamples = () => {
    if (fileActionType !== "select") {
      setFileActionType("select");
    }

    let sampleFiles = [];
    const selectedSamples = {};

    for (let i = 0; i < selectedProjects.length; i++) {
      if (selectedProjects[i].selected) {
        if (selectedProjects[i].indeterminate) {
          selectedSamples[selectedProjects[i].id] = [];
          for (let j = 0; j < selectedProjects[i].samples.length; j++) {
            if (selectedProjects[i].samples[j].selected) {
              sampleFiles.push({
                name: selectedProjects[i].samples[j].filename,
                sample_id: selectedProjects[i].samples[j].id,
              });
              // for api
              selectedSamples[selectedProjects[i].id].push(
                selectedProjects[i].samples[j].id
              );
            }
          }
        } else {
          for (let j = 0; j < selectedProjects[i].samples.length; j++) {
            sampleFiles.push({
              name: selectedProjects[i].samples[j].filename,
              sample_id: selectedProjects[i].samples[j].id,
            });
          }
          // for api
          selectedSamples[selectedProjects[i].id] = "whole";
        }
      }
    }
    setFiles(sampleFiles);
    setRemoteFiles(selectedSamples);
  };

  const selectProject = (projectData) => {
    setSelectedProjects((prev) => [...projectData]);
  };

  useEffect(() => {
    if (projectId && fileActionType === "select") {
      setPhase("submit");
    }
  }, [projectId]);

  return (
    <Box className={classes.root}>
      {disabled && (
        <div
          className={classes.displayOverlay}
          onClick={(event) => event.stopPropagation()}
        ></div>
      )}
      {loading ? (
        <CircularProgress className={classes.spinner} />
      ) : Boolean(projectsData.length) ? (
        <React.Fragment>
          <SearchBox
            classes={classes}
            setSearchValue={setSearchValue}
            defaultProjectsData={defaultProjectsData}
            setProjectsData={setProjectsData}
          />
          <Table size="small" className={classes.projectTable} hover={"true"}>
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(projectsData, getComparator(order, orderBy))
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((project, index) => (
                  <ProjectRow
                    projectData={project}
                    projectsData={projectsData}
                    setProjectsData={selectProject}
                    key={index}
                  />
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </React.Fragment>
      ) : projectsData.length === defaultProjectsData.length ? (
        <>
          <SearchBox
            classes={classes}
            setSearchValue={setSearchValue}
            defaultProjectsData={defaultProjectsData}
            setProjectsData={setProjectsData}
          />
          <Box
            width="100%"
            justifyContent="center"
            alignItems="center"
            display="flex"
            flexDirection="column"
            padding={4}
          >
            <img
              alt=""
              src="/assets/images/errors/no-project.svg"
              style={{ maxWidth: "200px" }}
            />
            <Typography>{t("You don't have any projects yet...")}</Typography>
          </Box>
        </>
      ) : (
        <React.Fragment>
          <SearchBox
            classes={classes}
            setSearchValue={setSearchValue}
            defaultProjectsData={defaultProjectsData}
            setProjectsData={setProjectsData}
          />
          <Box
            width="100%"
            justifyContent="center"
            alignItems="center"
            display="flex"
            flexDirection="column"
            padding={4}
          >
            <Typography>
              {t("No results were found for your keyword")}
            </Typography>
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
};
