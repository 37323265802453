export const reportSectionsConf = {
  exome_fastq: {
    name: "exome_fastq",
    label: "Exome (Fastq)",
    tabs: {
      statistics: {
        name: "statistics",
        label: "Statistics",
        order: 0,
        component: "",
      },
      variant_table: {
        name: "variant_table",
        label: "Variant table",
        order: 1,
        component: "",
      },
      cnv: {
        name: "cnv",
        label: "CNV",
        order: 2,
        component: "",
      },
    },
    detailsTabs: {
      main: {
        name: "main",
        label: "Main details",
        order: 0,
        component: "",
      },
      pathogenicity: {
        name: "pathogenicity",
        label: "Pathogenicity scores",
        order: 1,
        component: "",
      },
      population_conservation: {
        name: "population_conservation",
        label: "Population frequencies and conservation scores",
        order: 2,
        component: "",
      },
      external: {
        name: "external",
        label: "External databases (ClinVar, COSMIC, Phenotype)",
        order: 3,
        component: "",
      },
      comment: {
        name: "comments",
        label: "Comments",
        order: 4,
        component: "",
      },
      igv: {
        name: "igv",
        label: "Genome visualization",
        order: 5,
        component: "",
      },
      uniprot: {
        name: "main",
        label: "Uniprot",
        order: 6,
        component: "",
      },
      related_projects: {
        name: "main",
        label: "Related Projects",
        order: 7,
        component: "",
      },
      customize: {
        name: "main",
        label: "Customized Spesific Values",
        order: 8,
        component: "",
      },
    },
    charts: {
      v4: [
        "cnv_diagram_no_gender",
        "genomic_fraction_coverage",
        "chromosome_based_copy_ratio_no_treshold",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "indel_length",
        "transitions_and_transversions",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      v5: [
        "cnv_diagram_no_gender",
        "genomic_fraction_coverage",
        "chromosome_based_copy_ratio_no_treshold",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "indel_length",
        "transitions_and_transversions",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      v6: [
        "cnv_diagram_no_gender",
        "genomic_fraction_coverage",
        "chromosome_based_copy_ratio_no_treshold",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "indel_length",
        "transitions_and_transversions",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      v7: [
        "cnv_diagram_no_gender",
        "genomic_fraction_coverage",
        "chromosome_based_copy_ratio_no_treshold",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "indel_length",
        "transitions_and_transversions",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      v8: [
        "cnv_diagram_no_gender",
        "genomic_fraction_coverage",
        "chromosome_based_copy_ratio_no_treshold",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "indel_length",
        "transitions_and_transversions",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      v9: [
        "cnv_diagram_no_gender",
        "genomic_fraction_coverage",
        "chromosome_based_copy_ratio_no_treshold",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "indel_length",
        "transitions_and_transversions",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      "v10/somatic": [
        "cnv_diagram_no_gender",
        "genomic_fraction_coverage",
        "chromosome_based_copy_ratio_no_treshold",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "indel_length",
        "transitions_and_transversions",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      v10: [
        "cnv_diagram_no_gender",
        "genomic_fraction_coverage",
        "chromosome_based_copy_ratio_no_treshold",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "indel_length",
        "transitions_and_transversions",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      v11: [
        "cnv_diagram_no_gender",
        "genomic_fraction_coverage",
        "chromosome_based_copy_ratio_no_treshold",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "indel_length",
        "transitions_and_transversions",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      "v11/somatic": [
        "cnv_diagram_no_gender",
        "genomic_fraction_coverage",
        "chromosome_based_copy_ratio_no_treshold",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "indel_length",
        "transitions_and_transversions",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      v12: [
        "cnv_diagram_no_gender",
        "genomic_fraction_coverage",
        "chromosome_based_copy_ratio_no_treshold",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "indel_length",
        "transitions_and_transversions",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      "v12/somatic": [
        "cnv_diagram_no_gender",
        "genomic_fraction_coverage",
        "chromosome_based_copy_ratio_no_treshold",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "indel_length",
        "transitions_and_transversions",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      v13: [
        "cnv_diagram_no_gender",
        "genomic_fraction_coverage",
        "chromosome_based_copy_ratio_no_treshold",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "indel_length",
        "transitions_and_transversions",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      "v13/somatic": [
        "cnv_diagram_no_gender",
        "genomic_fraction_coverage",
        "chromosome_based_copy_ratio_no_treshold",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "indel_length",
        "transitions_and_transversions",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      v14: [
        "cnv_diagram_no_gender",
        "genomic_fraction_coverage",
        "chromosome_based_copy_ratio_no_treshold",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "indel_length",
        "transitions_and_transversions",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      "v14/somatic": [
        "cnv_diagram_no_gender",
        "genomic_fraction_coverage",
        "chromosome_based_copy_ratio_no_treshold",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "indel_length",
        "transitions_and_transversions",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
    },
    statistics: {
      v4: [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "mismatches_and_indels_(inside_of_regions)",
        "het_hom_ratio_res",
        "insert_size_(inside_of_regions)",
        "tstv",
      ],
      v5: [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "genebase",
        "insert_size_(inside_of_regions)",
        "mismatches_and_indels_(inside_of_regions)",
        "het_hom_ratio_res",
        "tstv",
        "tumor_burden",
      ],
      v6: [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "genebase",
        "insert_size_(inside_of_regions)",
        "mismatches_and_indels_(inside_of_regions)",
        "het_hom_ratio_res",
        "tstv",
        "tumor_burden",
      ],
      v7: [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "genebase",
        "insert_size_(inside_of_regions)",
        "mismatches_and_indels_(inside_of_regions)",
        "het_hom_ratio_res",
        "tstv",
        "tumor_burden",
      ],
      v8: [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "genebase",
        "insert_size_(inside_of_regions)",
        "mismatches_and_indels_(inside_of_regions)",
        "het_hom_ratio_res",
        "tstv",
        "tumor_burden",
      ],
      v9: [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "panelPercentages",
        "insert_size_(inside_of_regions)",
        "genebase",
        "het_hom_ratio_res",
        "dijital_panel",
        "tumor_burden",
        "tstv",
        "mismatches_and_indels_(inside_of_regions)",
      ],
      "v10/somatic": [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "panelPercentages",
        "insert_size_(inside_of_regions)",
        "genebase",
        "het_hom_ratio_res",
        "dijital_panel",
        "tumor_burden",
        "tstv",
        "mismatches_and_indels_(inside_of_regions)",
      ],
      v10: [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "panelPercentages",
        "insert_size_(inside_of_regions)",
        "genebase",
        "het_hom_ratio_res",
        "dijital_panel",
        "tumor_burden",
        "tstv",
        "mismatches_and_indels_(inside_of_regions)",
      ],
      "v11/somatic": [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "panelPercentages",
        "insert_size_(inside_of_regions)",
        "genebase",
        "het_hom_ratio_res",
        "dijital_panel",
        "tumor_burden",
        "tstv",
        "mismatches_and_indels_(inside_of_regions)",
      ],
      v11: [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "panelPercentages",
        "insert_size_(inside_of_regions)",
        "genebase",
        "het_hom_ratio_res",
        "dijital_panel",
        "tumor_burden",
        "tstv",
        "mismatches_and_indels_(inside_of_regions)",
      ],
      "v12/somatic": [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "panelPercentages",
        "insert_size_(inside_of_regions)",
        "genebase",
        "het_hom_ratio_res",
        "dijital_panel",
        "tumor_burden",
        "tstv",
        "mismatches_and_indels_(inside_of_regions)",
      ],
      v12: [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "panelPercentages",
        "insert_size_(inside_of_regions)",
        "genebase",
        "het_hom_ratio_res",
        "dijital_panel",
        "tumor_burden",
        "tstv",
        "mismatches_and_indels_(inside_of_regions)",
      ],
      "v13/somatic": [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "panelPercentages",
        "insert_size_(inside_of_regions)",
        "genebase",
        "het_hom_ratio_res",
        "dijital_panel",
        "tumor_burden",
        "tstv",
        "mismatches_and_indels_(inside_of_regions)",
      ],
      v13: [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "panelPercentages",
        "insert_size_(inside_of_regions)",
        "genebase",
        "het_hom_ratio_res",
        "dijital_panel",
        "tumor_burden",
        "tstv",
        "mismatches_and_indels_(inside_of_regions)",
      ],
      "v14/somatic": [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "panelPercentages",
        "insert_size_(inside_of_regions)",
        "genebase",
        "het_hom_ratio_res",
        "dijital_panel",
        "tumor_burden",
        "tstv",
        "mismatches_and_indels_(inside_of_regions)",
      ],
      v14: [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "panelPercentages",
        "insert_size_(inside_of_regions)",
        "genebase",
        "het_hom_ratio_res",
        "dijital_panel",
        "tumor_burden",
        "tstv",
        "mismatches_and_indels_(inside_of_regions)",
      ],
    },
  },
  nanopore: {
    name: "nanopore",
    label: "Nanopore (Fastq)",
    tabs: {
      statistics: {
        name: "statistics",
        label: "Statistics",
        order: 0,
        component: "",
      },
      variant_table: {
        name: "variant_table",
        label: "Variant table",
        order: 1,
        component: "",
      },
    },
    detailsTabs: {
      main: {
        name: "main",
        label: "Main details",
        order: 0,
        component: "",
      },
      pathogenicity: {
        name: "pathogenicity",
        label: "Pathogenicity scores",
        order: 1,
        component: "",
      },
      population_conservation: {
        name: "population_conservation",
        label: "Population frequencies and conservation scores",
        order: 2,
        component: "",
      },
      external: {
        name: "external",
        label: "External databases (ClinVar, COSMIC, Phenotype)",
        order: 3,
        component: "",
      },
      comment: {
        name: "comments",
        label: "Comments",
        order: 4,
        component: "",
      },
      igv: {
        name: "igv",
        label: "Genome visualization",
        order: 5,
        component: "",
      },
      uniprot: {
        name: "main",
        label: "Uniprot",
        order: 6,
        component: "",
      },
      related_projects: {
        name: "main",
        label: "Related Projects",
        order: 7,
        component: "",
      },
      customize: {
        name: "main",
        label: "Customized Spesific Values",
        order: 8,
        component: "",
      },
    },
    charts: {
      v4: [
        "cnv_diagram_no_gender",
        "genomic_fraction_coverage",
        "chromosome_based_copy_ratio_no_treshold",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "indel_length",
        "transitions_and_transversions",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      v5: [
        "cnv_diagram_no_gender",
        "genomic_fraction_coverage",
        "chromosome_based_copy_ratio_no_treshold",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "indel_length",
        "transitions_and_transversions",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      v6: [
        "cnv_diagram_no_gender",
        "genomic_fraction_coverage",
        "chromosome_based_copy_ratio_no_treshold",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "indel_length",
        "transitions_and_transversions",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      v7: [
        "cnv_diagram_no_gender",
        "genomic_fraction_coverage",
        "chromosome_based_copy_ratio_no_treshold",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "indel_length",
        "transitions_and_transversions",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      v8: [
        "cnv_diagram_no_gender",
        "genomic_fraction_coverage",
        "chromosome_based_copy_ratio_no_treshold",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "indel_length",
        "transitions_and_transversions",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      v9: [
        "cnv_diagram_no_gender",
        "genomic_fraction_coverage",
        "chromosome_based_copy_ratio_no_treshold",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "indel_length",
        "transitions_and_transversions",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      "v10/somatic": [
        "cnv_diagram_no_gender",
        "genomic_fraction_coverage",
        "chromosome_based_copy_ratio_no_treshold",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "indel_length",
        "transitions_and_transversions",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      v10: [
        "cnv_diagram_no_gender",
        "genomic_fraction_coverage",
        "chromosome_based_copy_ratio_no_treshold",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "indel_length",
        "transitions_and_transversions",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      v11: [
        "cnv_diagram_no_gender",
        "genomic_fraction_coverage",
        "chromosome_based_copy_ratio_no_treshold",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "indel_length",
        "transitions_and_transversions",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      "v11/somatic": [
        "cnv_diagram_no_gender",
        "genomic_fraction_coverage",
        "chromosome_based_copy_ratio_no_treshold",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "indel_length",
        "transitions_and_transversions",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      v12: [
        "cnv_diagram_no_gender",
        "genomic_fraction_coverage",
        "chromosome_based_copy_ratio_no_treshold",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "indel_length",
        "transitions_and_transversions",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      "v12/somatic": [
        "cnv_diagram_no_gender",
        "genomic_fraction_coverage",
        "chromosome_based_copy_ratio_no_treshold",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "indel_length",
        "transitions_and_transversions",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      v13: [
        "cnv_diagram_no_gender",
        "genomic_fraction_coverage",
        "chromosome_based_copy_ratio_no_treshold",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "indel_length",
        "transitions_and_transversions",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      "v13/somatic": [
        "cnv_diagram_no_gender",
        "genomic_fraction_coverage",
        "chromosome_based_copy_ratio_no_treshold",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "indel_length",
        "transitions_and_transversions",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      v14: [
        "cnv_diagram_no_gender",
        "genomic_fraction_coverage",
        "chromosome_based_copy_ratio_no_treshold",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "indel_length",
        "transitions_and_transversions",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      "v14/somatic": [
        "cnv_diagram_no_gender",
        "genomic_fraction_coverage",
        "chromosome_based_copy_ratio_no_treshold",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "indel_length",
        "transitions_and_transversions",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
    },
    statistics: {
      v4: [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "mismatches_and_indels_(inside_of_regions)",
        "het_hom_ratio_res",
        "insert_size_(inside_of_regions)",
        "tstv",
      ],
      v5: [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "genebase",
        "insert_size_(inside_of_regions)",
        "mismatches_and_indels_(inside_of_regions)",
        "het_hom_ratio_res",
        "tstv",
        "tumor_burden",
      ],
      v6: [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "genebase",
        "insert_size_(inside_of_regions)",
        "mismatches_and_indels_(inside_of_regions)",
        "het_hom_ratio_res",
        "tstv",
        "tumor_burden",
      ],
      v7: [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "genebase",
        "insert_size_(inside_of_regions)",
        "mismatches_and_indels_(inside_of_regions)",
        "het_hom_ratio_res",
        "tstv",
        "tumor_burden",
      ],
      v8: [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "genebase",
        "insert_size_(inside_of_regions)",
        "mismatches_and_indels_(inside_of_regions)",
        "het_hom_ratio_res",
        "tstv",
        "tumor_burden",
      ],
      v9: [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "panelPercentages",
        "insert_size_(inside_of_regions)",
        "genebase",
        "het_hom_ratio_res",
        "dijital_panel",
        "tumor_burden",
        "tstv",
        "mismatches_and_indels_(inside_of_regions)",
      ],
      "v10/somatic": [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "panelPercentages",
        "insert_size_(inside_of_regions)",
        "genebase",
        "het_hom_ratio_res",
        "dijital_panel",
        "tumor_burden",
        "tstv",
        "mismatches_and_indels_(inside_of_regions)",
      ],
      v10: [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "panelPercentages",
        "insert_size_(inside_of_regions)",
        "genebase",
        "het_hom_ratio_res",
        "dijital_panel",
        "tumor_burden",
        "tstv",
        "mismatches_and_indels_(inside_of_regions)",
      ],
      "v11/somatic": [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "panelPercentages",
        "insert_size_(inside_of_regions)",
        "genebase",
        "het_hom_ratio_res",
        "dijital_panel",
        "tumor_burden",
        "tstv",
        "mismatches_and_indels_(inside_of_regions)",
      ],
      v11: [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "panelPercentages",
        "insert_size_(inside_of_regions)",
        "genebase",
        "het_hom_ratio_res",
        "dijital_panel",
        "tumor_burden",
        "tstv",
        "mismatches_and_indels_(inside_of_regions)",
      ],
      "v12/somatic": [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "panelPercentages",
        "insert_size_(inside_of_regions)",
        "genebase",
        "het_hom_ratio_res",
        "dijital_panel",
        "tumor_burden",
        "tstv",
        "mismatches_and_indels_(inside_of_regions)",
      ],
      v12: [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "panelPercentages",
        "insert_size_(inside_of_regions)",
        "genebase",
        "het_hom_ratio_res",
        "dijital_panel",
        "tumor_burden",
        "tstv",
        "mismatches_and_indels_(inside_of_regions)",
      ],
      "v13/somatic": [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "panelPercentages",
        "insert_size_(inside_of_regions)",
        "genebase",
        "het_hom_ratio_res",
        "dijital_panel",
        "tumor_burden",
        "tstv",
        "mismatches_and_indels_(inside_of_regions)",
      ],
      v13: [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "panelPercentages",
        "insert_size_(inside_of_regions)",
        "genebase",
        "het_hom_ratio_res",
        "dijital_panel",
        "tumor_burden",
        "tstv",
        "mismatches_and_indels_(inside_of_regions)",
      ],
      "v14/somatic": [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "panelPercentages",
        "insert_size_(inside_of_regions)",
        "genebase",
        "het_hom_ratio_res",
        "dijital_panel",
        "tumor_burden",
        "tstv",
        "mismatches_and_indels_(inside_of_regions)",
      ],
      v14: [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "panelPercentages",
        "insert_size_(inside_of_regions)",
        "genebase",
        "het_hom_ratio_res",
        "dijital_panel",
        "tumor_burden",
        "tstv",
        "mismatches_and_indels_(inside_of_regions)",
      ],
    },
  },
  exome_vcf: {
    name: "exome_vcf",
    label: "Exome (VCF)",
    tabs: {
      statistics: {
        name: "statistics",
        label: "Statistics",
        order: 0,
        component: "",
      },
      variant_table: {
        name: "variant_table",
        label: "Variant table",
        order: 1,
        component: "",
      },
    },
    detailsTabs: {
      main: {
        name: "main",
        label: "Main details",
        order: 0,
        component: "",
      },
      pathogenicity: {
        name: "pathogenicity",
        label: "Pathogenicity scores",
        order: 1,
        component: "",
      },
      population_conservation: {
        name: "population_conservation",
        label: "Population frequencies and conservation scores",
        order: 2,
        component: "",
      },
      external: {
        name: "external",
        label: "External databases (ClinVar, COSMIC, Phenotype)",
        order: 3,
        component: "",
      },
      comment: {
        name: "comments",
        label: "Comments",
        order: 4,
        component: "",
      },
      uniprot: {
        name: "main",
        label: "Uniprot",
        order: 5,
        component: "",
      },
      related_projects: {
        name: "main",
        label: "Related Projects",
        order: 7,
        component: "",
      },
      customize: {
        name: "main",
        label: "Customized Spesific Values",
        order: 8,
        component: "",
      },
    },
    charts: {
      v5: ["indel_length", "transitions_and_transversions"],
      v6: ["indel_length", "transitions_and_transversions"],
      v7: ["indel_length", "transitions_and_transversions"],
      v8: ["indel_length", "transitions_and_transversions"],
      v9: ["indel_length", "transitions_and_transversions"],
      "v10/somatic": ["indel_length", "transitions_and_transversions"],
      v10: ["indel_length", "transitions_and_transversions"],
      v11: ["indel_length", "transitions_and_transversions"],
      "v11/somatic": ["indel_length", "transitions_and_transversions"],
      v12: ["indel_length", "transitions_and_transversions"],
      "v12/somatic": ["indel_length", "transitions_and_transversions"],
      v13: ["indel_length", "transitions_and_transversions"],
      "v13/somatic": ["indel_length", "transitions_and_transversions"],
    },
    statistics: {
      v5: ["het_hom_ratio_res", "tstv"],
      v6: ["het_hom_ratio_res", "tstv"],
      v7: ["het_hom_ratio_res", "tstv"],
      v9: ["het_hom_ratio_res", "tstv"],
      "v10/somatic": ["het_hom_ratio_res", "tstv"],
      v10: ["het_hom_ratio_res", "tstv"],
      "v11/somatic": ["het_hom_ratio_res", "tstv"],
      v11: ["het_hom_ratio_res", "tstv"],
      "v12/somatic": ["het_hom_ratio_res", "tstv"],
      v12: ["het_hom_ratio_res", "tstv"],
      "v13/somatic": ["het_hom_ratio_res", "tstv"],
      v13: ["het_hom_ratio_res", "tstv"],
    },
  },
  exome_bam: {
    name: "exome_bam",
    label: "Exome (BAM)",
    tabs: {
      statistics: {
        name: "statistics",
        label: "Statistics",
        order: 0,
        component: "",
      },
      variant_table: {
        name: "variant_table",
        label: "Variant table",
        order: 1,
        component: "",
      },
      cnv: {
        name: "cnv",
        label: "CNV",
        order: 2,
        component: "",
      },
    },
    detailsTabs: {
      main: {
        name: "main",
        label: "Main details",
        order: 0,
        component: "",
      },
      pathogenicity: {
        name: "pathogenicity",
        label: "Pathogenicity scores",
        order: 1,
        component: "",
      },
      population_conservation: {
        name: "population_conservation",
        label: "Population frequencies and conservation scores",
        order: 2,
        component: "",
      },
      external: {
        name: "external",
        label: "External databases (ClinVar, COSMIC, Phenotype)",
        order: 3,
        component: "",
      },
      comment: {
        name: "comments",
        label: "Comments",
        order: 4,
        component: "",
      },
      igv: {
        name: "igv",
        label: "Genome visualization",
        order: 5,
        component: "",
      },
      uniprot: {
        name: "main",
        label: "Uniprot",
        order: 6,
        component: "",
      },
      related_projects: {
        name: "main",
        label: "Related Projects",
        order: 7,
        component: "",
      },
      customize: {
        name: "main",
        label: "Customized Spesific Values",
        order: 8,
        component: "",
      },
    },
    charts: {
      v5: [
        "cnv_diagram_no_gender",
        "genomic_fraction_coverage",
        "chromosome_based_copy_ratio_no_treshold",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "indel_length",
        "transitions_and_transversions",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      v6: [
        "cnv_diagram_no_gender",
        "genomic_fraction_coverage",
        "chromosome_based_copy_ratio_no_treshold",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "indel_length",
        "transitions_and_transversions",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      v7: [
        "cnv_diagram_no_gender",
        "genomic_fraction_coverage",
        "chromosome_based_copy_ratio_no_treshold",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "indel_length",
        "transitions_and_transversions",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      v8: [
        "cnv_diagram_no_gender",
        "genomic_fraction_coverage",
        "chromosome_based_copy_ratio_no_treshold",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "indel_length",
        "transitions_and_transversions",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      v9: [
        "cnv_diagram_no_gender",
        "genomic_fraction_coverage",
        "chromosome_based_copy_ratio_no_treshold",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "indel_length",
        "transitions_and_transversions",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      "v10/somatic": [
        "cnv_diagram_no_gender",
        "genomic_fraction_coverage",
        "chromosome_based_copy_ratio_no_treshold",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "indel_length",
        "transitions_and_transversions",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      v10: [
        "cnv_diagram_no_gender",
        "genomic_fraction_coverage",
        "chromosome_based_copy_ratio_no_treshold",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "indel_length",
        "transitions_and_transversions",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      v11: [
        "cnv_diagram_no_gender",
        "genomic_fraction_coverage",
        "chromosome_based_copy_ratio_no_treshold",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "indel_length",
        "transitions_and_transversions",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      "v11/somatic": [
        "cnv_diagram_no_gender",
        "genomic_fraction_coverage",
        "chromosome_based_copy_ratio_no_treshold",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "indel_length",
        "transitions_and_transversions",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      v12: [
        "cnv_diagram_no_gender",
        "genomic_fraction_coverage",
        "chromosome_based_copy_ratio_no_treshold",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "indel_length",
        "transitions_and_transversions",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      "v12/somatic": [
        "cnv_diagram_no_gender",
        "genomic_fraction_coverage",
        "chromosome_based_copy_ratio_no_treshold",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "indel_length",
        "transitions_and_transversions",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      v13: [
        "cnv_diagram_no_gender",
        "genomic_fraction_coverage",
        "chromosome_based_copy_ratio_no_treshold",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "indel_length",
        "transitions_and_transversions",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      "v13/somatic": [
        "cnv_diagram_no_gender",
        "genomic_fraction_coverage",
        "chromosome_based_copy_ratio_no_treshold",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "indel_length",
        "transitions_and_transversions",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      v14: [
        "cnv_diagram_no_gender",
        "genomic_fraction_coverage",
        "chromosome_based_copy_ratio_no_treshold",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "indel_length",
        "transitions_and_transversions",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      "v14/somatic": [
        "cnv_diagram_no_gender",
        "genomic_fraction_coverage",
        "chromosome_based_copy_ratio_no_treshold",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "indel_length",
        "transitions_and_transversions",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
    },
    statistics: {
      v5: [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "genebase",
        "insert_size_(inside_of_regions)",
        "mismatches_and_indels_(inside_of_regions)",
        "het_hom_ratio_res",
        "tstv",
        "tumor_burden",
      ],
      v6: [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "genebase",
        "insert_size_(inside_of_regions)",
        "mismatches_and_indels_(inside_of_regions)",
        "het_hom_ratio_res",
        "tstv",
        "tumor_burden",
      ],
      v7: [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "genebase",
        "insert_size_(inside_of_regions)",
        "mismatches_and_indels_(inside_of_regions)",
        "het_hom_ratio_res",
        "tstv",
        "tumor_burden",
      ],
      v8: [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "genebase",
        "insert_size_(inside_of_regions)",
        "mismatches_and_indels_(inside_of_regions)",
        "het_hom_ratio_res",
        "tstv",
        "tumor_burden",
      ],
      v9: [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "panelPercentages",
        "insert_size_(inside_of_regions)",
        "genebase",
        "het_hom_ratio_res",
        "dijital_panel",
        "tumor_burden",
        "tstv",
        "mismatches_and_indels_(inside_of_regions)",
      ],
      "v10/somatic": [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "panelPercentages",
        "insert_size_(inside_of_regions)",
        "genebase",
        "het_hom_ratio_res",
        "dijital_panel",
        "tumor_burden",
        "tstv",
        "mismatches_and_indels_(inside_of_regions)",
      ],
      v10: [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "panelPercentages",
        "insert_size_(inside_of_regions)",
        "genebase",
        "het_hom_ratio_res",
        "dijital_panel",
        "tumor_burden",
        "tstv",
        "mismatches_and_indels_(inside_of_regions)",
      ],
      v11: [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "panelPercentages",
        "insert_size_(inside_of_regions)",
        "genebase",
        "het_hom_ratio_res",
        "dijital_panel",
        "tumor_burden",
        "tstv",
        "mismatches_and_indels_(inside_of_regions)",
      ],
      v12: [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "panelPercentages",
        "insert_size_(inside_of_regions)",
        "genebase",
        "het_hom_ratio_res",
        "dijital_panel",
        "tumor_burden",
        "tstv",
        "mismatches_and_indels_(inside_of_regions)",
      ],
      "v12/somatic": [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "panelPercentages",
        "insert_size_(inside_of_regions)",
        "genebase",
        "het_hom_ratio_res",
        "dijital_panel",
        "tumor_burden",
        "tstv",
        "mismatches_and_indels_(inside_of_regions)",
      ],
      v13: [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "panelPercentages",
        "insert_size_(inside_of_regions)",
        "genebase",
        "het_hom_ratio_res",
        "dijital_panel",
        "tumor_burden",
        "tstv",
        "mismatches_and_indels_(inside_of_regions)",
      ],
      "v13/somatic": [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "panelPercentages",
        "insert_size_(inside_of_regions)",
        "genebase",
        "het_hom_ratio_res",
        "dijital_panel",
        "tumor_burden",
        "tstv",
        "mismatches_and_indels_(inside_of_regions)",
      ],
      v14: [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "panelPercentages",
        "insert_size_(inside_of_regions)",
        "genebase",
        "het_hom_ratio_res",
        "dijital_panel",
        "tumor_burden",
        "tstv",
        "mismatches_and_indels_(inside_of_regions)",
      ],
      "v14/somatic": [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "panelPercentages",
        "insert_size_(inside_of_regions)",
        "genebase",
        "het_hom_ratio_res",
        "dijital_panel",
        "tumor_burden",
        "tstv",
        "mismatches_and_indels_(inside_of_regions)",
      ],
    },
  },
  trio: {
    name: "trio",
    label: "Trio",
    tabs: {
      statistics: {
        name: "statistics",
        label: "Statistics",
        order: 0,
        component: "",
      },
      variant_table: {
        name: "variant_table",
        label: "Variant table",
        order: 1,
        component: "",
      },
    },
    detailsTabs: {
      main: {
        name: "main",
        label: "Main details",
        order: 0,
        component: "",
      },
      pathogenicity: {
        name: "pathogenicity",
        label: "Pathogenicity scores",
        order: 1,
        component: "",
      },
      population_conservation: {
        name: "population_conservation",
        label: "Population frequencies and conservation scores",
        order: 2,
        component: "",
      },
      external: {
        name: "external",
        label: "External databases (ClinVar, COSMIC, Phenotype)",
        order: 3,
        component: "",
      },
      comment: {
        name: "comments",
        label: "Comments",
        order: 4,
        component: "",
      },
      igv: {
        name: "igv",
        label: "Genome visualization",
        order: 5,
        component: "",
      },
      uniprot: {
        name: "main",
        label: "Uniprot",
        order: 6,
        component: "",
      },
      related_projects: {
        name: "main",
        label: "Related Projects",
        order: 7,
        component: "",
      },
      customize: {
        name: "main",
        label: "Customized Spesific Values",
        order: 8,
        component: "",
      },
    },
    charts: {
      v0: [
        "indel_length",
        "transitions_and_transversions",
        "genomic_fraction_coverage",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      v1: [
        "indel_length",
        "transitions_and_transversions",
        "genomic_fraction_coverage",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      v2: [
        "indel_length",
        "transitions_and_transversions",
        "genomic_fraction_coverage",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      v3: [
        "indel_length",
        "transitions_and_transversions",
        "genomic_fraction_coverage",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      v4: [
        "indel_length",
        "transitions_and_transversions",
        "genomic_fraction_coverage",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      v5: [
        "indel_length",
        "transitions_and_transversions",
        "genomic_fraction_coverage",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      v6: [
        "indel_length",
        "transitions_and_transversions",
        "genomic_fraction_coverage",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      v7: [
        "indel_length",
        "transitions_and_transversions",
        "genomic_fraction_coverage",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      v8: [
        "indel_length",
        "transitions_and_transversions",
        "genomic_fraction_coverage",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
    },
    statistics: {
      v0: [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "genebase",
        "insert_size_(inside_of_regions)",
        "mismatches_and_indels_(inside_of_regions)",
        "het_hom_ratio_res",
        "tstv",
      ],
      v1: [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "genebase",
        "insert_size_(inside_of_regions)",
        "mismatches_and_indels_(inside_of_regions)",
        "het_hom_ratio_res",
        "tstv",
      ],
      v2: [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "genebase",
        "insert_size_(inside_of_regions)",
        "mismatches_and_indels_(inside_of_regions)",
        "het_hom_ratio_res",
        "tstv",
      ],
      v3: [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "panelPercentages",
        "insert_size_(inside_of_regions)",
        "genebase",
        "het_hom_ratio_res",
        "dijital_panel",
        "tumor_burden",
        "tstv",
        "mismatches_and_indels_(inside_of_regions)",
      ],
      v4: [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "panelPercentages",
        "insert_size_(inside_of_regions)",
        "genebase",
        "het_hom_ratio_res",
        "dijital_panel",
        "tumor_burden",
        "tstv",
        "mismatches_and_indels_(inside_of_regions)",
      ],
      v5: [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "panelPercentages",
        "insert_size_(inside_of_regions)",
        "genebase",
        "het_hom_ratio_res",
        "dijital_panel",
        "tumor_burden",
        "tstv",
        "mismatches_and_indels_(inside_of_regions)",
      ],
      v6: [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "panelPercentages",
        "insert_size_(inside_of_regions)",
        "genebase",
        "het_hom_ratio_res",
        "dijital_panel",
        "tumor_burden",
        "tstv",
        "mismatches_and_indels_(inside_of_regions)",
      ],
      v7: [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "panelPercentages",
        "insert_size_(inside_of_regions)",
        "genebase",
        "het_hom_ratio_res",
        "dijital_panel",
        "tumor_burden",
        "tstv",
        "mismatches_and_indels_(inside_of_regions)",
      ],
      v8: [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "panelPercentages",
        "insert_size_(inside_of_regions)",
        "genebase",
        "het_hom_ratio_res",
        "dijital_panel",
        "tumor_burden",
        "tstv",
        "mismatches_and_indels_(inside_of_regions)",
      ],
    },
  },
  acnv: {
    name: "acnv",
    label: "Advanced CNV",
    tabs: {
      statistics: {
        name: "statistics",
        label: "Statistics",
        order: 0,
        component: "",
      },
      cnv: {
        name: "cnv",
        label: "CNV",
        order: 1,
        component: "",
      },
    },
    detailsTabs: {
      igv: {
        name: "igv",
        label: "Genome visualization",
        order: 5,
        component: "",
      },
      uniprot: {
        name: "main",
        label: "Uniprot",
        order: 6,
        component: "",
      },
      related_projects: {
        name: "main",
        label: "Related Projects",
        order: 7,
        component: "",
      },
      customize: {
        name: "main",
        label: "Customized Spesific Values",
        order: 8,
        component: "",
      },
    },
    charts: {
      v0: [
        "cnv_diagram",
        "genomic_fraction_coverage",
        "chromosome_based_copy_ratio",
        "chromosome_based_copy_ratio_male_gender",
        "copy_number_copy_ratio",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      v1: [
        "cnv_diagram",
        "genomic_fraction_coverage",
        "chromosome_based_copy_ratio",
        "chromosome_based_copy_ratio_male_gender",
        "copy_number_copy_ratio",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      v2: [
        "cnv_diagram",
        "genomic_fraction_coverage",
        "chromosome_based_copy_ratio",
        "chromosome_based_copy_ratio_male_gender",
        "copy_number_copy_ratio",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      v11: [
        "cnv_diagram",
        "genomic_fraction_coverage",
        "chromosome_based_copy_ratio",
        "chromosome_based_copy_ratio_male_gender",
        "copy_number_copy_ratio",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      "v11/somatic": [
        "cnv_diagram",
        "genomic_fraction_coverage",
        "chromosome_based_copy_ratio",
        "chromosome_based_copy_ratio_male_gender",
        "copy_number_copy_ratio",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      v12: [
        "cnv_diagram",
        "genomic_fraction_coverage",
        "chromosome_based_copy_ratio",
        "chromosome_based_copy_ratio_male_gender",
        "copy_number_copy_ratio",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      "v12/somatic": [
        "cnv_diagram",
        "genomic_fraction_coverage",
        "chromosome_based_copy_ratio",
        "chromosome_based_copy_ratio_male_gender",
        "copy_number_copy_ratio",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      v13: [
        "cnv_diagram",
        "genomic_fraction_coverage",
        "chromosome_based_copy_ratio",
        "chromosome_based_copy_ratio_male_gender",
        "copy_number_copy_ratio",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      "v13/somatic": [
        "cnv_diagram",
        "genomic_fraction_coverage",
        "chromosome_based_copy_ratio",
        "chromosome_based_copy_ratio_male_gender",
        "copy_number_copy_ratio",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      v14: [
        "cnv_diagram",
        "genomic_fraction_coverage",
        "chromosome_based_copy_ratio",
        "chromosome_based_copy_ratio_male_gender",
        "copy_number_copy_ratio",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
      "v14/somatic": [
        "cnv_diagram",
        "genomic_fraction_coverage",
        "chromosome_based_copy_ratio",
        "chromosome_based_copy_ratio_male_gender",
        "copy_number_copy_ratio",
        "chromosome_mean_coverage",
        "mapping_quality_across_reference",
        "coverage_histogram",
        "acgt_content",
        "insert_size_his",
        "mapped_reads_nucleotide_content",
        "mapped_reads_gc_content_disribution",
        "mapped_read_clipping_profile",
      ],
    },
    statistics: {
      v0: [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "insert_size_(inside_of_regions)",
        "het_hom_ratio_res",
        "mismatches_and_indels_(inside_of_regions)",
        "tstv",
        "tumor_burden",
      ],
      v1: [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "insert_size_(inside_of_regions)",
        "het_hom_ratio_res",
        "mismatches_and_indels_(inside_of_regions)",
        "tstv",
        "tumor_burden",
      ],
      v2: [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "insert_size_(inside_of_regions)",
        "het_hom_ratio_res",
        "mismatches_and_indels_(inside_of_regions)",
        "tstv",
        "tumor_burden",
      ],
      v3: [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "insert_size_(inside_of_regions)",
        "het_hom_ratio_res",
        "mismatches_and_indels_(inside_of_regions)",
        "tstv",
        "tumor_burden",
      ],
      v4: [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "insert_size_(inside_of_regions)",
        "het_hom_ratio_res",
        "mismatches_and_indels_(inside_of_regions)",
        "tstv",
        "tumor_burden",
      ],
      v5: [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "insert_size_(inside_of_regions)",
        "het_hom_ratio_res",
        "mismatches_and_indels_(inside_of_regions)",
        "tstv",
        "tumor_burden",
      ],
      v6: [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "insert_size_(inside_of_regions)",
        "het_hom_ratio_res",
        "mismatches_and_indels_(inside_of_regions)",
        "tstv",
        "tumor_burden",
      ],
      v7: [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "insert_size_(inside_of_regions)",
        "het_hom_ratio_res",
        "mismatches_and_indels_(inside_of_regions)",
        "tstv",
        "tumor_burden",
      ],
      v8: [
        "globals",
        "coverage_(inside_of_regions)",
        "mapping_quality_(inside_of_regions)",
        "insert_size_(inside_of_regions)",
        "het_hom_ratio_res",
        "mismatches_and_indels_(inside_of_regions)",
        "tstv",
        "tumor_burden",
      ],
    },
  },
};
