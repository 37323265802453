/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  colors,
  Container,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";
import Page from "src/components/Page";
import _ from "lodash";
import { TableLayoutEditor } from "./tableLayoutEditor";
import { Save as SaveIcon } from "@material-ui/icons";
import {
  apiGetTableConf,
  apiPostTableConf,
  apiTableConfDetails,
  apiUpdateTableConf,
} from "src/api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import safeLogger from "src/services/safeLogger";
import { loadUser } from "src/redux/actions";
import { adaptUserCols } from "./helpers";
import { Autocomplete, TabContext, TabList, TabPanel } from "@material-ui/lab";
import AdvanceFilter from "../../reports/advancedFilter";

const useStyles = makeStyles((theme) => ({
  spinner: {
    position: "absolute",
    top: "48%",
    left: "48%",
  },
  root: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    zIndex: "-1 !important",
    position: "unset",
    background: "inherit",
  },
  pageTitle: {
    color: `${theme.palette.text.primary} !important`,
  },
  projectType: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(5),
    color: colors.grey[700],
  },
  sectionTitle: {
    marginTop: "30px",
    marginBottom: "10px",
  },
  form: {
    width: "100%",
  },
  box: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    gap: theme.spacing(2),
  },
}));

const mapStateToProps = (state) => {
  return {
    appConf: state.appConf,
    userObject: state.userObject,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    loadUser: () => dispatch(loadUser()),
  };
}

const ConnectedTableConfigPage = (props) => {
  const { appConf, userObject, loadUser, version, schemas } = props;
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const projectType =
    new URLSearchParams(useLocation().search).get("project_type") ??
    "exome_fastq";
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState("");
  const [schema, setSchema] = useState();
  const [tableConfList, setTableConfList] = useState([]);
  const [defaultColObj, setDefaultColObj] = useState(undefined);
  const [advanceFilter, setAdvanceFilter] = useState();
  const [schemaMeta, setSchemaMeta] = useState(version);
  const [columnOptions, setColumnOptions] = useState(undefined);
  const [columnLoading, setColumnLoading] = useState(true);
  const [type, setType] = useState("filter");
  const [autoCompleteOption, setAutoCompleteOption] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    setSchema({ ...schemas });
  }, [schemas]);

  const titleChangeHandler = (event) => {
    setTitle(event.target.value);
  };

  const tableConfCallback = (data, status) => {
    if (status === 200) {
      // safeLogger(data);
      setTableConfList(data);
    } else {
      safeLogger(data);
      enqueueSnackbar(
        t("unhandled_error_response", "There was an error. Please try again"),
        {
          variant: "error",
        }
      );
    }
  };

  const handleAutocomplete = (event, value) => {
    setAutoCompleteOption(value);
  };

  useEffect(() => {
    apiGetTableConf(
      projectType,
      userObject.activeOrganization.id,
      tableConfCallback
    );
  }, []);

  const selectedTableConfDetailsCallback = (data, status) => {
    // if (_.isArray(data.data)) {
    //   setColumnOptions({
    //     type: type,
    //     config: [...data.data],
    //     advanceFilter: {},
    //   });
    // } else {
    //   setColumnOptions(data.data);
    // }
    const userVisibleCols = (
      _.isArray(data.data) ? data.data : data.data.config
    ).filter((col) => {
      return col.representation &&
        col.representation.options &&
        (!col?.representation?.options?.hasOwnProperty("visible") ||
          (col?.representation?.options?.hasOwnProperty("visible") &&
            col?.representation?.options?.visible))
        ? col.representation.options.layout_visible
        : false;
    });
    (columnOptions?.type ? columnOptions.config : columnOptions).forEach(
      (options) => {
        userVisibleCols.forEach((col) => {
          if (options.name === col.name) {
            if (col.representation.kind === "main_table_cell")
              options.representation.kind = "main_table_cell";
            else options.representation.kind = "tabbed_grid";
          }
        });
      }
    );
    let tmpAdvanceFilter = {};
    if (data.data.advanceFilter) {
      tmpAdvanceFilter = data.data.advanceFilter;
      setAdvanceFilter(tmpAdvanceFilter);
    }
  };

  useEffect(() => {
    if (!_.isEmpty(autoCompleteOption) && defaultColObj) {
      apiTableConfDetails(
        autoCompleteOption.id,
        projectType,
        selectedTableConfDetailsCallback
      );
    }
  }, [autoCompleteOption, defaultColObj]);

  useEffect(() => {
    if (!_.isEmpty(schema)) {
      const tmpDefaultColObj = {};
      for (let i = 0; i < schema.columns.length; i++) {
        tmpDefaultColObj[schema.columns[i].name] = schema.columns[i];
      }
      setDefaultColObj({ ...tmpDefaultColObj });
    }
  }, [schema]);

  const tableDetailsCallback = (data, status) => {
    if (status === 200) {
      safeLogger(data);
      setColumnLoading(true);
      setTitle(data.name);
      setSchema(data.schema_details);
      const userVisibleCols = (
        data.data.config ? data.data.config : data.data
      ).filter((col) => {
        return col.representation &&
          col.representation.options &&
          (!col?.representation?.options?.hasOwnProperty("visible") ||
            (col?.representation?.options?.hasOwnProperty("visible") &&
              col?.representation?.options?.visible))
          ? col.representation.options.layout_visible
          : false;
      });

      if (!userVisibleCols.length) {
        const adaptedCols = adaptUserCols(data.data);
        apiUpdateTableConf(
          id,
          { name: data.name, data: adaptedCols },
          (data, status) => {
            if (status === 200) {
              window.location.reload();
            }
          }
        );
      } else {
        setColumnOptions({
          type: data.data.type,
          advanceFilter: data.data.advanceFilter,
          config: userVisibleCols,
        });
      }
      setType(data.data.type);
      setSchemaMeta({
        version: Number(
          data.schema_version.replace("v", "").replace("/somatic", "")
        ),
      });
      setAdvanceFilter(data.data.advanceFilter);
      setColumnLoading(false);
    } else {
      safeLogger(data);
    }
  };

  useEffect(() => {
    if (id) {
      apiTableConfDetails(id, projectType, tableDetailsCallback);
    }
  }, []);

  useEffect(() => {
    if (appConf && !id) {
      const tmpColumns = JSON.parse(JSON.stringify(schemas.columns)).filter(
        (col) => {
          return col.representation &&
            col.representation.options &&
            (!col?.representation?.options?.hasOwnProperty("visible") ||
              (col?.representation?.options?.hasOwnProperty("visible") &&
                col?.representation?.options?.visible))
            ? col.representation.options.layout_visible
            : false;
        }
      );
      setColumnOptions({ type: "filter", config: tmpColumns });
      setColumnLoading(false);
    }
  }, [appConf, schema]);

  useEffect(() => {
    if (!columnLoading) {
      for (let i = 0; i < columnOptions.config.length; i++) {
        if (columnOptions.config[i].kind === "enumeration") {
          if (!columnOptions.config[i]["filterValues"]) {
            columnOptions.config[i]["filterValues"] = [];
            columnOptions.config[i].activeFilterCount = 0;
          }
        } else if (columnOptions.config[i].kind === "numeric") {
          if (!columnOptions.config[i]["filterRange"]) {
            columnOptions.config[i]["filterRange"] = [];
            columnOptions.config[i].activeFilterCount = 0;
          }
        }
      }
    }
  }, [columnOptions]);

  useEffect(() => {
    if (columnOptions) {
      setLoading(false);
    }
  }, [columnOptions]);

  const prepareColumns = () => {
    const mainTableCols = [];
    const otherCols = [];

    for (let i = 0; i < columnOptions.config.length; i++) {
      if (
        columnOptions.config[i].kind === "numeric" &&
        columnOptions.config[i]?.filterRange
      ) {
        if (
          columnOptions.config[i]?.filterRange.length === 0 ||
          (columnOptions.config[i]?.filterRange[0] === "" &&
            columnOptions.config[i]?.filterRange[1] === "")
        ) {
          delete columnOptions.config[i]?.filterRange;
        }
      }
      if (columnOptions.config[i].representation.kind === "main_table_cell") {
        mainTableCols.push(columnOptions.config[i]);
      } else {
        if (
          columnOptions.config[i].representation.kind === "unspecified" &&
          defaultColObj[columnOptions.config[i].name] &&
          defaultColObj[columnOptions.config[i].name].representation.kind !==
            "main_table_cell"
        ) {
          columnOptions.config[i].representation.kind =
            defaultColObj[columnOptions.config[i].name].representation.kind;
        }
        otherCols.push(columnOptions.config[i]);
      }
    }

    return [...mainTableCols, ...otherCols];
  };

  const getUnselectedColumns = () => {
    return schema.columns.filter((column) => {
      for (let i = 0; i < columnOptions.config.length; i++) {
        if (columnOptions.config[i].name === column.name) {
          return false;
        }
      }

      if (column.representation.kind === "main_table_cell") {
        column.representation.kind = "unspecified";
      }
      return true;
    });
  };

  const confSaveCallback = (data, status) => {
    if (status === 200 || status === 201) {
      navigate("/settings");
    } else if (status === 409) {
      enqueueSnackbar(
        t(
          "table_conf_name_not_unique_error",
          "A Table configuration with the name '{{title}}' already exists! Please use a unique name and try again.",
          { title: title }
        ),
        { variant: "error" }
      );
    } else {
      enqueueSnackbar(data, { variant: "error" });
    }
  };

  const submitHandler = (event) => {
    if (event) {
      event.preventDefault();
    }
    const userConf = {};
    let tableConf = prepareColumns();
    tableConf.push(...getUnselectedColumns());

    userConf["name"] = title;
    userConf["type"] = type;
    userConf["data"] = {
      type: type,
      config: tableConf,
      advanceFilter: advanceFilter,
    };

    if (id) {
      apiUpdateTableConf(id, userConf, confSaveCallback);
    } else {
      userConf["schema"] = schema.id;
      apiPostTableConf(userConf, confSaveCallback);
    }
  };

  const handleChange = (e) => {
    columnOptions["type"] = e.target.value;
    setColumnOptions({ ...columnOptions });
    setType(e.target.value);
  };

  const handleTabChange = (e, value) => {
    columnOptions["type"] = value;
    setColumnOptions({ ...columnOptions });
    setType(value);
  };

  if (loading || columnLoading) {
    return <CircularProgress className={classes.spinner} />;
  } else {
    return (
      <Page className={classes.root} title={t("Dashboard Settings")}>
        <Container>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                className={classes.pageTitle}
                component="h3"
                data-testid={"tableconf-name"}
                variant="h3"
              >
                {t("Variant Table Configuration")}
              </Typography>
              <Box className={classes.projectType}>
                <Typography component="h5" variant="h5" color="textSecondary">
                  {t("Sequance Type")}: &nbsp;
                  {t(schema.version.includes("/") ? "Somatic" : "Germline")}
                </Typography>
                {projectType && (
                  <Typography component="h5" variant="h5" color="textSecondary">
                    {t("Project type")}
                    {`: ${appConf.entities.project_types[projectType]}`}
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item xs={4} style={{ marginBottom: "15px" }}>
              <Typography
                component="h4"
                variant="h4"
                style={{ marginBottom: "15px" }}
              >
                {t("Get old table config")}
              </Typography>
              <Autocomplete
                onChange={handleAutocomplete}
                options={tableConfList}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => {
                  params.inputProps = {
                    ...params.inputProps,
                    "data-testid": "tableconf-preset",
                  };
                  return (
                    <TextField
                      {...params}
                      className={classes.inputField}
                      label={t("Select a table configuration")}
                      variant="outlined"
                    />
                  );
                }}
              />
            </Grid>
            <form className={classes.form} onSubmit={submitHandler}>
              <Grid item sm={12}>
                <Typography
                  component="h4"
                  variant="h4"
                  style={{ marginBottom: "15px" }}
                >
                  {t("Table Layout")}
                </Typography>
                <Box className={classes.box}>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    label={t("Layout Name")}
                    name="layout-name"
                    required
                    className={classes.inputField}
                    value={title}
                    onChange={titleChangeHandler}
                    style={{ marginBottom: "15px" }}
                  />
                </Box>
              </Grid>
              <Grid item sm={12}>
                <TabContext value={type}>
                  <TabList
                    onChange={handleTabChange}
                    aria-label="lab API tabs example"
                  >
                    <Tab label="Normal Filter" value="filter" />
                    <Tab label="Advance Filter" value="advance_filter" />
                  </TabList>
                  <TabPanel value="filter">
                    <TableLayoutEditor
                      appConf={appConf}
                      userObject={userObject}
                      loadUser={loadUser}
                      schemaId={schema.id}
                      colDetails={
                        columnOptions?.type && columnOptions?.type === "filter"
                          ? columnOptions?.config
                          : columnOptions
                      }
                      columnOptions={
                        columnOptions?.type && columnOptions?.type === "filter"
                          ? columnOptions?.config
                          : columnOptions
                      }
                      setColumnOptions={(props) => {
                        setColumnOptions({
                          type: "filter",
                          config: [...props],
                        });
                      }}
                    />
                  </TabPanel>
                  <TabPanel value="advance_filter">
                    <AdvanceFilter
                      fields={
                        columnOptions?.type
                          ? columnOptions?.config
                          : columnOptions
                      }
                      schemaId={schema.id}
                      version={schemaMeta?.version}
                      setAdvanceFilter={setAdvanceFilter}
                      advanceFilter={advanceFilter}
                    />
                  </TabPanel>
                </TabContext>
              </Grid>
              <Box display="flex" mt={4} pb={6}>
                <Button
                  startIcon={<SaveIcon />}
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{ marginLeft: "auto" }}
                >
                  {t("Save")}
                </Button>
              </Box>
            </form>
          </Grid>
        </Container>
      </Page>
    );
  }
};

export const TableConfigPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedTableConfigPage);
