import { Autocomplete } from "@material-ui/lab";
import { memo, useState } from "react";
import { ListboxComponent } from "./listBoxComponent";
import { format } from "date-fns";
import {
  Box,
  Checkbox,
  IconButton,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { TooltipMenuItem } from "./toolTipMenuItem";
import { useTranslation } from "react-i18next";
import { SelectAllOutlined } from "@material-ui/icons";
import { getTranslated } from "../utils/helpers";

export const VirtualizedAutocomplete = memo((props) => {
  const {
    classes,
    appConf,
    tableKind,
    columns,
    handleSelectFilteredAll,
    columnIndex,
    handleChange,
    controlledValue,
    options,
  } = props;
  const { t, i18n } = useTranslation();
  const [tmpValue, setTmpValue] = useState("");
  const label = columns[columnIndex].label ?? {};

  return (
    <Autocomplete
      id={props.id}
      style={{ width: "100%" }}
      multiple
      // disableListWrap
      disableCloseOnSelect
      ListboxComponent={ListboxComponent}
      options={options}
      value={controlledValue.filter((value, index, self) => {
        return self.indexOf(value) === index;
      })}
      onInputChange={(event, value, change) => {
        if (change !== "reset") {
          setTmpValue(value);
        }
        // inputChange(value);
      }}
      inputValue={tmpValue}
      onChange={handleChange}
      getOptionLabel={(option) => {
        let altLabel;
        if (tableKind === "project") {
          switch (columns[columnIndex].name) {
            case "project_type":
              altLabel = appConf.entities.project_types[option];
              break;
            case "pipeline_class":
              for (let projectType in appConf.entities.pipeline_classes) {
                if (appConf.entities.pipeline_classes[projectType][option]) {
                  altLabel =
                    appConf.entities.pipeline_classes[projectType][option];
                }
              }
              break;
            case "kit":
              altLabel = appConf.entities.kits[option];
              break;
            case "created_at":
              altLabel = format(new Date(option), "dd/MM/yyyy HH:mm");
              break;
            default:
              break;
          }
        }
        return altLabel ?? option;
      }}
      renderOption={(option, { selected }) => {
        let altLabel;
        if (tableKind === "project") {
          switch (columns[columnIndex].name) {
            case "project_type":
              altLabel = appConf.entities.project_types[option];
              break;
            case "pipeline_class":
              for (let projectType in appConf.entities.pipeline_classes) {
                if (appConf.entities.pipeline_classes[projectType][option]) {
                  altLabel =
                    appConf.entities.pipeline_classes[projectType][option];
                }
              }
              break;
            case "kit":
              altLabel = appConf.entities.kits[option];
              break;
            case "created_at":
              altLabel = format(new Date(option), "dd/MM/yyyy HH:mm");
              break;
            default:
              break;
          }
        } else if (tableKind === "variant") {
          if (
            columns[columnIndex].representation &&
            columns[columnIndex].representation.options &&
            columns[columnIndex].representation.options.hide_prefix
          ) {
            altLabel = option.split(
              columns[columnIndex].representation.options.prefix_indicator
            )[1];
          }
        }

        return (
          <div className={classes.autocompleteMenuItem}>
            <Checkbox
              className="menuItemCheckBox"
              checked={columns[columnIndex].filterValues[option]}
            />
            <TooltipMenuItem content={altLabel ?? option} />
          </div>
        );
      }}
      renderInput={(params) => {
        return (
          <Box width="100%" display="flex" style={{ gap: 5 }}>
            <TextField
              {...params}
              variant="outlined"
              placeholder={getTranslated(label) ?? ""}
            />
            <Box height="fit-content">
              <Tooltip title={t("Select all filtered items.")}>
                <IconButton
                  style={{ width: "100%" }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleSelectFilteredAll(tmpValue);
                  }}
                >
                  <SelectAllOutlined />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        );
      }}
    />
  );
});
