import React from "react";
import {
  Box,
  ButtonBase,
  Container,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { loadAppConf, loadUser } from "src/redux/actions";
import Page from "../Page";
import { Business as BusinessIcon } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import { apiLoadProfile } from "src/api";
import { getUserActivePermissions } from "../utils";
import { apiLoadOrganizationKits } from "src/api/endpoints";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: "100vw",
    marginTop: theme.spacing(2),
    display: "flex",
  },
  organizationButton: {
    padding: theme.spacing(3),
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    border: "1px solid #e1e1e1",
    borderRadius: "6px",
    background: theme.palette.background.paper,
    height: "100%",
    "& .MuiSvgIcon-root": {
      marginBottom: theme.spacing(2),
      fill: "#424242",
      width: "3em",
      height: "3em",
    },
    "&:hover": {
      background: "#f1f1f1",
      boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 3px rgba(0,0,0,0.24)",
    },
  },
  form: {
    minWidth: "250px", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
}));

const ConnectedOrgButton = (props) => {
  const { org, loadUser, appConf, loadAppConf } = props;
  const classes = useStyles();
  const navigate = useNavigate();

  const handleClick = () => {
    apiLoadProfile(org.id, (data, status) => {
      if (status === 200) {
        const newUserObject = data;
        newUserObject["activePermissions"] =
          getUserActivePermissions(newUserObject);
        newUserObject["activeOrganization"] = org;
        apiLoadOrganizationKits(org.id, (kits, status) => {
          if (status === 200) {
            appConf.entities.kits = { ...kits, ...appConf.entities.kits };
          }
        });
        loadAppConf(appConf);
        localStorage.setItem("activeOrganizationId", org.id);
        loadUser({ ...newUserObject });
        navigate("/dashboard");
      }
    });
  };

  return (
    <ButtonBase onClick={handleClick} className={classes.organizationButton}>
      <BusinessIcon />
      <Typography component="span">{org.name}</Typography>
    </ButtonBase>
  );
};

function mapOrgButtonDispatchToProps(dispatch) {
  return {
    loadAppConf: (confData) => dispatch(loadAppConf(confData)),
  };
}

const mapOrgButtonToProps = (state) => ({ appConf: state.appConf });

const OrgButton = connect(
  mapOrgButtonToProps,
  mapOrgButtonDispatchToProps
)(ConnectedOrgButton);

const mapStateToProps = (state) => {
  return {
    userObject: state.userObject,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    loadUser: (data) => dispatch(loadUser(data)),
  };
}

const ConnectedSelectOrganization = (props) => {
  const { userObject, loadUser } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Page className={classes.root} title={t("Select organization")}>
      <Box
        alignItems="center"
        justifyContent="center"
        className={classes.mainContainer}
      >
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h2" component="h2">
                {t("Select organization")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography component="p">
                {t("Please select your organzation")}
              </Typography>
            </Grid>
            <Grid item container spacing={4} xs={12}>
              {userObject.organizations.map((org) => (
                <Grid item xs={2} key={org.id}>
                  <OrgButton org={org} loadUser={loadUser} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Page>
  );
};

export const SelectOrganizationPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedSelectOrganization);
