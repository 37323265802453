import { List } from "react-virtualized";
import React from "react";
import { Box } from "@material-ui/core";

export const ListboxComponent = function ListboxComponent(props) {
  const { children, role, ...other } = props;
  const itemCount = Array.isArray(children) ? children.length : 0;
  const itemSize = 45;

  return (
    <div style={{ width: "100%" }}>
      <div style={{ width: "100%" }} {...other}>
        <List
          height={200}
          width={300}
          rowHeight={itemSize}
          overscanCount={5}
          rowCount={itemCount}
          rowRenderer={(props) => {
            return (
              <Box width="100%">
                {React.cloneElement(children[props.index], {
                  style: {
                    ...props.style,
                  },
                })}
              </Box>
            );
          }}
          role={role}
        />
      </div>
    </div>
  );
};
