import React from "react";
import { connect } from "react-redux";
import Page from "src/components/Page";
import {
  Container,
  Grid,
  makeStyles,
  Paper,
  Avatar,
  Typography,
  colors,
} from "@material-ui/core";
import {
  Business as BusinessIcon,
  BusinessCenter as BusinessCenterIcon,
} from "@material-ui/icons";
import { EditAccount } from "./editAccount";
import { ManagementSection } from "./manage";
import { EditDepartment } from "./manage/departmentManagement/editDepartment";
import { EditUser } from "./manage/userManagement/editUser";
import { EditTeam } from "./manage/teamManagement/editTeam";
import { useTranslation } from "react-i18next";
import { getUserFullName, userHasPermission } from "src/components/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(8),
    paddingTop: theme.spacing(8),
  },
  pageTitle: {
    marginBottom: "40px",
    color: colors.grey[800],
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "1em",
  },
  avatar: {
    margin: theme.spacing(1),
    width: "75px",
    height: "75px",
    marginTop: "-55px",
    "& img": {
      width: "75px",
      height: "75px",
    },
  },
}));

const mapStateToProps = (state) => {
  return { userObject: state.userObject };
};

const ConnectedAccountPage = (props) => {
  const classes = useStyles();

  const { userObject } = props;
  const { t } = useTranslation();

  return (
    <Page className={classes.root} title={t("Account")}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography component="h2" variant="h2">
              {t("Edit Account")}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper
              className={classes.paper}
              style={{ color: colors.grey[700] }}
            >
              <Avatar
                className={classes.avatar}
                src={userObject?.avatar ?? ""}
              />
              <Typography component="h1" variant="h5">
                {getUserFullName(userObject)}
              </Typography>
              <Typography variant="subtitle1">{userObject?.email}</Typography>
              <Typography
                style={{ color: colors.grey[500], textAlign: "center" }}
              >
                {userObject?.position && (
                  <>
                    <BusinessCenterIcon /> {userObject?.position} <br />
                  </>
                )}
                {userObject?.department && userObject.company && (
                  <>
                    <BusinessIcon />
                    {`${userObject?.department} - ${userObject.company}`}
                  </>
                )}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={8}>
            <EditAccount userObj={userObject} />
          </Grid>
          {userHasPermission(userObject?.activePermissions, "organization") && (
            <ManagementSection userObj={userObject} />
          )}
        </Grid>
      </Container>
    </Page>
  );
};

export const AccountPage = connect(mapStateToProps)(ConnectedAccountPage);

export { EditUser, EditTeam, EditDepartment };
