/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  CircularProgress,
  colors,
  Grid,
  IconButton,
  Menu,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  Chip,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { Rating, ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import {
  Sort as SortIcon,
  FormatListBulleted as FormatListBulletedIcon,
  Help as HelpIcon,
  Save as SaveIcon,
  Search as SearchIcon,
  Info as InfoIcon,
  DragIndicator as DragIndicatorIcon,
  TableChart as TableChartIcon,
} from "@material-ui/icons";
import { List } from "react-virtualized";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import Papa from "papaparse";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FiltersetDialog, SaveFiltersetDialog } from "./filterset";
import { loadUser } from "src/redux/actions";
import { apiLoadSchemaColumnUniqueValues } from "src/api";
import safeLogger from "src/services/safeLogger";
import { defaultFiltersetsConf } from "src/config";
import { useSnackbar } from "notistack";
import { tableConfigStyle } from "./tableConfigStyle";
import { getTranslated } from "../../reports/utils/helpers";

const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref
) {
  const { children, role, ...other } = props;
  const itemCount = Array.isArray(children) ? children.length : 0;
  const itemSize = 45;

  const [parentDimension, setParentDimension] = useState(null);
  const myRef = React.useRef(ref);

  React.useLayoutEffect(() => {
    const parentWidth = myRef?.current?.offsetWidth;
    const parentHeight = myRef?.current?.offsetHeight;
    setParentDimension({ width: parentWidth, height: parentHeight });
  }, [ref]);

  return (
    <div ref={myRef}>
      <div {...other}>
        <List
          height={itemCount <= 10 ? 100 : 300}
          width={parentDimension ? parentDimension.width : 300}
          rowHeight={itemSize}
          overscanCount={5}
          rowCount={itemCount}
          rowRenderer={(props) => {
            return React.cloneElement(children[props.index], {
              style: props.style,
            });
          }}
          role={role}
        />
      </div>
    </div>
  );
});

const HelpBox = (props) => {
  const classes = tableConfigStyle();
  const { title, helpDesc } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <Menu
        className={classes.menu}
        id="help-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Card className={classes.root} variant="outlined">
          <CardContent>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              {title}
            </Typography>
            <Typography variant="body2" component="p">
              {helpDesc}
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="small" onClick={() => setAnchorEl(null)}>
              {t("Close")}
            </Button>
          </CardActions>
        </Card>
      </Menu>
      <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
        <HelpIcon />
      </IconButton>
    </div>
  );
};

export const NumericField = (props) => {
  const classes = tableConfigStyle();
  const {
    colIndex,
    colObject,
    colSetter,
    filterValue,
    setFilterValue,
    activeFilterSetName,
    setActiveFilterSetName,
  } = props;

  const error = useState({
    minFilterValue: false,
    maxFilterValue: false,
  });

  const { t } = useTranslation();

  // useEffect(() => {
  //   let mounted = true;

  //   if (!defaultRange) {
  //     apiLoadSchemaColumnUniqueValues(schemaId, name, (data, status) => {
  //       if (status === 200) {
  //         if (mounted) {
  //           setDefaultRange(data);
  //           if (
  //             !colObject[colIndex].filterRange ||
  //             !colObject[colIndex].filterRange.length
  //           ) {
  //             colObject[colIndex].filterRange = [];
  //             colSetter(colObject);
  //           } else {
  //             setFilterValue({
  //               minFilterValue:
  //                 colObject[colIndex].filterRange[0] !== data[0]
  //                   ? colObject[colIndex].filterRange[0]
  //                   : "",
  //               maxFilterValue:
  //                 colObject[colIndex].filterRange[1] !== data[1]
  //                   ? colObject[colIndex].filterRange[1]
  //                   : "",
  //             });
  //           }
  //         }
  //       }
  //     });
  //   }

  //   return () => {
  //     mounted = false;
  //   };
  // }, []);

  useEffect(() => {
    setFilterValue({
      minFilterValue:
        colObject[colIndex]?.filterRange && colObject[colIndex]?.filterRange[0]
          ? colObject[colIndex]?.filterRange[0]
          : "",
      maxFilterValue:
        colObject[colIndex]?.filterRange && colObject[colIndex]?.filterRange[1]
          ? colObject[colIndex]?.filterRange[1]
          : "",
    });
  }, []);

  useEffect(() => {
    if (filterValue) {
      const mnV = parseFloat(filterValue.minFilterValue);
      const mxV = parseFloat(filterValue.maxFilterValue);
      if (
        colObject[colIndex]?.filterRange &&
        colObject[colIndex]?.filterRange.length
      ) {
        if (isNaN(mnV) && colObject[colIndex]?.filterRange[0] !== "") {
          if (colObject[colIndex]?.filterRange[1] === "") {
            colObject[colIndex].filterRange = [];
            delete colObject[colIndex]["activeFilterCount"];
          } else {
            colObject[colIndex].filterRange[0] = "";
          }
        }
        if (isNaN(mxV) && colObject[colIndex]?.filterRange[1] !== "") {
          if (colObject[colIndex]?.filterRange[0] === "") {
            colObject[colIndex].filterRange = [];
            delete colObject[colIndex]["activeFilterCount"];
          } else {
            colObject[colIndex].filterRange[1] = "";
          }
        }
        if (!isNaN(mnV) && mnV !== colObject[colIndex]?.filterRange[0]) {
          colObject[colIndex].filterRange[0] = mnV;
          if (!colObject[colIndex]["activeFilterCount"]) {
            colObject[colIndex]["activeFilterCount"] = 1;
          }
        }
        if (!isNaN(mxV) && mxV !== colObject[colIndex]?.filterRange[1]) {
          colObject[colIndex].filterRange[1] = mxV;
          if (!colObject[colIndex]["activeFilterCount"]) {
            colObject[colIndex]["activeFilterCount"] = 1;
          }
        }
      }
      colSetter(colObject);
    }
  }, [filterValue]);

  const changeHandler = (event) => {
    if (activeFilterSetName) {
      setActiveFilterSetName(undefined);
      delete colObject[colIndex].filtersetName;
      colSetter(colObject);
    }
    const re = new RegExp(/^[-]?(\d+\.?\d*|\.\d+)$/);
    const name = event.target.name;
    const value = event.target.value;
    if (value === "" || re.test(value)) {
      // setError({ ...error, [name]: false });
      setFilterValue({ ...filterValue, [name]: value });
    }
    // else {
    //   setError({ ...error, [name]: true });
    // }
  };

  if (filterValue === undefined) {
    return <CircularProgress />;
  } else {
    return (
      <Box display="flex">
        <TextField
          size="small"
          error={error["minFilterValue"]}
          onChange={changeHandler}
          name="minFilterValue"
          label={t("Minimum")}
          variant="outlined"
          value={filterValue["minFilterValue"]}
          style={{ marginRight: "20px" }}
          helperText={t("Only numeric values are accepted.")}
        />
        <TextField
          size="small"
          error={error["maxFilterValue"]}
          onChange={changeHandler}
          name="maxFilterValue"
          label={t("Maximum")}
          variant="outlined"
          value={filterValue["maxFilterValue"]}
          helperText={t("Only numeric values are accepted.")}
        />
        {activeFilterSetName && (
          <Tooltip title={`${t("Active filterset")}: ${activeFilterSetName}`}>
            <InfoIcon className={classes.numericActiveFiltersetIcon} />
          </Tooltip>
        )}
      </Box>
    );
  }
};

const SortingButton = (props) => {
  const { columns, colIndex, colSetter } = props;
  const [sorting, setSorting] = useState(
    columns[colIndex].sorting ? columns[colIndex].sorting : "default"
  );
  const { t } = useTranslation();

  const handleSortingChange = (event, newSortValue) => {
    if (newSortValue !== null) {
      setSorting(newSortValue);
      if (newSortValue !== "default") {
        columns[colIndex].sorting = newSortValue;
        colSetter(columns);
      } else if (columns[colIndex].sorting !== "default") {
        delete columns[colIndex].sorting;
        colSetter(columns);
      }
    }
  };

  return (
    <ToggleButtonGroup
      size="small"
      value={sorting}
      exclusive
      onChange={handleSortingChange}
    >
      <ToggleButton value="ASC">
        <Tooltip title={t("Ascending")} placement="top">
          <SortIcon fontSize="small" style={{ transform: "rotate(-180deg)" }} />
        </Tooltip>
      </ToggleButton>
      <ToggleButton value="default">
        <Tooltip title={t("Default sorting")} placement="top">
          <FormatListBulletedIcon fontSize="small" />
        </Tooltip>
      </ToggleButton>
      <ToggleButton value="DESC">
        <Tooltip title={t("Descending")} placement="top">
          <SortIcon fontSize="small" />
        </Tooltip>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

const TableCell = (props) => {
  const { children, className, lockDimensions, ...rest } = props;
  // const [locked, setLocked] = useState(false);
  const ref = useRef();

  useLayoutEffect(() => {
    if (ref) {
      // if (lockDimensions) {
      //   setLocked(true);
      //   ref.current.style.width = `${ref.current.clientWidth}px`;
      // } else if (locked) {
      //   setLocked(false);
      //   ref.current.style.width("auto");
      // }
    }
  }, [lockDimensions]);

  return (
    <MuiTableCell ref={ref} className={className} {...rest}>
      {children}
    </MuiTableCell>
  );
};

const ColRow = (props) => {
  const classes = tableConfigStyle();
  const {
    activeDragging,
    appConf,
    userObject,
    schemaId,
    colIndex,
    columnOptions,
    setColumnOptions,
    visibleCols,
    defaultFiltersets,
  } = props;
  const [filterValue, setFilterValue] = useState(undefined);
  const [activeFilterSetName, setActiveFilterSetName] = useState(
    columnOptions[colIndex].filtersetName
      ? columnOptions[colIndex].filtersetName
      : undefined
  );
  const [filtersetDialogOpen, setFiltersetDialogOpen] = useState(false);
  const [saveFiltersetDialogOpen, setSaveFiltersetDialogOpen] = useState(false);
  const [lockDimensions, setLockDimensions] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (activeDragging === columnOptions[colIndex].name) {
      setLockDimensions(true);
    }
  }, [activeDragging]);

  const handleFiltersChange = (event, activeValues, reason, filtersetName) => {
    safeLogger("activeValues: ", activeValues, "reason: ", reason);
    if (columnOptions[colIndex]?.kind === "numeric") {
      if (reason === "filterset-apply") {
        columnOptions[colIndex].filtersetName = filtersetName;
        setActiveFilterSetName(filtersetName);
        setColumnOptions(columnOptions);
      }
      setFilterValue({
        minFilterValue: activeValues[0],
        maxFilterValue: activeValues[1],
      });
    } else if (columnOptions[colIndex]?.kind === "enumeration") {
      if (reason === "filterset-apply") {
        columnOptions[colIndex].filtersetName = filtersetName;
        setActiveFilterSetName(filtersetName);
      }
      if (reason === "select-option" && activeFilterSetName) {
        delete columnOptions[colIndex].filtersetName;
        setActiveFilterSetName(undefined);
        activeValues = [activeValues[1]];
      }
      if (reason === "remove-option" && activeFilterSetName) {
        delete columnOptions[colIndex].filtersetName;
        setActiveFilterSetName(undefined);
      }
      setFilterValue(activeValues);
      columnOptions[colIndex].filterValues = activeValues;
      columnOptions[colIndex]["activeFilterCount"] = activeValues.length;
      setColumnOptions(columnOptions);
    }
  };

  const isInMainCols = (col) => {
    return col?.representation?.kind === "main_table_cell";
  };

  const toggleMainColChecked = (col, checked) => {
    col.representation.kind = checked ? "main_table_cell" : "unspecified";
    setColumnOptions([...columnOptions]);
  };

  // function SliderComponent(props) {
  //   const { children, open, value } = props;

  //   return (
  //     <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
  //       {children}
  //     </Tooltip>
  //   );
  // }

  useEffect(() => {
    if (visibleCols) {
      if (visibleCols[colIndex]) {
        columnOptions[colIndex].representation.options.width =
          visibleCols[colIndex].representation.options.width;
      }
    }
  }, [visibleCols]);

  const label = columnOptions[colIndex].label;
  const description = columnOptions[colIndex].description;

  const handleAddToTab = () => {
    const isAddedTab = Boolean(columnOptions[colIndex]?.tab);

    columnOptions[colIndex].tab = !isAddedTab;

    setColumnOptions([...columnOptions]);
  };

  return (
    <Draggable draggableId={columnOptions[colIndex].name} index={colIndex}>
      {(provided) => (
        <TableRow
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={clsx(classes.tableRow, {
            [classes.mainTableRow]: isInMainCols(columnOptions[colIndex]),
          })}
        >
          <TableCell
            lockDimensions={lockDimensions}
            className={clsx(classes.draggable, classes.tableCell)}
            {...provided.dragHandleProps}
          >
            <DragIndicatorIcon />
          </TableCell>
          <TableCell
            lockDimensions={lockDimensions}
            className={clsx(classes.checkboxCell, classes.tableCell)}
          >
            <Checkbox
              checked={isInMainCols(columnOptions[colIndex])}
              onChange={(e) =>
                toggleMainColChecked(columnOptions[colIndex], e.target.checked)
              }
              disabled={
                columnOptions[colIndex].representation.options.isDisabled
                  ? columnOptions[colIndex].representation.options.isDisabled
                  : false
              }
            />
          </TableCell>
          <TableCell
            lockDimensions={lockDimensions}
            className={clsx(classes.tableCell)}
          >
            <Box display="flex" alignItems="center">
              <Typography>{getTranslated(label) ?? ""}</Typography>
              <HelpBox
                title={getTranslated(label) ?? ""}
                helpDesc={getTranslated(description)}
              />
            </Box>
          </TableCell>
          <TableCell
            lockDimensions={lockDimensions}
            className={clsx(classes.tableCell, classes.filterTableCell)}
          >
            {columnOptions[colIndex].kind === "numeric" ? (
              <NumericField
                appConf={appConf}
                schemaId={schemaId}
                label={getTranslated(label) ?? ""}
                name={columnOptions[colIndex].name}
                colIndex={colIndex}
                colObject={columnOptions}
                colSetter={setColumnOptions}
                filterValue={filterValue}
                setFilterValue={setFilterValue}
                activeFilterSetName={activeFilterSetName}
                setActiveFilterSetName={setActiveFilterSetName}
                userObject={userObject}
                loadUser={loadUser}
                defaultFiltersets={defaultFiltersets}
              />
            ) : (
              <AsyncFilterField
                appConf={appConf}
                schemaId={schemaId}
                label={getTranslated(label) ?? ""}
                name={columnOptions[colIndex].name}
                colIndex={colIndex}
                colObject={columnOptions}
                colSetter={setColumnOptions}
                filterValue={filterValue}
                setFilterValue={setFilterValue}
                activeFilterSetName={activeFilterSetName}
                setActiveFilterSetName={setActiveFilterSetName}
                userObject={userObject}
                loadUser={loadUser}
                defaultFiltersets={defaultFiltersets}
              />
            )}
          </TableCell>
          <TableCell
            lockDimensions={lockDimensions}
            className={classes.tableCell}
          >
            <SortingButton
              colIndex={colIndex}
              columns={columnOptions}
              colSetter={setColumnOptions}
            />
          </TableCell>
          {/* <TableCell
            lockDimensions={lockDimensions}
            className={classes.tableCell}
          >
            <Slider
              ValueLabelComponent={SliderComponent}
              aria-label="Slider"
              max={600}
              min={0}
              value={
                visibleCols
                  ? visibleCols[colIndex]
                    ? visibleCols[colIndex].representation.options.width
                    : columnOptions[colIndex].representation.options.width
                  : columnOptions[colIndex].representation.options.width
              }
              onChange={(e, val) => {
                columnOptions[colIndex].representation.options.width = val;
                if (visibleCols) {
                  if (visibleCols[colIndex]) {
                    visibleCols[colIndex].representation.options.width = val;
                    setVisibleCols([...visibleCols]);
                  }
                }
                setColumnOptions([...columnOptions]);
              }}
              defaultValue={0}
            />
          </TableCell> */}
          <TableCell
            lockDimensions={lockDimensions}
            className={classes.tableCell}
          >
            {Object.keys(defaultFiltersets).includes(
              columnOptions[colIndex].name
            ) && (
              <React.Fragment>
                <Tooltip title={t("Save Filterset")}>
                  <IconButton
                    // className={classes.filtersetButton}
                    onClick={() => setSaveFiltersetDialogOpen(true)}
                  >
                    <SaveIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t("Use Filtersets")}>
                  <IconButton
                    // className={classes.filtersetButton}
                    data-testid="saved-filters"
                    onClick={() => setFiltersetDialogOpen(true)}
                  >
                    <SearchIcon />
                  </IconButton>
                </Tooltip>
                <FiltersetDialog
                  kind={columnOptions[colIndex]?.kind}
                  appConf={appConf}
                  schemaId={schemaId}
                  colName={columnOptions[colIndex].name}
                  open={filtersetDialogOpen}
                  setOpen={setFiltersetDialogOpen}
                  handleFiltersChange={handleFiltersChange}
                  userObject={userObject}
                  loadUser={loadUser}
                  defaultFiltersets={
                    defaultFiltersets[columnOptions[colIndex].name]
                  }
                />
                <SaveFiltersetDialog
                  kind={columnOptions[colIndex]?.kind}
                  colName={columnOptions[colIndex].name}
                  open={saveFiltersetDialogOpen}
                  setOpen={setSaveFiltersetDialogOpen}
                  values={filterValue}
                  userObject={userObject}
                  loadUser={loadUser}
                />
              </React.Fragment>
            )}
          </TableCell>
          <TableCell>
            <Tooltip title={t("Side View")}>
              <Checkbox
                onClick={handleAddToTab}
                checked={columnOptions[colIndex]?.tab}
              />
            </Tooltip>
          </TableCell>
        </TableRow>
      )}
    </Draggable>
  );
};

const ColumnsList = React.memo(function ColumnsList({
  activeDragging,
  columnOptions,
  appConf,
  userObject,
  schemaId,
  visibleCols,
  setVisibleCols,
  setColumnOptions,
}) {
  return columnOptions.map((column, index) => (
    <ColRow
      activeDragging={activeDragging}
      key={columnOptions[index].name}
      appConf={appConf}
      userObject={userObject}
      schemaId={schemaId}
      visibleCols={visibleCols}
      setVisibleCols={setVisibleCols}
      colIndex={index}
      columnOptions={columnOptions}
      setColumnOptions={setColumnOptions}
      defaultFiltersets={defaultFiltersetsConf}
    />
  ));
});

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const TableLayoutEditor = (props) => {
  const classes = tableConfigStyle();
  const {
    schemaId,
    columnOptions,
    setColumnOptions,
    visibleCols,
    setVisibleCols,
    userObject,
    appConf,
  } = props;

  const [activeDragging, setActiveDragging] = useState(undefined);

  const defaultFiltersets = defaultFiltersetsConf;
  const { t } = useTranslation();

  function onBeforeDragStart(params) {
    setActiveDragging(params.draggableId);
  }

  function onDragEnd(result) {
    setActiveDragging(undefined);
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const cols = reorder(
      columnOptions,
      result.source.index,
      result.destination.index
    );

    setColumnOptions(cols);
  }

  if (!Object.keys(defaultFiltersets).length || !columnOptions) {
    return <CircularProgress />;
  } else {
    return (
      <div style={{ marginTop: "30px" }}>
        <Grid container spacing={5}>
          {/* <Grid item xs={12}>
            <Button onClick={() => setOpen((prev) => !prev)}>
              {t("Save")}
            </Button>
          </Grid> */}
          <Grid item xs={12} sm={12}>
            <TableContainer>
              <Table size="small">
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell
                      style={{ width: "40px" }}
                      className={classes.tableCell}
                    ></TableCell>
                    <TableCell
                      className={classes.tableCell}
                      style={{
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        color: colors.grey[600],
                      }}
                    >
                      <Tooltip title={t("Include in table")}>
                        <TableChartIcon style={{ verticalAlign: "bottom" }} />
                      </Tooltip>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {t("Name")}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {t("Filters")}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {t("Sorting")}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {t("Actions")}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {t("Side View")}
                    </TableCell>
                  </TableRow>
                </TableHead>

                <DragDropContext
                  onBeforeDragStart={onBeforeDragStart}
                  onDragEnd={onDragEnd}
                >
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <TableBody
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        <ColumnsList
                          activeDragging={activeDragging}
                          appConf={appConf}
                          visibleCols={visibleCols}
                          setVisibleCols={setVisibleCols}
                          userObject={userObject}
                          schemaId={schemaId}
                          columnOptions={columnOptions}
                          setColumnOptions={setColumnOptions}
                        />
                        {provided.placeholder}
                      </TableBody>
                    )}
                  </Droppable>
                </DragDropContext>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </div>
    );
  }
};

export const AsyncFilterField = (props) => {
  const classes = tableConfigStyle();
  const {
    schemaId,
    label,
    name,
    colIndex,
    colObject,
    colSetter,
    filterValue,
    setFilterValue,
    activeFilterSetName,
    setActiveFilterSetName,
  } = props;

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  useEffect(() => {
    if (filterValue === undefined) {
      setFilterValue(
        colObject[colIndex].filtersetName
          ? colObject[colIndex].filtersetName
          : colObject[colIndex].filterValues
          ? colObject[colIndex].filterValues
          : []
      );
    }
  }, [colObject]);

  useEffect(() => {
    let mounted = true;
    if (open && !options.length) {
      setLoading(true);

      apiLoadSchemaColumnUniqueValues(schemaId, name, (data, status) => {
        if (status === 200) {
          if (mounted) {
            if (!data.length) {
              enqueueSnackbar(t("No data were found for this column."), {
                variant: "error",
              });
              setOptions([]);
            } else {
              setOptions(data);
            }
            setLoading(false);
          }
        }
      });
    }

    return () => {
      mounted = false;
    };
  }, [open]);

  const handleFiltersChange = (event, activeValues, reason, filtersetName) => {
    safeLogger("activeValues: ", activeValues, "reason: ", reason);
    if (reason === "filterset-apply") {
      colObject[colIndex].filtersetName = filtersetName;
      setActiveFilterSetName(filtersetName);
    }
    if (reason === "select-option" && activeFilterSetName) {
      delete colObject[colIndex].filtersetName;
      setActiveFilterSetName(undefined);
      activeValues = [activeValues[1]];
    }
    if (reason === "remove-option" && activeFilterSetName) {
      delete colObject[colIndex].filtersetName;
      setActiveFilterSetName(undefined);
    }
    if (reason === "paste") {
    }
    setFilterValue(activeValues);
    colObject[colIndex].filterValues = activeValues;
    colObject[colIndex]["activeFilterCount"] = activeValues.length;
    colSetter(colObject);
  };

  const pasteHandler = (event) => {
    event.preventDefault();
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData("Text");
    if (pastedData) {
      let parsedData = Papa.parse(pastedData).data;
      parsedData = parsedData.filter((value) => !value.includes(""));
      parsedData = parsedData
        .flat()
        .map((value) => value.replace(new RegExp("\\s"), ""));
      const newData = [...filterValue, ...parsedData];
      setFilterValue(newData);
      colObject[colIndex].filterValues = newData;
      colObject[colIndex]["activeFilterCount"] = newData.length;
      colSetter(colObject);
    }
  };
  if (filterValue === undefined) {
    return <CircularProgress />;
  } else {
    return (
      <Autocomplete
        size="small"
        onKeyDown={(event) => {
          if (event.keyCode === 13) {
            event.preventDefault();
            event.stopPropagation();
          }
        }}
        autoComplete
        autoHighlight
        freeSolo
        fullWidth
        id={`asynchronous-${name}-filter`}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        multiple
        filterSelectedOptions
        disableCloseOnSelect
        ListboxComponent={ListboxComponent}
        getOptionSelected={(option, value) => option === value}
        getOptionLabel={(option) => {
          if (
            colObject[colIndex].representation.options &&
            colObject[colIndex].representation.options.component_choices &&
            colObject[colIndex].representation.options.component_choices
              .constructor === Object
          ) {
            return (
              colObject[colIndex].representation.options.component_choices[
                option
              ] ?? option
            );
          }
          return option;
        }}
        options={options}
        loading={loading}
        value={activeFilterSetName ? [activeFilterSetName] : filterValue}
        // filterOptions={customFilterOptions}
        onChange={handleFiltersChange}
        filterOptions={(options) => {
          if (
            colObject[colIndex].representation.options.component ===
            "star-rating"
          ) {
            return options.filter(
              (option) =>
                parseFloat(option) >= -1 &&
                parseFloat(option) <= 4 &&
                !option.includes("|")
            );
          }
          return options.filter((option) => option !== "");
        }}
        renderOption={(option, { inputValue }) => {
          let altOption = option;
          if (
            colObject[colIndex].representation.options &&
            colObject[colIndex].representation.options.component_choices &&
            colObject[colIndex].representation.options.component_choices
              .constructor === Object
          ) {
            altOption =
              colObject[colIndex].representation.options.component_choices[
                option
              ];
          }

          const matches = match(altOption ?? option, inputValue);
          const parts = parse(altOption ?? option, matches);

          if (
            colObject[colIndex].representation.options.component ===
            "star-rating"
          ) {
            return (
              <div
                style={{
                  padding: "2px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  marginRight: "5px",
                  width: "100px",
                  height: "30px",
                }}
              >
                <Rating
                  className={classes.starRating}
                  name="revstat"
                  readOnly
                  max={4}
                  value={parseFloat(option)}
                  precision={0.1}
                  style={{
                    display: parseFloat(option) <= -1 && "none",
                  }}
                />
              </div>
            );
          }

          return (
            <div>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              ))}
            </div>
          );
        }}
        renderTags={(value, getTagProps) => {
          if (
            colObject[colIndex].representation.options.component ===
            "star-rating"
          ) {
            return value.map((option, index) => (
              <div
                style={{
                  padding: "2px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  marginRight: "5px",
                  width: "100px",
                  height: "30px",
                }}
              >
                <Rating
                  className={classes.starRating}
                  name="revstat"
                  readOnly
                  max={4}
                  value={parseFloat(option)}
                  precision={0.1}
                  style={{
                    display: parseFloat(option) <= -1 && "none",
                  }}
                />
              </div>
            ));
          } else
            return value.map((val, i) => (
              <Chip
                key={i}
                variant="outlined"
                color="primary"
                label={
                  colObject[colIndex] &&
                  colObject[colIndex]?.representation &&
                  colObject[colIndex]?.representation?.options &&
                  colObject[colIndex]?.representation?.options
                    ?.component_choices &&
                  colObject[colIndex]?.representation?.options
                    ?.component_choices[val]
                    ? colObject[colIndex]?.representation?.options
                        ?.component_choices[val]
                    : val
                }
                {...getTagProps(i)}
              />
            ));
        }}
        renderInput={(params) => {
          return (
            <TextField
              className={classes.autoCompleteInput}
              onPaste={pasteHandler}
              {...params}
              label={label}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          );
        }}
      />
    );
  }
};
