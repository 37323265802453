import React, { useState } from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { Save as SaveIcon } from "@material-ui/icons";
import { apiUpdateProfile } from "src/api";
import { useTranslation } from "react-i18next";
import safeLogger from "src/services/safeLogger";
import { reportSectionsConf } from "src/config";

const useStyles = makeStyles((theme) => ({
  section: {
    paddingTop: "20px",
  },
}));

export const DefaultReportTabSection = (props) => {
  const { userObject, loadUser, projectTypeConf } = props;
  const [userConf, setUserConf] = useState(
    userObject.settings.report[projectTypeConf.name].defaultMainTab
      ? userObject.settings.report[projectTypeConf.name].defaultMainTab
      : "statistics"
  );
  const classes = useStyles();

  const { t } = useTranslation();

  const updateProfileCallback = (data, status) => {
    if (status === 200) {
      loadUser(data);
    } else {
      safeLogger(status, data);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    userObject.settings.report[projectTypeConf.name]["defaultMainTab"] =
      userConf;
    apiUpdateProfile({ settings: userObject.settings }, updateProfileCallback);
  };

  const handleChange = (event) => {
    setUserConf(event.target.value);
  };

  return (
    <div className={classes.section} data-testid="report-tab-section">
      <form className={classes.form} noValidate onSubmit={handleSubmit}>
        <FormControl component="fieldset" style={{ display: "flex" }}>
          <RadioGroup
            aria-label="default report tab"
            name="defaultReportTab"
            data-testid="defaultReportTab"
            value={userConf}
            onChange={handleChange}
          >
            {Object.keys(reportSectionsConf[projectTypeConf.name].tabs).map(
              (item, index) => (
                <FormControlLabel
                  key={index}
                  value={item}
                  data-testid={"default-report-tab"}
                  control={<Radio />}
                  label={t(
                    reportSectionsConf[projectTypeConf.name].tabs[item].label
                  )}
                />
              )
            )}
          </RadioGroup>
        </FormControl>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.submit}
          startIcon={<SaveIcon />}
          style={{ marginTop: "10px" }}
        >
          {t("Save")}
        </Button>
      </form>
    </div>
  );
};
