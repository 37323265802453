/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Page from "src/components/Page";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Link, useSearchParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { apiListProjects } from "src/api";
import {
  Typography,
  Button,
  CircularProgress,
  Grid,
  Container,
  Box,
  TextField,
} from "@material-ui/core";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { userHasPermission } from "src/components/utils";
import safeLogger from "src/services/safeLogger";
import { VirtualProjectsTable } from "./virtualProjectsTable";
import Search from "../tableExtensions/search";
import { pageSizes } from "../reports/utils/helpers";
import Pagination from "src/components/pagination";
import ProjectTable from "./table";

const useStyles = makeStyles((theme) => ({
  spinner: {
    position: "absolute",
    top: "48%",
    left: "48%",
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(8),
    paddingTop: theme.spacing(8),
  },
  pageTitle: {
    // color: grey[800],
    color: theme.palette.text.primary,
  },
  section: {
    "& .section-title": {
      display: "flex",
      borderRadius: "5px 5px 0 0",
    },
    "&.active": {
      "& .project-icon": {
        color: theme.palette.warning.light,
      },
      "& .section-title": {
        color: theme.palette.background.paper,
        backgroundColor: theme.palette.warning.dark,
      },
    },
    "&.completed": {
      "& .project-icon": {
        color: theme.palette.success.light,
      },
      "& .section-title": {
        color: theme.palette.background.paper,
        backgroundColor: theme.palette.success.dark,
      },
    },
    "& .MuiTablePagination-root": {
      overflow: "initial",
    },
  },
  createButton: {
    backgroundColor: theme.palette.warning.light,
    "&:hover": {
      backgroundColor: theme.palette.warning.main,
    },
    "&:active": {
      backgroundColor: theme.palette.warning.dark,
    },
  },
  toggleActive: {
    color: "#FFF",
    transition: "color 0.3s",
  },
}));

const mapStateToPropsPage = (state) => {
  return {
    userObj: state.userObject,
    appConf: state.appConf,
  };
};

const ConnectedProjectsPage = (props) => {
  const { userObj, appConf } = props;
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [loadingTable, setLoadingTable] = useState(true);
  const [projectDataCount, setProjectDataCount] = useState(0);
  const [tmpProjectData, setTmpProjectData] = useState();
  const [projectsData, setProjectsData] = useState([]);
  let [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get("value");
  const size = searchParams.get("pageSize");
  const pageNumber = searchParams.get("page");
  const [page, setPage] = useState(pageNumber);
  const sort = searchParams.get("sort");
  const [tableSort, setTableSort] = useState(JSON.parse(sort));
  const filterValue = JSON.parse(searchParams.get("filterValue"));
  const [tableFilters, setTableFilters] = useState(filterValue);
  const [pageSize, setPageSize] = useState(size ?? 10);
  const [searchValue, setSearchValue] = useState(search ? search : "");
  const { t } = useTranslation();

  useEffect(() => {
    let mounted = true;
    setLoadingTable(true);
    apiListProjects(
      userObj.activeOrganization.id,
      page,
      (data, status) => {
        if (status === 200) {
          if (mounted) {
            setProjectsData(data.results);
            setTmpProjectData(data.results);
            setProjectDataCount(data.count);
            setLoading(false);
            setLoadingTable(false);
          }
        } else {
          safeLogger(status);
          safeLogger(data);
        }
      },
      pageSize,
      tableSort,
      tableFilters,
      searchValue
    );
    return () => {
      mounted = false;
      return mounted;
    };
  }, [page, pageSize, tableSort, tableFilters, searchValue]);

  useEffect(() => {
    let mounted = true;
    if (page || pageSize || tableSort || tableFilters) {
      setSearchParams((prev) => ({
        ...prev,
        page: page,
        pageSize: pageSize,
        value: search,
        sort: JSON.stringify(tableSort),
        filterValue: JSON.stringify(tableFilters),
      }));
    }
    return () => {
      mounted = false;
      return mounted;
    };
  }, [page, pageSize, tableSort, tableFilters, searchValue]);

  if (loading) {
    return <CircularProgress className={classes.spinner} />;
  } else {
    return (
      <Page className={classes.root} title={t("Projects")}>
        <Container maxWidth="xl">
          <Grid container spacing={4}>
            <Grid item xs={12} style={{ display: "flex" }}>
              <Box>
                <Typography
                  component="h3"
                  variant="h3"
                  className={classes.pageTitle}
                >
                  {t("Projects")}
                </Typography>
                <Typography className={classes.pageTitle}>
                  {t(
                    "projects_subheading",
                    `Total projects: {{totalProject}}`,
                    {
                      totalProject: projectDataCount,
                    }
                  )}
                </Typography>
              </Box>
              <Link to="/projects/create" style={{ marginLeft: "auto" }}>
                {userHasPermission(
                  userObj.activePermissions,
                  "create_project"
                ) && (
                  <Button
                    variant="contained"
                    size="large"
                    className={classes.createButton}
                    startIcon={<AddCircleIcon />}
                  >
                    {t("New Project")}
                  </Button>
                )}
              </Link>
            </Grid>
            <Search
              data={tmpProjectData}
              setData={setProjectsData}
              setTmpData={setTmpProjectData}
              search={search}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              setSearchParams={setSearchParams}
              searchParams={searchParams}
              url={true}
              type={"projects"}
            />
            {!loading && !projectsData.length ? (
              <Box
                height="60vh"
                width="100%"
                justifyContent="center"
                alignItems="center"
                display="flex"
                flexDirection="column"
              >
                <img
                  alt=""
                  src="/assets/images/errors/no-project.svg"
                  style={{ maxWidth: "400px" }}
                />
                <Typography>
                  {t("You don't have any projects yet...")}
                </Typography>
              </Box>
            ) : (
              <Grid item xs={12}>
                <Grid item xs={12}>
                  {loadingTable ? (
                    <Box height={530}>
                      <CircularProgress className={classes.spinner} />
                    </Box>
                  ) : (
                    <ProjectTable
                      appConf={appConf}
                      userObj={userObj}
                      tableSort={tableSort}
                      setSortState={setTableSort}
                      tableFilters={tableFilters}
                      setTableFilters={setTableFilters}
                      projectsData={projectsData}
                    />
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
          <Pagination
            page={page}
            pageSize={pageSize}
            projectDataCount={projectDataCount}
            setLoading={setLoadingTable}
            setPage={setPage}
            setPageSize={setPageSize}
          />
        </Container>
      </Page>
    );
  }
};

export const ProjectsPage = connect(mapStateToPropsPage)(ConnectedProjectsPage);
