/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import clsx from "clsx";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  colors,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  LinearProgress,
  makeStyles,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import { TreeItem, TreeView } from "@material-ui/lab";
import {
  MinusSquare as MinusSquareIcon,
  PlusSquare as PlusSquareIcon,
} from "react-feather";
import {
  Cancel as CancelIcon,
  ExpandMore as ExpandMoreIcon,
  Folder as FolderIcon,
  InsertDriveFile as InsertDriveFileIcon,
  Usb as UsbIcon,
  Warning as WarningIcon,
} from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { alpha } from "@material-ui/core/styles";
import {
  apiAttachStorageSubmit,
  apiGetAttachStorageProgress,
  apiGetAttachedStorageContent,
  apiListAttachedStorage,
  apiCancelProject,
} from "src/api";
import { useTranslation } from "react-i18next";
import { useInterval } from "src/components/utils";
import safeLogger from "src/services/safeLogger";

const useStyles = makeStyles((theme) => ({
  spinner: {
    position: "absolute",
    top: "48%",
    left: "48%",
  },
  root: {
    minHeight: "240px",
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
    position: "relative",
  },
  displayOverlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    margin: "-24px auto auto -24px",
    backgroundColor: colors.grey[500],
    opacity: 0.5,
    zIndex: 100,
  },
}));

const getReadableFileSize = (bytes, si = false, dp = 1) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si
    ? ["KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + " " + units[u];
};

const useTreeItemStyles = makeStyles((theme) => ({
  iconContainer: {
    "& .close": {
      opacity: 0.3,
    },
  },
  group: {
    marginLeft: 7,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
  label: {
    fontWeight: "inherit",
    color: "inherit",
  },
  labelRoot: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  folderIcon: {
    fill: theme.palette.warning.light,
  },
  fileIcon: {
    fill: colors.lightBlue[500],
  },
  warningIcon: {
    fill: theme.palette.warning.light,
  },
  disabledNode: {
    "& path": {
      fill: colors.grey[300],
    },
  },
  labelText: {
    marginLeft: theme.spacing(1),
    fontWeight: "inherit",
    flexGrow: 1,
  },
  checkbox: {
    "& input": {
      minWidth: "auto !important",
    },
  },
}));

const IconLeftAccordionSummary = withStyles({
  expandIcon: {
    order: -1,
  },
})(AccordionSummary);

// const SingleFileProgressBar = (props) => {
//   const { file } = props;
//   const [progress, setProgress] = useState(file.progress * 100);
//   const [progressColor, setProgressColor] = useState("secondary");

//   file.flowObj.on("progress", function () {
//     setProgress(file.progress() * 100);
//   });

//   useEffect(() => {
//     if (progress === 100) {
//       setProgressColor("primary");
//     }
//   }, [progress]);

//   return (
//     <Box display="flex" alignItems="center" mt={2} mb={2}>
//       {file.name}
//       <Box width="100%" ml={2} mr={1}>
//         <LinearProgress
//           variant="determinate"
//           color={progressColor}
//           value={progress}
//         />
//       </Box>
//       <Box minWidth={35} mr={1}>
//         <Typography variant="body2" color="textSecondary">{`${Math.round(
//           progress
//         )}%`}</Typography>
//       </Box>
//     </Box>
//   );
// };

const StyledFolderIcon = (props) => {
  const { extraClasses } = props;
  const classes = useTreeItemStyles();
  return <FolderIcon className={clsx(classes.folderIcon, extraClasses)} />;
};

const StyledFileIcon = (props) => {
  const classes = useTreeItemStyles();
  return <InsertDriveFileIcon className={classes.fileIcon} />;
};

const StyledTreeItem = (props) => {
  const classes = useTreeItemStyles();
  const {
    setFiles,
    setRemoteFiles,
    fileActionType,
    setFileActionType,
    getSelectedFiles,
    checkChildrenStatus,
    checkParentStatus,
    rootContent,
    setRootContent,
    // selectedFiles,
    // setSelectedFiles,
    expanded,
    setExpanded,
    node,
    ...other
  } = props;
  const { t } = useTranslation();

  //   const isSelected = (id) => {
  //     // safeLogger(id, selectedFiles, node.selected);
  //     return Boolean(node.selected); //selectedFiles.includes(id) ||
  //   };

  const handleSelectFile = (event) => {
    if (fileActionType !== "attach") {
      setFiles([]);
      setRemoteFiles([]);
      setFileActionType("attach");
    }
    if (!node.selected) {
      node["selected"] = true;
    } else {
      node["selected"] = false;
      if (node.indeterminate) {
        node.indeterminate = false;
      }
    }

    checkParentStatus(node.path.slice(0, node.path.lastIndexOf("/")));

    checkChildrenStatus(node);

    setRootContent(JSON.parse(JSON.stringify(rootContent)));
    const newSelectedFiles = getSelectedFiles(rootContent);
    setRemoteFiles(newSelectedFiles);
    if (!newSelectedFiles.length) {
      setFiles([]);
    }
  };

  const getAttachedStorageContentCallback = (data, status) => {
    if (status === 200) {
      // safeLogger(data);
      const tmpChildren = {};
      if (data.length) {
        for (let i = 0; i < data.length; i++) {
          tmpChildren[data[i].name] = {
            ...data[i],
            label: data[i].name,
            selected: false,
          };
        }
      }
      node["children"] = tmpChildren;
      setRootContent(JSON.parse(JSON.stringify(rootContent)));
      if (!expanded.includes(node.path)) {
        setExpanded([...expanded, node.path]);
      }
      if (node.selected) {
        checkChildrenStatus(node);
      }
    } else {
      safeLogger(status);
      safeLogger(data);
    }
  };

  const handleToggle = (event) => {
    event.stopPropagation();
    if (node.type !== "dir") {
      return;
    }
    if (!node.children) {
      //  make api call
      apiGetAttachedStorageContent(
        { path: node.path },
        getAttachedStorageContentCallback
      );

      // const newChildren = JSON.parse(JSON.stringify(sampleChildren));
      // Object.keys(newChildren).map(
      //   (childKey) =>
      //     (newChildren[childKey]["path"] = node.path + node.name + "/")
      // );

      // node["children"] = newChildren;
      // setRootContent(JSON.parse(JSON.stringify(rootContent)));
      // if (!expanded.includes(node.path + node.name)) {
      //   setExpanded([...expanded, node.path + node.name]);
      // }
      // checkChildrenStatus(node);
    }
  };

  useEffect(() => {
    if (!node.selected && node.indeterminate) {
      node.indeterminate = false;
      setRootContent(JSON.parse(JSON.stringify(rootContent)));
    }
  }, [node.selected]);

  return (
    <TreeItem
      key={node.name}
      onClick={node.status === "unmountable" ? undefined : handleToggle}
      label={
        <div className={classes.labelRoot}>
          {node.status === "unmountable" ? (
            <Tooltip
              title={t(
                "unmountable_usb_tooltip_message",
                "The USB drive is not mountable. Please make sure the device is functional and it is ejected properly after files are written into it."
              )}
            >
              <WarningIcon className={classes.warningIcon} />
            </Tooltip>
          ) : (
            <Checkbox
              indeterminate={node.indeterminate}
              onClick={(event) => event.stopPropagation()}
              className={classes.checkbox}
              checked={node.selected}
              onChange={handleSelectFile}
            />
          )}
          {node.type === "dir" && (
            <StyledFolderIcon
              extraClasses={clsx(classes.labelIcon, {
                [classes.disabledNode]: node.status === "unmountable",
              })}
            />
          )}
          {node.type === "file" && (
            <StyledFileIcon className={classes.labelIcon} />
          )}
          <Typography variant="body2" className={classes.labelText}>
            {node.label}
          </Typography>
          {node.type === "file" && (
            <Typography variant="caption" color="inherit">
              {getReadableFileSize(node.size, true)}
            </Typography>
          )}
        </div>
      }
      {...other}
    />
  );
};

const AttachedStorage = (props) => {
  const {
    nextButtonLoading,
    setNextButtonLoading,
    files,
    setFiles,
    remoteFiles,
    setRemoteFiles,
    projectId,
    setProjectId,
    setPhase,
    fileActionType,
    setFileActionType,
    disabled,
  } = props; //TODO if atached storage is not in the userObj.privilege, disable, or navigate to forbidden page
  const classes = useStyles();
  const [expanded, setExpanded] = useState([]);
  const [rootContent, setRootContent] = useState({});
  const [isStorageAttached, setIsStorageAttached] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  // const [progressIntervalId, setProgressIntervalId] = useState(undefined);
  // const [stopInterval, setStopInterval] = useState(undefined);
  const [expandedProgress, setExpandedProgress] = useState(false);
  const [filesUploadProgress, setFilesUploadProgress] = useState(undefined);
  const [totalUploadProgress, setTotalUploadProgress] = useState(0);
  const [cancelDialog, setCancelDialog] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const getSelectedFiles = (node) => {
    // safeLogger(node);
    const selectedRemoteFiles = [];
    for (let item in node) {
      if (node[item].selected) {
        if (node[item].indeterminate) {
          selectedRemoteFiles.push(...getSelectedFiles(node[item].children));
        } else {
          selectedRemoteFiles.push(node[item].path);
        }
      }
    }
    safeLogger("getSelectedFiles: ", selectedRemoteFiles);
    return selectedRemoteFiles;
  };

  const checkChildrenStatus = (node) => {
    if (node) {
      if (node.children) {
        for (const key in node.children) {
          if (node.children[key].selected !== node.selected) {
            node.children[key].selected = node.selected;
          }
          checkChildrenStatus(node.children[key]);
        }
      }
      setRootContent(JSON.parse(JSON.stringify(rootContent)));
    }
  };

  const checkParentStatus = (path) => {
    if (!path || path === "/") {
      // one of the root nodes selected
      return;
    } else {
      let strippedPath = path.split("/");
      // strippedPath = strippedPath.slice(1, strippedPath.length - 1);
      strippedPath = strippedPath.slice(1);
      let targetDir = rootContent[strippedPath[0]];
      for (let i = 1; i < strippedPath.length; i++) {
        targetDir = targetDir.children[strippedPath[i]];
      }

      let parentSelected = false;
      let anyChildNotSelected = false;
      const childrenKeys = Object.keys(targetDir.children);
      for (let i = 0; i < childrenKeys.length; i++) {
        if (targetDir.children[childrenKeys[i]].selected && !parentSelected) {
          parentSelected = true;
        }
        if (
          (!targetDir.children[childrenKeys[i]].selected &&
            !anyChildNotSelected) ||
          targetDir.children[childrenKeys[i]].indeterminate
        ) {
          anyChildNotSelected = true;
        }
      }
      targetDir["selected"] = parentSelected;
      targetDir["indeterminate"] = parentSelected && anyChildNotSelected;
      checkParentStatus(
        targetDir.path.slice(0, targetDir.path.lastIndexOf("/"))
      );
    }
    setRootContent(JSON.parse(JSON.stringify(rootContent)));
  };

  const renderTree = (nodes) => {
    return (
      <StyledTreeItem
        setRemoteFiles={setRemoteFiles}
        setFiles={setFiles}
        fileActionType={fileActionType}
        setFileActionType={setFileActionType}
        getSelectedFiles={getSelectedFiles}
        checkChildrenStatus={checkChildrenStatus}
        checkParentStatus={checkParentStatus}
        rootContent={rootContent}
        setRootContent={setRootContent}
        expanded={expanded}
        setExpanded={setExpanded}
        node={nodes}
        key={nodes.path}
        nodeId={nodes.path}
      >
        {nodes.children && Boolean(Object.keys(nodes.children).length)
          ? Object.keys(nodes.children).map((nodeKey) => {
              return renderTree(nodes.children[nodeKey]);
            })
          : null}
      </StyledTreeItem>
    );
  };

  const handleToggle = (event, nodeIds) => {
    // safeLogger("nodeIds: ", nodeIds);
    setExpanded(nodeIds);
  };

  useEffect(() => {
    let mounted = true;
    apiListAttachedStorage((data, status) => {
      if (status === 200) {
        safeLogger(data);
        if (data.length) {
          if (mounted) {
            setIsStorageAttached(true);
          }
          const tmpRootContent = {};
          for (let i = 0; i < data.length; i++) {
            tmpRootContent[data[i].name] = {
              ...data[i],
              path: "/" + data[i].name,
              type: "dir",
              selected: false,
              status: data[i].status,
            };
          }
          if (mounted) {
            setRootContent(tmpRootContent);
          }
        }
      } else {
        safeLogger(status);
        safeLogger(data);
      }
    });

    return () => {
      mounted = false;
    };
  }, []);

  const updateUploadProgress = (data, status) => {
    if (status === 200) {
      // safeLogger("recurring interval: ", progressIntervalId);
      let totalProgress = 0;
      for (let filepath in data) {
        if (data[filepath].status === "failed") {
          setIsUploading(false);
          setProjectId(null);
          setTotalUploadProgress(0);
          setPhase("init");
          // setFilesUploadProgress(undefined);
          enqueueSnackbar(
            t(
              "attached_storage_file_failed",
              "An error occurred while transferring from the attached storage device, please try again later. If the problem persists, please contact support for further assistance."
            ),
            {
              variant: "error",
            }
          );
          break;
        } else {
          totalProgress += data[filepath].progress;
          filesUploadProgress[filepath] = data[filepath];
        }
      }
      totalProgress /= Object.keys(data).length;
      safeLogger("total progress: ", totalProgress);
      setTotalUploadProgress(totalProgress);
      setFilesUploadProgress({ ...filesUploadProgress });

      if (totalProgress === 1) {
        setIsUploading(false);
        setPhase("submit");
      }
    } else {
      safeLogger(status);
      safeLogger(data);
    }
  };

  const [isFirst, setIsFirst] = useState(true);

  useInterval(
    () => {
      if (isFirst) {
        setIsFirst(false);
      }
      apiGetAttachStorageProgress(projectId, updateUploadProgress);
    },
    isUploading ? (isFirst ? 10000 : 2000) : null
  );

  useEffect(() => {
    let mounted = true;
    if (projectId && fileActionType === "attach") {
      enqueueSnackbar(
        t(
          "attached_storage_project_created",
          "Project created. Submitting the files. Please wait..."
        ),
        {
          variant: "success",
        }
      );
      const submitFiles = [];
      for (let i = 0; i < files.length; i++) {
        submitFiles.push(files[i].path);
      }
      safeLogger(submitFiles);
      apiAttachStorageSubmit(
        projectId,
        { files: submitFiles },
        (data, status) => {
          if (status === 200) {
            const tmpFilesProgress = {};
            for (let i = 0; i < remoteFiles.length; i++) {
              tmpFilesProgress[remoteFiles[i]] = {
                path: remoteFiles[i],
                total_size: 0,
                uploaded: 0,
                progress: 0,
              };
            }
            if (mounted) {
              setFilesUploadProgress(tmpFilesProgress);
              setIsUploading(true);
            }
          } else {
            safeLogger(data, status);
          }
        }
      );
    }

    return () => {
      mounted = false;
    };
  }, [projectId]);

  useEffect(() => {
    let mounted = true;
    if (remoteFiles && remoteFiles.length && fileActionType === "attach") {
      setNextButtonLoading(true);
      setFiles([]);
      safeLogger("remoteFiles: ", remoteFiles);
      // api call to get file names
      apiGetAttachedStorageContent({ paths: remoteFiles }, (data, status) => {
        if (status === 200) {
          const tmpFiles = [];
          for (let i = 0; i < data.length; i++) {
            tmpFiles.push({
              name: data[i].slice(data[i].lastIndexOf("/") + 1),
              path: data[i],
            });
          }
          if (mounted) {
            setFiles(tmpFiles);
          }
        } else {
          safeLogger(status);
          safeLogger(data);
        }
      });
    }

    return () => {
      mounted = false;
    };
  }, [remoteFiles]);

  useEffect(() => {
    if (files.length && fileActionType === "attach") {
      if (nextButtonLoading) {
        setNextButtonLoading(false);
      }
    }
  }, [files]);

  const uploadCancelHandler = () => {
    if (isUploading) {
      apiCancelProject(projectId, null, (data, status) => {
        if (status === 200) {
          setIsUploading(false);
          setProjectId(null);
          setTotalUploadProgress(0);
          setPhase("init");
          enqueueSnackbar(
            t("file_upload_cancelled", "File uploads was cancelled!"),
            {
              variant: "warning",
            }
          );
        } else {
          safeLogger(data);
        }
      });
    }
    setCancelDialog(false);
  };

  const handleCancelDialogOpen = (event) => {
    event.stopPropagation();
    setCancelDialog(true);
  };

  if (!Boolean(Object.keys(rootContent).length)) {
    return <CircularProgress className={classes.spinner} />;
  } else {
    return (
      <React.Fragment>
        <Box className={classes.root}>
          {disabled && (
            <div
              className={classes.displayOverlay}
              onClick={(event) => event.stopPropagation()}
            ></div>
          )}
          {!isStorageAttached ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              p={3}
            >
              <UsbIcon
                style={{
                  fontSize: "6em",
                  fill: "rgba(0, 0, 0, 0.38)",
                  marginBottom: "20px",
                }}
              />
              <Typography
                variant="body1"
                style={{ color: "rgba(0, 0, 0, 0.38)" }}
              >
                {t("No attached storage device detected.")}
              </Typography>
              <Typography
                variant="subtitle1"
                style={{ color: "rgba(0, 0, 0, 0.38)" }}
              >
                {t(
                  "Make sure the storage dvice is attached and refresh the page."
                )}
              </Typography>
            </Box>
          ) : (
            <React.Fragment>
              <TreeView
                expanded={expanded}
                defaultCollapseIcon={<MinusSquareIcon />}
                defaultExpandIcon={<PlusSquareIcon />}
                onNodeToggle={handleToggle}
              >
                {Boolean(Object.keys(rootContent).length) &&
                  Object.keys(rootContent).map((nodeKey) =>
                    renderTree(rootContent[nodeKey])
                  )}
              </TreeView>
            </React.Fragment>
          )}
        </Box>
        {isUploading && (
          <Box>
            <Accordion
              expanded={expandedProgress}
              onChange={() => setExpandedProgress(!expandedProgress)}
              className={classes.progressAccordion}
            >
              <IconLeftAccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="total-progressbar-content"
                id="total-progressbar"
              >
                <Typography className="total-progress-text">
                  {t("Total Upload Progress")}
                </Typography>
                <Box width="100%" mr={1} className="main-progress-bar">
                  <LinearProgress
                    variant="determinate"
                    color={totalUploadProgress === 1 ? "primary" : "secondary"}
                    value={totalUploadProgress * 100}
                  />
                </Box>
                <Box minWidth={35}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    className="progress-percent"
                  >{`${Math.round(totalUploadProgress * 100)}%`}</Typography>
                </Box>
                <IconButton onClick={handleCancelDialogOpen}>
                  <CancelIcon />
                </IconButton>
              </IconLeftAccordionSummary>
              <AccordionDetails className="progress-details">
                <Box width="100%">
                  {Object.keys(filesUploadProgress).map((fileKey, index) => {
                    return (
                      <Box
                        display="flex"
                        alignItems="center"
                        mt={2}
                        mb={2}
                        key={index}
                      >
                        {fileKey}
                        <Box width="100%" ml={2} mr={1}>
                          <LinearProgress
                            variant="determinate"
                            color={
                              filesUploadProgress[fileKey].progress === 1
                                ? "primary"
                                : "secondary"
                            }
                            value={filesUploadProgress[fileKey].progress * 100}
                          />
                        </Box>
                        <Box minWidth={35} mr={1}>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                          >{`${Math.round(
                            filesUploadProgress[fileKey].progress * 100
                          )}%`}</Typography>
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </AccordionDetails>
            </Accordion>
            <Dialog
              open={cancelDialog}
              onClose={() => setCancelDialog(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {t("Cancel file upload?")}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {t(
                    "file_upload_cancel_prompt",
                    "Are you sure you want to cancel uploading? All the progress will be lost!"
                  )}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setCancelDialog(false)} color="primary">
                  {t("No")}
                </Button>
                <Button
                  onClick={uploadCancelHandler}
                  color="secondary"
                  autoFocus
                >
                  {t("Yes")}
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        )}
      </React.Fragment>
    );
  }
};

export default AttachedStorage;
