import {
  Box,
  IconButton,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { projectsTableHeaders } from "src/config";
import ProjectTableHeader from "./header";
import { ProjectTableRow } from "./row";
import { Autocomplete } from "@material-ui/lab";
import { Check } from "@material-ui/icons";
import PopoverContent from "./header/popoverContent";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  center: {
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
  },
  table: {
    width: "100%",
  },
  tableHead: {
    backgroundColor: theme.palette.primary.main,
    "& .MuiTableCell-root": {
      color: "white",
    },
  },
  popover: {
    padding: theme.spacing(2),
    width: 300,
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
  },
  popoverAutocomplete: {
    width: "100%",
  },
}));

function ProjectTable({
  projectsData,
  tableSort,
  setSortState,
  appConf,
  setTableFilters,
  tableFilters,
}) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeColumnName, setActiveColumnName] = useState(null);
  const [options, setOptions] = useState(null);
  const [columns, setColumns] = useState([...projectsTableHeaders]);
  const classes = useStyles();

  const onFilterClick = (e, name) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    setActiveColumnName(name);
  };

  const configureOptions = (name) => {
    const options = [];
    Object.entries(appConf.entities[name]).forEach((value) => {
      options.push({ [value[0]]: value[1] });
    });
    return options;
  };

  const getName = (name) => {
    if (name === "project_type") {
      return "project_types";
    } else if (name === "pipeline_class") {
      return "pipeline_classes";
    } else if (name === "kit") {
      return "kits";
    } else {
      return "";
    }
  };

  useEffect(() => {
    if (activeColumnName) {
      switch (activeColumnName) {
        case "project_type":
          setOptions(configureOptions("project_types"));
          return;
        case "pipeline_class":
          const pipelineClasses = [];
          Object.entries(appConf.entities["pipeline_classes"]).forEach(
            (value) => {
              Object.entries(value[1]).forEach((value) => {
                pipelineClasses.push({ [value[0]]: value[1] });
              });
            }
          );
          setOptions(pipelineClasses);
          return;
        case "kit":
          setOptions(configureOptions("kits"));
          return;
        case "created_at":
          setOptions([]);
          return;
        default:
          return;
      }
    }
  }, [activeColumnName]);

  return (
    <Box className={classes.root}>
      <Table className={classes.table}>
        <TableHead className={classes.tableHead}>
          <TableRow>
            {/* For collapsible  */}
            <TableCell />
            {columns.map((value, index) => (
              <ProjectTableHeader
                key={index}
                classes={classes}
                onFilterClick={onFilterClick}
                tableFilters={tableFilters}
                setTableSort={setSortState}
                tableSort={tableSort}
                value={value}
              />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {_.uniqBy(projectsData, (obj) => obj.id).map((project, index) => (
            <ProjectTableRow key={index} project={project} columns={columns} />
          ))}
        </TableBody>
      </Table>
      {activeColumnName && options && (
        <Popover
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
          onClose={() => setAnchorEl(null)}
          open={anchorEl}
        >
          <PopoverContent
            activeColumnName={activeColumnName}
            setTableFilters={setTableFilters}
            tableFilters={tableFilters}
            name={getName(activeColumnName)}
            options={options}
            classes={classes}
          />
        </Popover>
      )}
    </Box>
  );
}

export default ProjectTable;
