/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Font,
  usePDF,
  Image,
} from "@react-pdf/renderer";
import { Box, CircularProgress, colors } from "@material-ui/core";
import { apiLoadReportDetails, apiLoadReportVariantData } from "src/api";
import pageSecond from "./pdfGermlinePage2.png";
import contact from "./contact.png";
import pageFirst from "./pdfGermline.png";
import { useParams } from "react-router-dom";
import { prepareFiles } from "../reports/utils/helpers";
import { getBaseURL } from "src/config/api";
import { apiTableGetSchema } from "src/api/endpoints";

// Register Font
Font.register({
  family: "Roboto",
  src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf",
});

// const headersResult = [
//   { label: "Variant", name: ["main.gene_symbol", "main.hgvsc"], type: "text" },
//   { label: "Classification", name: ["main.pathogenicity"], type: "pathogenic" },
//   {
//     label: "Associated Indications",
//     name: ["details4.phenotype_information"],
//     type: "split",
//   },
// ];

// const variantDetails = [
//   [{ label: "Variant", name: ["main.gene_symbol"], type: "text" }],
//   [{ label: "Transcript", name: ["main.gene_symbol"], type: "text" }],
//   [
//     { label: "Zygosity", name: ["main.gene_symbol"], type: "text" },
//     { label: "Consequence", name: ["main.gene_symbol"], type: "text" },
//   ],
//   [
//     { label: "Variant Type", name: ["main.gene_symbol"], type: "text" },
//     { label: "Classification", name: ["main.gene_symbol"], type: "text" },
//   ],
//   [
//     {
//       label: "Associated Phenotype",
//       name: ["main.phenotype_information"],
//       type: "text",
//     },
//   ],
// ];

const headersAnalysisResult = [
  {
    label: "Gene / HGVSc",
    name: ["main.gene_symbol", "main.hgvsc"],
    type: "text",
    width: 10,
  },
  {
    label: "Consequence",
    name: ["main.consequence"],
    type: "text",
    width: 20,
    center: true,
  },
  {
    label: "Associated Indications",
    name: ["details4.phenotype_information"],
    type: "split",
    center: true,
    width: 40,
  },
  {
    label: "Classification / Zigosity",
    name: ["main.pathogenicity", "main.genotype"],
    type: "pathogenic",
    center: true,
    width: 10,
  },
];

// Create styles
const styles = StyleSheet.create({
  page: {
    // backgroundColor: "#E4E4E4",
    fontFamily: "Roboto",
  },
  table: {
    display: "flex",
  },
  divTable: {
    display: "table",
    width: "auto",
    borderSpacing: "5px" /* cellspacing:poor IE support for  this */,
  },
  divTableRow: {
    display: "flex",
    flexDirection: "row",
    width: "auto",
    clear: "both",
  },
  divTableCol: {
    float: "left" /* fix for  buggy browsers */,
    display: "table-column",
    width: "100%",
    fontSize: 12,
    fontWeight: "extrabold",
    padding: 2,
    margin: 2,
    color: "white",
    backgroundColor: "#2B5079",
  },
  row: {
    flexDirection: "row",
  },
  col: {
    flexDirection: "column",
    flex: 1,
    width: "100%",
    fontSize: 10,
    padding: 2,
    margin: 2,
    backgroundColor: "#E6F0F6",
  },
  section: {
    padding: "10px 0",
  },
  header: {
    textAlign: "center",
    fontSize: 28,
    fontWeight: "extrabold",
  },
  subHeader: {
    textAlign: "start",
    fontSize: 18,
    fontWeight: "bold",
  },
  text: {
    fontSize: 12,
  },
  pageNumbers: {
    width: "100%",
    textAlign: "center",
    fontSize: 8,
    fontWeight: "bold",
  },
  p: {
    color: colors["red"][400],
  },
  lp: {
    color: colors["red"][900],
  },
  vusii: {
    color: colors["orange"][900],
  },
  vusi: {
    color: colors["orange"][600],
  },
  vus: {
    color: colors["orange"][300],
  },
  lb: {
    color: colors["green"][900],
  },
  b: {
    color: colors["green"][400],
  },
  li: {
    flexDirection: "row",
    maxWidth: "100%",
    paddingVertical: 4,
  },
  ul: {
    width: "100%",
    maxWidth: "100%",
  },
  tableText: {
    width: "100%",
    maxWidth: "100%",
    paddingBottom: 2,
    paddingTop: 2,
  },
  patientInfo: {
    width: "100%",
    paddingHorizontal: 12,
    padding: 4,
    gap: 4,
    flexDirection: "column",
  },
  patientInfoHeader: {
    color: "#2B5079",
    fontSize: 12,
    fontWeight: "extrabold",
  },
  patientInfoDidiver: {
    width: "100%",
    height: 1,
    backgroundColor: "black",
  },
  patientInfoSub: {
    fontSize: 10,
    fontWeight: "bold",
  },
  patientInfoParent: {
    flexDirection: "row",
  },
  variantDetails: {
    paddingTop: 8,
    flexDirection: "column",
  },
  view: {
    width: "100%",
    height: "100%",
    padding: 0,
    backgroundColor: "white",
  },
  pathogenisity: {
    textAlign: "center",
  },
  image: {
    objectFit: "cover",
  },
  coverage: {
    flexDirection: "row",
  },
  coverageKey: {
    width: "100%",
  },
  coverageValue: {
    width: "100%",
  },
  baseView: {
    // flexDirection: "column",
    // alignItems: "center",
  },
});

// Create Document Component
const MyDocument = () => {
  const [value, setValue] = useState();
  const [coverage, setCoverage] = useState();
  const [schema, setSchema] = useState();
  const [, updateInstance] = usePDF({
    document: <PDF value={value} coverage={coverage} />,
  });
  const { reportId, variantIds, isSecondary } = useParams();
  useEffect(() => {
    let tempSchema = {};
    apiLoadReportDetails(reportId, async (data, status) => {
      if (status === 200) {
        const files = await prepareFiles(data.files, data.unified_output);
        apiTableGetSchema(data.schema, (data, status) => {
          if (status === 200) {
            for (const column of data.columns) {
              tempSchema = {
                ...tempSchema,
                [column.name]: column,
              };
            }
          }
        }).then(() => {
          let tmpCovarage = {};
          if (files["coverage_table"]) {
            try {
              fetch(getBaseURL() + files["coverage_table"].download_address)
                .then((response) => response.text())
                .then((data) => {
                  const coverageData = data.split("\n").slice(1);
                  for (let i = 0; i < coverageData?.length; i++) {
                    const rowData = coverageData[i].split("\t");
                    if (Boolean(rowData[0])) {
                      tmpCovarage[rowData[0]] = rowData[1];
                    }
                  }
                  return;
                })
                .then(() => {
                  setCoverage(tmpCovarage);
                  setSchema(tempSchema);
                  updateInstance(
                    <PDF
                      value={value}
                      coverage={tmpCovarage}
                      schema={tempSchema}
                    />
                  );
                });
            } catch (e) {}
          } else {
            setSchema(tempSchema);
            updateInstance(
              <PDF value={value} coverage={[]} schema={tempSchema} />
            );
          }
        });
      }
    });
  }, []);

  useEffect(() => {
    if (variantIds !== "0") {
      apiLoadReportVariantData(
        reportId,
        4,
        0,
        200,
        {
          filters: {
            combinator: "and",
            rules: [
              {
                field: "___id___",
                operator: "in",
                value: variantIds,
              },
            ],
          },
        },
        (value) => {
          setValue(value);
          // updateInstance(
          //   <PDF value={value} coverage={coverage} schema={schema} />
          // );
        }
      );
    } else {
      setValue(["Not Found In DB"]);
    }
  }, []);

  if (!value || !schema) {
    return (
      <Box
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  } else {
    return (
      <Box
        height={"100%"}
        style={{
          display: "flex",
        }}
      >
        {/* <a href={`${instance.url}`} target="_blank" rel="noreferrer">
          Download
        </a> */}
        {value && value.results && value[0] !== "Not Found In DB" && (
          <CustomPdfViewer
            value={value}
            coverage={coverage}
            isSecondary={isSecondary}
            schema={schema}
          />
        )}
      </Box>
    );
  }
};

const CustomPdfViewer = ({ value, coverage, isSecondary, schema }) => {
  return (
    <PDFViewer width={"100%"} height={"100%"}>
      <PDF
        value={value}
        coverage={coverage}
        isSecondary={isSecondary}
        schema={schema}
      />
    </PDFViewer>
  );
};

const PDF = React.memo(({ value, coverage, isSecondary, schema }) => {
  if (!value) {
    return (
      <Document>
        <Page size="A4"></Page>
      </Document>
    );
  }

  return (
    <Document title="Massive Analyser Germline Analysis Report">
      <Page size="A4" style={styles.page}>
        <View style={styles.view}>
          <Image src={pageFirst} style={styles.image} />
        </View>
        <View style={styles.view}>
          <Image src={pageSecond} style={styles.image} />
        </View>

        <View style={{ padding: 16 }}>
          <View style={styles.baseView}>
            <VariantDetails />
            <View>
              <View style={styles.section}>
                <Text style={styles.subHeader}>Results</Text>
                <Text style={styles.text}>
                  Variant(s) compatible with the patient's clinical findings and
                  classified as pathogenic by the current literature and
                  databases integrated into the bioinformatics software platform
                  were identified.
                </Text>
              </View>
              <Section
                value={value}
                header={headersAnalysisResult}
                schema={schema}
              />
            </View>
          </View>
          {Boolean(isSecondary === "true") && (
            <View break>
              <View style={styles.baseView}>
                <VariantDetails />
                <View>
                  <View style={styles.section}>
                    <Text style={styles.subHeader}>SECONDARY FINDINGS</Text>
                    <Text style={styles.text}>
                      The American College of Medical Genetics and Genomics has
                      published recommendations for reporting secondary findings
                      in clinical exome and genome sequencing. Based on the
                      regularly updated literature, the pathogenic and likely
                      pathogenic variants found on the genes listed in the
                      secondary findings list are advised to be reported
                      regardless of clinical indication and evidence (Miller et
                      al. 2023).
                    </Text>
                  </View>
                  <Section
                    value={value}
                    header={headersAnalysisResult}
                    schema={schema}
                  />
                </View>
              </View>
            </View>
          )}
          <View break wrap={false}>
            {/* <VariantDetails /> */}
            <View>
              <View style={styles.section}>
                {coverage && Object.keys(coverage).length > 0 && (
                  <View>
                    <Text style={styles.subHeader}>QUALITY OF THE ASSAY</Text>
                    <Text style={styles.text}>
                      The American College of Medical Genetics and Genomics has
                      published recommendations for reporting secondary findings
                      in clinical exome and genome sequencing. Based on the
                      regularly updated literature, the pathogenic and likely
                      pathogenic variants found on the genes listed in the
                      secondary findings list are advised to be reported
                      regardless of clinical indication and evidence (Miller et
                      al. 2023).
                    </Text>
                  </View>
                )}
              </View>
              <View style={styles.coverage}>
                {coverage &&
                  Object.keys(coverage).length > 0 &&
                  Object.keys(coverage).map((key) => {
                    return (
                      <View
                        style={{ ...styles.coverageKey, ...styles.divTableCol }}
                      >
                        <Text>{key}</Text>
                      </View>
                    );
                  })}
              </View>
              <View style={styles.coverage}>
                {coverage &&
                  Object.keys(coverage).length > 0 &&
                  Object.keys(coverage).map((key) => {
                    return (
                      <View style={{ ...styles.coverageValue, ...styles.col }}>
                        <Text>{coverage[key]}</Text>
                      </View>
                    );
                  })}
              </View>
            </View>
          </View>
          <View
            fixed
            style={{ position: "absolute", bottom: 0, left: "50%" }}
            render={({ pageNumber, totalPages }) =>
              totalPages !== pageNumber && (
                <Text style={styles.pageNumbers}>
                  {`${pageNumber} / ${totalPages}`}
                </Text>
              )
            }
          />
        </View>
        <View style={styles.view} break>
          <Image src={contact} style={styles.image} />
        </View>
      </Page>
    </Document>
  );
});
const VariantDetails = ({ value }) => {
  return (
    <View>
      <View break style={styles.variantDetails}>
        <View style={styles.patientInfoParent}>
          <PatientInfo
            header={"SENDER"}
            infos={["Hospital :", "Physician :", "Date of Request :"]}
          />
          <PatientInfo
            header={"PATIENT"}
            infos={["Patient ID :", "Date of Birth :", "Indication :"]}
          />
          <PatientInfo
            header={"SAMPLE"}
            infos={["Sample ID :", "Sample Type :", "Date of Collection :"]}
          />
        </View>
      </View>
    </View>
  );
};

const PatientInfo = ({ infos, header }) => {
  return (
    <View style={styles.patientInfo}>
      <Text style={styles.patientInfoHeader}>{header}</Text>
      <View style={styles.patientInfoDidiver} />
      {infos.map((info) => (
        <Text style={styles.patientInfoSub}>{info}</Text>
      ))}
    </View>
  );
};

const Section = ({ value, header, schema }) => {
  const isCenterd = (center) => {
    if (center) {
      return { ...styles.pathogenisity };
    }
    return {};
  };
  return (
    <View style={styles.section}>
      <View style={styles.divTable}>
        <View style={styles.divTableRow}>
          {!(value ?? {}).hasOwnProperty("results") &&
          value[0] === "Not Found In DB" ? (
            <Text style={{ textAlign: "center", width: "100%", ...styles.p }}>
              No variant found
            </Text>
          ) : (
            header.map((header) => {
              return <Text style={styles.divTableCol}>{header.label}</Text>;
            })
          )}
        </View>
        <View>
          {value &&
            value.results &&
            value.results.map((variant, index) => {
              return (
                <>
                  <View style={styles.row} wrap={false}>
                    {header.map((header, index) => {
                      return (
                        <>
                          <View style={styles.col}>
                            {header.name.map((name) => {
                              return (
                                <View
                                  style={{
                                    width: "100%",
                                    maxWidth: "100%",
                                    // flex: "column",
                                    ...isCenterd(header.center),
                                    // justifyContent: "center",
                                    paddingBottom: 2,
                                    paddingTop: 2,
                                  }}
                                >
                                  {header.type === "pathogenic" ? (
                                    variant[name] === "0" ? (
                                      <Text
                                        style={{
                                          ...styles.p,
                                        }}
                                      >
                                        Pathogenic
                                      </Text>
                                    ) : variant[name] === "1" ? (
                                      <Text
                                        style={{
                                          ...styles.lp,
                                        }}
                                      >
                                        Likely Pathogenic
                                      </Text>
                                    ) : variant[name] === "2" ? (
                                      <Text
                                        style={{
                                          ...styles.vusii,
                                        }}
                                      >
                                        VUSii
                                      </Text>
                                    ) : variant[name] === "3" ? (
                                      <Text
                                        style={{
                                          ...styles.vusi,
                                        }}
                                      >
                                        VUSi
                                      </Text>
                                    ) : variant[name] === "4" ? (
                                      <Text
                                        style={{
                                          ...styles.vus,
                                        }}
                                      >
                                        VUS
                                      </Text>
                                    ) : variant[name] === "5" ? (
                                      <Text
                                        style={{
                                          ...styles.lb,
                                        }}
                                      >
                                        Likely Benign
                                      </Text>
                                    ) : variant[name] === "6" ? (
                                      <Text style={styles.b}>Benign</Text>
                                    ) : (
                                      <Text>{variant[name]}</Text>
                                    )
                                  ) : header.type === "split" ? (
                                    <View style={styles.ul}>
                                      {variant[name]
                                        .split(schema[name].multi_value)
                                        .map((value) => (
                                          <View style={styles.li}>
                                            <Text
                                              style={{
                                                marginHorizontal: 4,
                                                width: "1%",
                                              }}
                                            >
                                              •
                                            </Text>
                                            <Text style={{ width: "97%" }}>
                                              {value}
                                            </Text>
                                          </View>
                                        ))}
                                    </View>
                                  ) : (
                                    <Text>
                                      {variant[name]
                                        .match(/\w+|\W+/g)
                                        ?.map((seg, i) => (
                                          <Text style={{}} key={i}>
                                            {seg}
                                          </Text>
                                        ))}
                                    </Text>
                                  )}
                                </View>
                              );
                            })}
                          </View>
                        </>
                      );
                    })}
                  </View>
                </>
              );
            })}
        </View>
      </View>
    </View>
  );
};

export default MyDocument;
