import { Box, TableCell, makeStyles } from "@material-ui/core";
import {
  Cancel,
  Done,
  Error,
  HourglassEmpty,
  HourglassFull,
} from "@material-ui/icons";
import React from "react";
import {
  CancelAndReAnalysisButton,
  CircularProgressWithLabel,
} from "src/components/pages/projectDetails/detailsTable";
import CancelAnalysisButton from "./cancelAnalysisButton";
import { userHasPermission } from "src/components/utils";
import { format } from "date-fns";

const useStyles = makeStyles((theme) => ({
  done: { color: theme.palette.success.main, margin: "auto" },
  error: { color: theme.palette.error.main },
  // TODO: always 360 rotate object
}));

function ProjectTableRowCell({ userObj, data, name, type, appConf }) {
  const classes = useStyles();
  const entitesNames = {
    kit: "kits",
    pipeline_class: "pipeline_classes",
    project_type: "project_types",
    sequencer: "sequencers",
  };
  switch (type) {
    case "string":
      return (
        <>
          {appConf.entities[entitesNames[name]]
            ? name !== "pipeline_class"
              ? appConf.entities[entitesNames[name]][data[name]]
              : appConf.entities[entitesNames[name]][data["project_type"]][
                  data[name]
                ]
            : data[name]}
        </>
      );
    case "date":
      return format(new Date(data[name]), "dd/MM/yyyy HH:mm");
    case "action":
      return data.status === "completed" || data.status === "failed"
        ? userHasPermission(userObj.activePermissions, "create_project") && (
            <>
              <CancelAndReAnalysisButton
                projectId={data.id}
                projectData={data}
                sampleData={data.samples}
                type="project"
                buttonType="re"
              />
            </>
          )
        : data.status === "pending" &&
            userHasPermission(userObj.activePermissions, "create_project") && (
              <CancelAndReAnalysisButton
                projectId={data.id}
                projectData={data}
                sampleData={data.samples}
                type="project"
                buttonType="cancel"
              />
            );
    case "progress":
      if (data[name] === "completed") {
        return (
          <Done fontSize="large" color="action" className={classes.done} />
        );
      } else if (data[name] === "analysing") {
        return <CircularProgressWithLabel value={data.progress} />;
      } else if (data[name] === "failed") {
        return (
          <Error fontSize="large" color="action" className={classes.error} />
        );
      } else if (data[name] === "cancelled") {
        return (
          <Cancel fontSize="large" color="action" className={classes.error} />
        );
      } else if (data[name] === "pending") {
        return <HourglassEmpty fontSize="large" color="primary" />;
      } else {
        return data[name];
      }
    default:
      return <>{data[name]}</>;
  }
}

export default ProjectTableRowCell;
